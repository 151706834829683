import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import DetailSidebar from "../AssetDetail/DetailsSidebar/DetailsSidebar";
import { setActiveMilestoneStatus } from "../../utilities/getMilestones";
import getMilestoneStatistics from "../../utilities/getMilestoneStatistics.js";
import { UserContext } from "../../context/UserContext";
import TasksAndHolds from "../TasksAndHolds/TasksAndHolds";
import styled from "@emotion/styled";
import { useTheme } from '@emotion/react';
import { rgba } from "emotion-rgba";
import { FaExpandAlt } from "react-icons/fa";
import { FiMinimize2 } from "react-icons/fi";
import DocumentTable from "../DocumentTable/DocumentTable";
import Overview from "../AssetDetail/Foreclosure/Overview";
import Milestones from "../AssetDetail/Milestones/Milestones";
import Modal from "../../styled/Modal/Modal";
import { isForeclosure, isBankruptcy } from "../../utilities";
import FilterAssetTimeline from "./FilterAssetTimeline";
import { filters } from "../AssetDetail/Processes";
import TLDR from "../AssetDetail/TLDR.jsx";

const AssetDetailContainer = styled(Box)`
  display: grid;
  height: 89.5vh;
  grid-template-columns: 1.2fr 5fr;
  margin-top: 10px;
`

export const getActiveStep = (substeps) => {
  const ml = substeps.find((step) => (step.status === "active"));
  return ml;
}

const getProcessType = (process) => {
  if (
    process.processType === "stateDefault" ||
    process.processType === "judicial" ||
    process.processType === "nonJudicial"
  )
    return "Foreclosure"
  else return "Bankruptcy";
}

export default function AssetDetailsReadOnly({ asset, process, open, onClose }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { user, portfolioID } = useContext(UserContext);

  const statusValue = process.stepID;
  const { processSteps } = process;

  const milestones = setActiveMilestoneStatus(processSteps, statusValue);
  
  const data = { asset };
  const { nextStepStats, sideBarStats } = getMilestoneStatistics(milestones, data);

  let hasBK, bkSteps, bkMilestones, originalFC;
  let fcSteps, fcMilestones;
  
  if (isBankruptcy(process)) {
    bkSteps = process.processSteps;
    bkMilestones = setActiveMilestoneStatus(bkSteps, statusValue);
  } else {
    fcSteps = process.processSteps;
    fcMilestones = setActiveMilestoneStatus(fcSteps, statusValue)
  }
  // Used to calculate the current, active milestone for each process
  const bkActiveStepIndex = bkMilestones && getActiveStep(bkMilestones)?.stepID;
  const fcActiveStepIndex = fcMilestones && getActiveStep(fcMilestones)?.stepID;

  return (
    <Modal open={open} onClose={onClose} height="100vh" width="98vw">
      <AssetDetailContainer>
        {!isFullScreen && (<DetailSidebar asset={asset} sideBarStats={sideBarStats} nextStepStats={nextStepStats} readOnly />)}
        <Asset
          nextStepStats={nextStepStats}
          sideBarStats={sideBarStats}
          asset={asset}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          bkMilestones={bkMilestones}
          originalFC={originalFC}
          fcMilestones={fcMilestones}
          user={user}
          portfolioID={portfolioID}
          bkActiveStepIndex={bkActiveStepIndex}
          fcActiveStepIndex={fcActiveStepIndex}
          hasBK={hasBK}
          process={process}
        />
      </AssetDetailContainer>
    </Modal>
  );
}

const Container = styled(Box)`
  display: flex;
`
const AssetContainer = styled(Box)`
  width: ${({isFullScreen}) => isFullScreen ? '98vw' : '80%'};
  background-color: ${({theme}) => `${theme.themeColor.backgroundBody} !important`};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 10px;
  background-color: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
`
const TaskAndHoldContainer = styled(Box)`
  width: 25%;
  margin: 0 auto;
`

const AssetBox = styled(Box)`
  height: 94%;
`

const ExpandDiv = styled('div')`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const StyledTab = styled(Tab)`
  min-width: 150px;
  width: 250px;
`
const TimelinePattern = styled('div')`
  position: absolute;
  background-color: transparent;  
  background: repeating-linear-gradient( 180deg, transparent, transparent 10px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 1px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 11px);
  height: 83%;
  width: 5px;
  z-index: 1;
  top: 100px;
`
const MilestonesBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 84vh;
  background: ${({theme}) => theme.themeColor.backgroundBody};
`

const getCurrMilestone = (fcI, fcM, bkI, bkM) => {
  if ((!fcM || !fcI) && (!bkI || !bkM)) return;
  if (!bkI) {
    // Regular FC
    const fc = fcM.find((ml) => ml.stepID === fcI);
    return { fc };
  } else if (bkI) {
    // BK exists
    const fc = fcM ? fcM.find((ml) => ml?.stepID === fcI) : null;
    const bk = bkM.find((ml) => ml.stepID === bkI);
    return { fc, bk }
  }
}

const Asset = ({
  asset,
  isFullScreen,
  setIsFullScreen,
  bkMilestones,
  fcMilestones,
  nextStepStats,
  sideBarStats,
  bkActiveStepIndex,
  fcActiveStepIndex,
  process
}) => {
  const [screen, setScreen] = useState(1);
  const [openFilterTimeline, setOpenFilterTimeline] = useState(false);
  const [timelineFilters, setTimelineFilters] = useState(filters);
  const [numFiltersChanged, setNumFiltersChanged] = useState("0");
  const [currentMilestone, setCurrentMilestone] = useState(() =>
    getCurrMilestone(fcActiveStepIndex, fcMilestones, bkActiveStepIndex, bkMilestones)
  );
  const theme = useTheme();

  useEffect(() => {
    const currMilestone = getCurrMilestone(fcActiveStepIndex, fcMilestones, bkActiveStepIndex, bkMilestones);
    setCurrentMilestone(currMilestone)
  }, [asset])

  const handleChange = (event, newValue) => {
    setScreen(newValue);
  };

  const isFC = isForeclosure(process);

  return (
    <Container theme={theme}>
      <AssetContainer isFullScreen={isFullScreen}>
        <Tabs
          value={screen}
          onChange={handleChange}
          sx={{display: 'flex'}}
        >
          <ExpandDiv onClick={() => setIsFullScreen(!isFullScreen)}>
            {isFullScreen ? <FiMinimize2 /> : <FaExpandAlt />}
          </ExpandDiv>
          <StyledTab selected label={getProcessType(process)} />
          <StyledTab label="Documents" />
        </Tabs>

        <AssetBox>
          <>
            {screen === 1 &&
              <div style={{ display: 'flex', float: 'left', width: '100%', position: 'relative' }}>
                <Overview
                  sideBarStats={sideBarStats}
                  subStepStats={nextStepStats}
                  milestones={isFC ? fcMilestones : bkMilestones}
                  currentMilestone={currentMilestone}
                  setCurrentMilestone={setCurrentMilestone}
                  activeProcess
                  originalFC
                  asset={asset}
                />
                <TLDR
                  openFilter={setOpenFilterTimeline}
                  numFiltersChanged={numFiltersChanged}
                  process={process}
                />
                <TimelinePattern theme={theme} />
                <MilestonesBackground theme={theme} />      
                <Milestones
                  process={process}
                  activeStep={isFC ? fcActiveStepIndex : bkActiveStepIndex}
                  milestoneID={isFC ? currentMilestone.fc.stepID : currentMilestone.bk.stepID}
                  startDate={process.startDate}
                  currentMilestone={currentMilestone}
                  timelineFilters={timelineFilters}
                  readOnly
                />
              </div>
            }
            {screen === 2 && 
              <DocumentTable
                asset={asset}
                processID={process.id}
                milestoneID={isFC ? currentMilestone.fc.stepID : currentMilestone.bk.stepID}
                readOnly
              />
            }
          </>
        </AssetBox>
      </AssetContainer>

      {!isFullScreen && (
        <TaskAndHoldContainer>
          <TasksAndHolds readOnly activities={asset.activities} asset={asset} process={process} milestoneID={currentMilestone?.stepID} style={{width: '25%'}}/>
        </TaskAndHoldContainer>
      )}
      <FilterAssetTimeline
        open={openFilterTimeline}
        onClose={() => setOpenFilterTimeline(false)}
        setFilters={setTimelineFilters}
        filters={timelineFilters}
        setNumFilters={setNumFiltersChanged}
      />
    </Container>
  )
}