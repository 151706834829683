import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import AssignedTasks from "../components/AssignedTasks/AssignedTasks";
import TaskFlowTemplates from "../components/TaskFlowTemplates/TaskFlowTemplates";

export default function TasksAndTaskFlows({ selectedTab, setNumTasks, isTeam }) {
  const [screen, setScreen] = useState(0);

  const handleChange = (event, newValue) => {
    setScreen(newValue);
  };

  return (
    <>
      <Tabs
        value={screen}
        onChange={handleChange}
        sx={{display: 'flex'}}
      >
        <Tab selected label="List" />
        <Tab label="Templates" />
      </Tabs>
      {screen === 0 && (
        <AssignedTasks selectedTab={selectedTab} setNumTasks={setNumTasks} />
      )}

      {screen === 1 && (
        <TaskFlowTemplates selectedTab={selectedTab} screen={screen} isTeam={isTeam} />
      )}
    </>
  )
}
