import React from 'react';
import { Box } from '@mui/material';
import MilestoneTasks from '../MilestoneTasks/MilestoneTasks';
import MilestoneHolds from '../MilestoneHolds/MilestoneHolds';

export default function TasksAndHolds({ asset, milestoneID, process, readOnly, activities, screen, taskActivity, setAsset, setActiveProcess, setHasSentReferral, setScreen, originalFC, client }) {
  const { id: assetID } = asset;

  if (screen === activities.length+1 && !process?.id) return null;
  if (screen >= activities.length+2 && !process?.id) return null;

  return (
    <Box
      sx={{ margin: '0 15px auto 0px' }}
    >
      { (activities.length > 0 || process?.id) &&
        <MilestoneTasks
          assetID={assetID}
          process={process}
          readOnly={readOnly}
          activities={activities}
          taskActivity={taskActivity}
          milestoneID={milestoneID}
          setScreen={setScreen}
          setHasSentReferral={setHasSentReferral}
          setAsset={setAsset}
          setActiveProcess={setActiveProcess}
          originalFC={originalFC}
          client={client}
        />
      }
      { screen > activities.length && process &&
        <MilestoneHolds readOnly={readOnly} asset={asset} process={process} milestoneID={milestoneID} />
      }
    </Box>
  )
}