import React from 'react';
import './terms-and-conditions.scss'

export default function TermsAndConditions() {
  return (
    <div id="terms-of-service">
      <h1 className='centered'>Terms of Service</h1>
      <h3 className='centered'>Redhawk Research, LLC</h3>
      <h6 className='centered'>October 7, 2024</h6>

      <main className='tnc-content'>
        <p>These Terms of Service (these “Terms of Service”) govern (i) the provision by Redhawk
          Research LLC (“Company”) of those Services that are offered by Company from time to time
          (collectively, the “Services”), the permitted use of the Services and all intellectual
          property and other rights in the Services; and (ii) the use of the Website (as defined below).
          By subscribing for, paying for, accepting and/or using any Services and/or visiting the Website,
          User is agreeing to be bound by these Terms of Service as a User.
        </p>
        <p>
          THESE TERMS OF SERVICE INCLUDE IMPORTANT LEGAL PROVISIONS THAT DEFINE USER’S RIGHTS
          AND OBLIGATIONS REGARDING THE SERVICES, THE USE OF THE WEBSITE AND ALL TRANSACTIONS
          AND OTHER INTERACTIONS BETWEEN USER AND COMPANY. PLEASE CAREFULLY READ THESE TERMS OF
          SERVICE, INCLUDING THE PRIVACY POLICY INCORPORATED HEREIN, PRIOR TO SUBSCRIBING FOR OR
          USING ANY SERVICES OR ENTERING INTO ANY TRANSACTION WITH COMPANY. USER’S SUBSCRIPTION
          FOR, PAYMENT FOR, ACCEPTANCE OF OR USE OF ANY SERVICES, ENTRANCE INTO ANY TRANSACTION
          WITH COMPANY OR USE OF THE WEBSITE CONSTITUTES USER’S ACCEPTANCE OF THESE TERMS OF SERVICE.
        </p>
        <section>
          <h3>1. Certain definitions.</h3>
          <p>In addition to the capitalized terms defined elsewhere in these Terms of Service, the following
            words and phrases shall have the following meanings:</p>
          <p>
            “Agreement” means a contract between Company and a Subscriber, consisting
            of the business terms relating to the Services subscribed for by such Subscriber, as set
            forth on the Website, and these Terms of Service, which are incorporated into such contract by reference. 
          </p>
          <p>
            “Company” means, collectively, Company, its affiliates, and their respective equity holders,
            members, managers, directors, officers, employees, agents and other representatives.</p>
          <p>
            “Representatives” means, collectively, with respect to any User that is an entity, all members,
            managers, directors, officers, employees, agents and advisors (including attorneys, accountants,
            consultants, brokers and financial advisors).
          </p>
          <p>
            “Software” means the software used by or on behalf of Company in connection with the Website and/or to deliver the Services. 
          </p>
          <p>
            “Subscriber” means an entity or individual that subscribes for any Services.
          </p>
          <p>
            “Subscription Fees” means the subscription fees payable by a Subscriber with respect to the Services subscribed for by such Subscriber.
          </p>
          <p>
            “User Account” means a user account with Company that is created by a User.
          </p>
          <p>
            “User Data” means, collectively, all information submitted, uploaded or inputted by or on behalf of
            User in or to the Services and/or the Website, including, without limitation, all contracts or data
            uploaded into the Services by or on behalf of User, and all User or third party data contained therein,
            but excluding all User Feedback (as defined below).
          </p>
          <p>
            “Users” mean, collectively, Subscribers, other users of the Services and visitors that access the
            Website, and “User” means any of them. To the extent that any User subscribes for any Services
            on behalf of one or more of its affiliates and/or acts through one or more Representatives, all references
            in these Terms of Service or the Agreement to “User” shall be deemed to include such affiliate(s)
            and/or Representative(s).
          </p>
          <p>
            “Website” means each Company website, including without limitation the URL www.jonahdirect.com together
            with all associated web pages, functionality and content located under that domain name.
          </p>
        </section>
        <section>
          <h3>2. Right to Amend.</h3>
          <p>
            User acknowledges and agrees that Company may amend these Terms of Service, including any policy
            incorporated herein, at any time and from time to time, without notice to User. Following any such
            amendment, these Terms of Service, as so amended, shall govern Company’s provision and User’s use
            of the Services, any subsequent transactions and interactions with Company and any subsequent visits
            to the Website. User’s continued subscription for, payment for and/or use of any Services following
            such amendment shall evidence User’s acceptance of and agreement to these Terms of Service as so amended.
          </p>
        </section>
        <section>
          <h3>3. Privacy Policy; Information Collection.</h3>
          <p>
            (a)	User’s use of the Services and the Website is subject to the terms of Company’s privacy policy (the
            “Privacy Policy”), which is hereby incorporated into and made a part of these Terms of Service. In the
            event of a conflict between any provision of these Terms of Service and the Privacy Policy, the terms of
            the Privacy Policy will control.
          </p>
          <p>
            (b)	In order to create and use any User Account, User may be required to submit sensitive information about
            User and/or User’s business, including personally identifiable information. Company may collect other
            information about User’s use of the Services and the Website to improve the products and services offered
            by Company and to protect the security of Company and other Company customers. Any personally identifiable
            information collected by Company will be governed by the Privacy Policy.
          </p>
        </section>
        <section>
          <h3>4. Limited License to Use Services.</h3>
          <p>
          In connection with any subscription for Services fully paid for by User, Company hereby grants to User a
          limited, revocable, non-exclusive, non-sublicensable and non-assignable license to use such Services during
          the paid-for term of the subscription therefor for the limited purpose of using such Services as described in
          the Website. Any violation by User of these Terms and Conditions or use by User of any Services for a purpose
          beyond such limited purpose shall result in immediate termination of the foregoing license to User, without
          notice or further action on the part of Company.
          </p>
        </section>
        <section>
          <h3>5. Termination by Company; Right to Change or Suspend Services.</h3>
          <p>
            (a)	Company shall have the right to terminate any Agreement with User for cause at any time immediately
            upon notice to User in the event of any actual or threatened breach by User of any provision of such
            Agreement, including any provision of these Terms of Service. In such event, in addition to any other
            remedies that may be available to Company, Company shall have the right to retain all Subscription Fees and
            other amounts paid by User. Company shall have no obligation to refund any Subscription Fees allocable to
            any period following the effective date of such termination.
          </p>
          <p>
            (b)	Company reserves the right, in its sole discretion, to change, suspend, remove or disable access to, or
            to impose limits on the use of or access to, the Services, or any feature or part thereof, at any time
            without notice to User or any liability to User except as hereinafter provided in this Section 5(b).
          </p>
        </section>
        <section>
          <h3>6. Responsibility for User Accounts; Account Suspension or Termination.</h3>
          <p>
            (a)	User is responsible for the accuracy and completeness of all information provided with respect to each
            User Account created by such User and for the security of each such User Account, including logins,
            passwords and access codes. User acknowledges and agrees that User must update such information as required
            from time to time to maintain the accuracy of all information in each such User Account. User agrees not to
            share any such User Account with any other individual or entity and to keep each such User Account secure
            from unauthorized access. User is responsible for the confidentiality of each such User Account, including
            User’s user login and password, and may not share any such user login or password with any unaffiliated
            person or entity. User is required to immediately notify Company of any unauthorized use of or suspicious
            activity with respect to any such User Account. Company will not be liable for any loss or other damages
            that may result from User’s failure to protect User’s login information, including any password, or any
            other failure to protect the confidentiality of any such User Account, including, without limitation, any
            unauthorized transaction using such User Account, unless such unauthorized transaction results from a
            wrongful act or omission on the part of Company. Subject to the foregoing exception, User agrees to accept
            full responsibility for use of User’s User Account(s), whether authorized or unauthorized.
          </p>
          <p>
            (b)	In the event of any failure to comply with any provision of these Terms and Conditions on the part of
            User, Company may suspend or terminate User’s User Account(s) at any time, without prior notice to User.
            Any such suspension or termination shall not affect User’s legal obligations under these Terms and
            Conditions or any right of Company to exercise any other legal remedy against User.
          </p>
        </section>
        <section>
          <h3>7. Authorization to Contact User.</h3>
          <p>
            By checking the contact authorization box when User creates any User Account, User expressly authorizes
            Company, and its designees and agents, to contact User as described below.
          </p>
          <p>
            (a)	<em>Do Not Call Registry Waiver.</em> User hereby expressly authorizes Company to telephone User at any
            number User provides to Company (and any updated or additional numbers User may provide in the future),
            regardless of whether or not User’s telephone number appears in the “National Do Not Call Registry,”
            established pursuant to 16 CFR§ 310.4(b)(1)(iii)(B) or any similar registry of any other jurisdiction.
          </p>
          <p>
            (b)	<em>Telephone Consumer Protection Act Authorization.</em> User hereby expressly agrees that Company may
            call User and/or send text messages and/or emails to User at any telephone number or email address User
            provides to Company (and any updated or additional numbers or email addresses User may provide in the
            future), including wireless telephone numbers (commonly known as mobile or cellphone numbers) that could
            result in charges to User. The manner in which these calls, text messages or emails are made to User may
            include, without limitation, the use of prerecorded/artificial voice messages and/or automated dialing
            systems. User may revoke consent at any time and by any reasonable means, including by texting “Stop” in
            response to any text message User receives, emailing a stop request to info@redhawkresearch.com (which does
            not bounce back) or mailing a request to Redhawk Research, LLC, 95 Homestead Rd., Tenafly, NJ 07670. If
            User emails or mails such a request, User must clearly identify itself and what method(s), number(s) or
            email address(es) Company should stop using to communicate with User, if less than all.
          </p>
        </section>
        <section>
          <h3>8. User Representations and Warranties.</h3>
          <p>
            By creating a User Account, subscribing for or using the Services or visiting the Website, User hereby
            represents and warrants to Company, on a continuing basis, that: (a) User is at least 18 years old; (b)
            User has the legal capacity and authority to enter into and comply with each Agreement with User and these
            Terms and Conditions; and (c) all information that User provides to Company, including in connection with
            the creation of any User Account and the submission of any User Feedback (as defined below), is and shall
            remain accurate in all respects. 
          </p>
        </section>
        <section>
          <h3>9. Subscription and Payment Terms.</h3>
          <p>
            All Services are provided on a subscription basis. All Subscription Fees are nonrefundable and payable in
            advance and must be paid using the online payment service offered by Company on the Website. User’s
            subscription will automatically renew at the end of each billing cycle unless User cancels the auto-renewal
            by sending a cancellation notice by email to info@redhawkresearch.com. Company reserves the right to change
            the Subscription Fees for any Services at any time, provided that such change will become effective only at
            the end of User’s then-current billing cycle. Subject to all applicable terms and conditions, Users’ access
            to Services will not be curtailed during any subscription period for which nonrefundable payment was been
            received.
          </p>
        </section>
        <section>
          <h3>10. Responsibility for Taxes.</h3>
          <p>
            User is responsible for all taxes and duties associated with User’s purchase of a subscription for any
            Services, other than any taxes payable on Company’s income. If Company is obligated to collect or pay any
            taxes in connection with User’s subscription for any Services, Company will charge such taxes to User
            unless User timely delivers to Company a valid tax exemption certificate from the appropriate taxing
            authority.
          </p>
        </section>
        <section>
          <h3>11. General Rules Regarding Use of Services.</h3>
          <p>
            As a condition to using the Services, User hereby agrees to comply with all applicable local, state,
            national, provincial and international laws, treaties and regulations. User further agrees not to do any of
            the following: (a) gain or attempt to gain unauthorized access to the Services, the Software or the Website
            (including any restricted sections of any of the foregoing), the account of any other user or any related
            system or network, including by impersonating any other user or other individual or entity or by creating a
            false identity or account; (b) establish a link to any Services or the Website in such a way as to suggest
            to any third party any form of association, approval or endorsement on the part of Company; (c) falsely
            imply any sponsorship or other relationship with Company except that of customer and service provider; (d)
            use any Services, the Website or any User Account in any unlawful manner, including, without limitation, in
            violation of any third party’s privacy rights, to abuse, harass or defame any individual or entity, to send
            unsolicited communications or to store or transmit any content that infringes upon any third party’s
            intellectual property rights; (e) use any Services, the Website or any User Account to post, transmit,
            upload, link to, send or store any content that is unlawful, abusive, obscene, racist or discriminatory or
            that would be deemed offensive to an ordinary person; (f) use any Services, the Website or any User Account
            to post, transmit, upload, link to, send or store any viruses, cancel bots, malware, Trojan horses, time
            bombs or any other similar harmful software or any tracking cookies; (g) use any Services, the Website or
            any User Account in any manner that interferes with or disrupts the integrity or performance of any
            Services, the Website or any related system or network or attempt to damage or interfere with any of the
            foregoing by any method, including, without limitation, flood pings, denial of service attacks, packet or
            IP spoofing, forged routing or other information or similar methods or technology; (h) attempt to decipher,
            decompile, reverse-engineer, disassemble, reproduce or copy or otherwise access or discover the source code
            or underlying program of the Software or any other software used in or in connection with any Services or
            the Website; (i) copy, display, distribute, create derivative works from or misappropriate or misuse in any
            manner any Services (or any component thereof), the Website or any of the contents of the foregoing; (j)
            use or try to use any User Account, any Services or the Website in violation of these Terms and Conditions;
            or (k) assist any third party in any of the foregoing. User is solely responsible for compliance with all
            data protection laws and other regulations not generally applicable to us.
          </p>
        </section>
        <section>
          <h3>12. Company Rights in Services and Related Intellectual Property.</h3>
          <p>
            Except for the limited license granted to User to use the Services as described in Section 4, Company
            retains the exclusive right, title and interest in and to all intellectual property, including, without
            limitation, all patents, inventions, copyrights, trademarks, trade names, service marks, trade dress,
            domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights, in or
            related to the Services, the Software, the Website and the contents and components of the foregoing. All
            such intellectual property and intellectual property rights are protected by applicable law, including,
            without limitation, applicable copyright laws and treaties throughout the world. User hereby acknowledges
            and agrees that User is not permitted to reproduce in any manner the Services, the Software or the Website
            (or any contents or component of any of the foregoing), prepare derivative works from the Services, the
            Software or the Website (or any contents or component of any of the foregoing) or use the Services, the
            Software or the Website (or any contents or component of any of the foregoing) for any purpose other than
            as expressly permitted by these Terms and Conditions.
          </p>
        </section>
        <section>
          <h3>13. Trademarks, Logos and Other Intellectual Property.</h3>
          <p>
            The Company trademarks, service marks, graphics, logos and slogans used in connection with the Services and/or
            the Website are trademarks of Company and may not be copied, imitated or used, in whole or in part, without
            the prior written permission of Company. User may not use any metatags or any other “hidden text” utilizing
            the name “Company” or any other name, trademark or Service name of Company without the prior written
            permission of Company. The look and feel of the Services and the Website, including, without limitation,
            all page headers, custom graphics, button icons and scripts, constitute the service mark, trademark and/or
            trade dress of Company and may not be copied, imitated or used, in whole or in part, without the prior
            written permission of Company. Other trademarks, trade names, product names and company names and logos
            used in connection with the Services and/or the Website are the property of their respective owners.
          </p>
        </section>
        <section>
          <h3>14. User Data.</h3>
          <p>
            (a)	At all times, User shall remain responsible for all User Data, including the accuracy and legality of
            all User Data. Without limiting the generality of the foregoing, User shall not submit, upload or input any
            User Data in or to the Services or the Website that violates (i) any intellectual property or other rights
            of any third party or (ii) any applicable law or regulation.
          </p>
          <p>
            (b)	User hereby grants Company and its affiliates a worldwide, royalty-free, transferable and
            sub-licensable license to use, reproduce, modify and adapt the User Data, and to create derivative works
            from the User Data, to the extent required to provide the Services to User and as contemplated by this
            Agreement or the Privacy Policy.
          </p>
          <p>
            (c)	User retains ownership of User Data and, except for the license granted pursuant to Section 14(b), all
            intellectual property rights in and to the User Data.
          </p>
          <p>
            (d)	To the maximum extent permissible under applicable law, User shall be deemed to be the data controller
            with respect to all User Data uploaded to Company and User shall be solely responsible therefor.
          </p>
        </section>
        <section>
          <h3>15. Confidentiality.</h3>
          <p>
            Company agrees to hold all User Data in confidence and to not disclose any User Data to unrelated third
            parties or use such information for any purposes, except as reasonably required in connection with the
            provision of Services to User and/or for performance of Company’s obligations or enforcement of Company’s
            rights under any Agreement with User, including under these Terms of Service. The foregoing obligations
            shall not apply to any User Data (i) which is now or subsequently becomes public, other than as a result of
            Company’s breach of its confidentiality obligations to User hereunder; (ii) which was already known by
            Company on a non-confidential basis prior to its disclosure by User or is independently developed by
            Company; (iii) which is received by Company from a third party not subject to a duty of confidentiality to
            User; or (iv) the disclosure of which is legally compelled.
          </p>
        </section>
        <section>
          <h3>16. User Feedback.</h3>
          <p>
            In connection with User’s use of any Services and/or any transactions User may enter into with Company,
            User may be requested or permitted to provide suggestions, recommendations, reviews or other feedback on
            the Services and/or the Website (collectively, “User Feedback”). User represents and warrants to Company
            that any User Feedback that User submits to Company will be accurate, will comply with all applicable laws
            and will not violate the intellectual property or other rights of any third parties. By providing any User
            Feedback to Company, User thereby grants to Company an irrevocable, perpetual, worldwide, royalty-free,
            transferable and sub-licensable license to incorporate such User Feedback into the Services and/or the
            Website and/or reproduce, display, publicize and otherwise use such User Feedback for any purpose deemed
            reasonable by Company. In the event any User Feedback is in the form of a review or endorsement of Company,
            any Services and/or the Website, such license shall include the right to publish such User Feedback on the
            Website and/or in any promotional materials of Company and to use User’s name in connection therewith. User
            hereby agrees that, by submitting any User Feedback, User irrevocably and fully waives any and all moral
            rights, privacy rights, rights of publicity and any other rights User may have in and to such User
            Feedback.
          </p>
        </section>
        <section>
          <h3>17. Sub-Processors.</h3>
          <p>
            Company uses third party service providers (in each case, a “Sub-Processor”) for its infrastructure,
            third-party software and data processing needs pursuant to each such Sub-Processor’s terms and conditions.
            User acknowledges that Company cannot be held responsible for acts and omissions by its Sub-Processors
            beyond Company’s control, and that to the maximum extent permissible under applicable law Company’s
            financial liability in case of fault or non-compliance by a Sub-Processor shall be limited to the actual
            amounts, if any, that Company recovers from such Sub-Processor on User’s behalf. Company shall cooperate
            with User in any suit or proceeding that User brings against a Sub-Processor arising from or related to
            services purchased from Company.
          </p>
        </section>
        <section>
          <h3>18. DISCLAIMERS BY COMPANY.</h3>
          <p>
            (A)	COMPANY PROVIDES THE SERVICES ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. COMPANY DOES NOT REPRESENT OR
            WARRANT THAT THE SERVICES, THE USE THEREOF OR ANY INFORMATION THEREIN (i) WILL BE UNINTERRUPTED OR SECURE;
            (ii) WILL BE FREE OF DEFECTS, INACCURACIES OR ERRORS; (iii) WILL MEET THE REQUIREMENTS OF USER; OR (iv)
            WILL OPERATE IN THE CONFIGURATION OR WITH OTHER HARDWARE OR SOFTWARE USED BY USER.
          </p>
          <p>
            (B)	COMPANY MAKES NO WARRANTIES WITH RESPECT TO THE SERVICES AND, ON BEHALF OF ITSELF AND THE OTHER MEMBERS
            OF THE COMPANY, HEREBY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
            WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A
            PARTICULAR PURPOSE.
          </p>
          <p>
            (C)	NEITHER COMPANY NOR ANY OTHER MEMBER OF THE COMPANY SHALL BE LIABLE FOR ANY DAMAGES OR LOSSES ARISING
            FROM ANY USE, MISUSE, RELIANCE ON, INABILITY TO USE, INTERRUPTION, SUSPENSION OR TERMINATION OF ANY
            SERVICES, INCLUDING ANY INTERRUPTIONS DUE TO SYSTEM FAILURES, NETWORK ATTACKS OR SCHEDULED OR UNSCHEDULED
            MAINTENANCE.
          </p>
          <p>
            (D)	COMPANY, ON BEHALF OF ITSELF AND THE OTHER MEMBERS OF THE COMPANY, HEREBY DISCLAIMS ALL RESPONSIBILITY
            FOR ALL USER FEEDBACK THAT MAY BE INCLUDED ON THE WEBSITE OR IN ANY PROMOTIONAL MATERIALS AND FOR ALL OTHER
            THIRD-PARTY CONTENT AND THIRD-PARTY LINKS THAT MAY BE INCLUDED IN OR ON THE SERVICES, THE WEBSITE OR ANY
            PROMOTIONAL MATERIALS.
          </p>
        </section>
        <section>
          <h3>19. NO LIABILITY FOR CERTAIN DAMAGES.</h3>
          <p>
            IN NO EVENT, AND UNDER NO LEGAL THEORY (WHETHER CONTRACT, TORT OR OTHERWISE), SHALL COMPANY OR ANY OTHER
            MEMBERS OF THE COMPANY BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
            OF ANY KIND WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, REVENUES, BUSINESS OR
            OPPORTUNITIES AND/OR DELAY DAMAGES, EVEN IF COMPANY OR ANY OTHER MEMBER OF THE COMPANY WAS ADVISED OF OR
            AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
        </section>
        <section>
          <h3>20. Cap on Damages</h3>
          <p>
            Notwithstanding anything to the contrary set forth in these Terms of Service, except to the extent
            prohibited by applicable law, the aggregate liability of the members of the Company to User with respect to
            all claims User may have at any time against Company and the other members of the Company shall not exceed
            an amount equal to the Subscription Fees paid by User during the most-recent six-month period. The
            foregoing limitation of liability reflects an informed, voluntary allocation between Company and User of
            the risks (known and unknown) that may exist in connection with the Services and any Agreement with User,
            including these Terms of Service.
          </p>
        </section>
        <section>
          <h3>21. Indemnification.</h3>
          <p>
            User hereby agrees to indemnify, defend and hold Company and the other members of the Company harmless from
            and against any and all claims, liabilities, actions, demands, losses, damages, penalties, fines and other
            costs and expenses (including, without limitation, reasonable attorneys’ fees, experts’ fees, investigation
            costs, remediation costs and settlement costs) incurred by any of them (collectively, “Indemnifiable
            Losses”) arising out of or relating to (a) use of User’s User Account(s), whether such use is authorized or
            unauthorized; (b) User’s negligent or other wrongful acts or omissions in connection with use of any
            Services and/or the Website; (c) breach of any representation and warranty made by User to Company; (d) any
            violation of these Terms and Conditions by User; (e) any personal injury, including death, or property
            damage directly or indirectly resulting from User’s acts or omissions; (f) User’s violation of any
            applicable law or regulation any intellectual property or other rights of any third party; (h) any
            inaccuracy or incompleteness of any information provided by or on behalf of User to Company; and/or (i) any
            User Feedback, User Data or other content and information that User or any Representative thereof submits,
            uploads, inputs or otherwise provides in or to the Services, the Website and/or Company.
          </p>
        </section>
        <section>
          <h3>22. No Waiver.</h3>
          <p>
            No delay or failure on the part of Company in exercising any legal right or remedy it may have against User
            shall be deemed to constitute a waiver of such right or remedy. In addition, no partial exercise by Company
            of any legal right or remedy it may have against User shall preclude the further exercise by Company of
            such right or remedy or its exercise of any other right or remedy it may have against User.
          </p>
        </section>
        <section>
          <h3>23. Force Majeure.</h3>
          <p>
            User expressly acknowledges and agrees that Company will not be liable to User under any legal theory for
            any failure to perform or delay in performing any obligation to User, whether under these Terms and
            Conditions, any Agreement with User or otherwise, which failure is caused directly or indirectly by a force
            majeure event, including, without limitation, any act of God, accident, fire, strike or other labor
            dispute, riot, insurrection, terrorism, war, epidemic or pandemic, governmental action or refusal to act,
            telecommunications or power failure, any exchange or market ruling or any other cause beyond the reasonable
            control of Company.
          </p>
        </section>
        <section>
          <h3>24. Late Fees; Collection Costs.</h3>
          <p>
            User shall be required to pay a late fee on any payment not received by the due date thereof or received by
            such date but subsequently dishonored or disallowed due to any failed, dishonored or reversed electronic
            debit, dishonored check or disallowed or disputed credit card payment. Such late fee shall be calculated at
            the rate of 1.0% per month (Annual Percentage Rate of 12%) or, if such rate exceeds the maximum legal rate,
            the maximum rate permitted by applicable law. User shall be liable for all reasonable attorneys’ fees and
            other costs incurred by or on behalf of Company to collect any amount owed by User to Company.
          </p>
        </section>
        <section>
          <h3>25. Governing Law.</h3>
          <p>
            Any controversy or claim arising out of or relating in any way to (a) any Agreement with User, including
            these Terms and Conditions; (b) the enforcement or interpretation thereof; (c) any alleged breach, default
            or misrepresentation in connection with any of the provisions thereof; or (d) any Services, the Website and/or
            any transaction, communication or interaction between User and Company shall be governed by New Jersey law,
            without regard to its conflicts of law provisions. Notwithstanding the foregoing, those provisions of
            Section 26 relating to arbitration of Disputes shall be governed by and interpreted in accordance with the
            Federal Arbitration Act.
          </p>
        </section>
        <section>
          <h3>26. Resolution of Disputes; Waiver of Right to Jury Trial; Agreement to Arbitrate.</h3>
          <p>
            These Terms and Conditions contain a binding, individual arbitration agreement and class action waiver.
            This means that any claim must be arbitrated on an individual basis pursuant to the terms set forth below;
            that claims of different persons cannot be combined or aggregated, and that User is waiving User’s right to
            file a lawsuit in court and to have a jury decide the dispute. <strong><u>Please read all the provision of this
            Section 26 carefully and consult a legal advisor if you have any questions.</u></strong>
          </p>
          <p>
            (a)	Any controversy or claim arising out of or relating to the Services, the Website, any Agreement with
            User and/or or these Terms and Conditions, including their enforcement or interpretation, or because of an
            alleged breach, default or misrepresentation in connection with any of the foregoing, or arising out of or
            relating in any way to any transaction, communication or interaction between User and Company or any other
            member of the Company (each, a “Dispute”), shall be submitted to final and binding individual arbitration
            in accordance with the following provisions in order to establish and gain the benefits of a speedy,
            impartial and cost-effective dispute resolution procedure.
          </p>
          <p>
            (b)	Except as otherwise prohibited by applicable law and as provided in Section 26(i), any Dispute shall be
            settled by arbitration administered by JAMS pursuant to its applicable rules (the “JAMS Rules”), before a
            single neutral arbitrator (the “Arbitrator”), in Newark, New Jersey.
          </p>
          <p>
            (c)	To the fullest extent permitted by applicable law, and notwithstanding anything else in these Terms and
            Conditions, User agrees that any Dispute shall be decided by the Arbitrator on an individual basis and not
            on a class, collective or representative basis. User further acknowledges and agrees that the Arbitrator
            shall not have the authority or jurisdiction to hear the arbitration as a class, collective or
            representative action or to join or consolidate causes of action of different parties into one proceeding.
          </p>
          <p>
            (d)	The Arbitrator may award any form of remedy or relief (including injunctive relief and specific
            performance) that otherwise would be available in court. Any award pursuant to the arbitration shall be
            accompanied by a written opinion of the Arbitrator setting forth the reason(s) for the award. The award
            rendered by the Arbitrator shall be conclusive and binding upon User and Company (and/or the other member(s)
            of the Company), and judgment upon the award may be entered, and enforcement may be sought in, any court of
            competent jurisdiction.
          </p>
          <p>
            (e)	Subject to the JAMS Rules and the provisions of Section 26, the arbitration fees, together with other
            expenses of the arbitration incurred or approved by the Arbitrator, shall be divided equally between User
            and Company.
          </p>
          <p>
            (f)	USER UNDERSTANDS THAT, ABSENT THESE TERMS AND CONDITIONS, EACH OF USER AND THE MEMBERS OF THE COMPANY
            WOULD HAVE THE RIGHT TO SUE THE OTHER IN COURT AND TO HAVE ANY DISPUTE DECIDED PURSUANT TO A JURY TRIAL,
            BUT, BY THESE TERMS AND CONDITIONS, USER ARE GIVING UP THAT RIGHT AND AGREEING TO RESOLVE BY ARBITRATION
            ANY AND ALL DISPUTES.
          </p>
          <p>
            (g)	User and Company agree to treat as strictly confidential all information concerning any arbitration
            proceeding, including the pleadings, the hearing and any arbitration award, except as (i) may be necessary
            to prepare for or conduct the arbitration hearing on the merits, (ii) may be necessary in connection with a
            court application for a preliminary remedy, confirmation and enforcement proceedings or a judicial
            challenge to an award or its enforcement, or (iii) otherwise required by applicable law or judicial
            decision. User and Company agree that breach of this confidentiality provision would irreparably harm the
            non-breaching party, and further agree that any such breach shall entitle the non-breaching party to seek
            injunctive relief and/or compensatory damages for the breach (without the necessity of posting a bond or
            other security).
          </p>
          <p>
            (h)	User may elect to opt out and exclude User from the final, binding arbitration procedure and class
            action waiver specified in this Section 26 by sending to Redhawk Research, LLC, 95 Homestead Rd., Tenafly,
            NJ 07670 for postmarking within 15 days of User’s creation of a User Account, a letter stating (i) User’s
            name, (ii) User’s mailing address, and (iii) User’s request to be excluded from the final, binding
            arbitration procedure and class action waiver. User is not required to send the letter by registered or
            certified mail, return receipt requested, but it is recommended that User do so. User’s request to be
            excluded will only be effective and enforceable if User can prove that the request was postmarked within
            the 15-day deadline. In the event of an effective exclusion request by User, Company reserves the right,
            exercisable in its sole and absolute discretion, to rescind any subscription or other transaction with User
            and/or to terminate or suspend User’s User Account(s). In the event of an effective exclusion request by
            User, all other provisions of these Terms and Conditions shall continue to apply.
          </p>
          <p>
            (i)	Notwithstanding the other provisions of this Section 26, User agrees that Company and the other members
            of the Company shall have the right to seek injunctive or other equitable relief in any state or federal
            court located in Newark, New Jersey, to enforce any Agreement with User and/or these Terms and Conditions
            or prevent infringement of any intellectual property rights, without being required to post a bond or other
            security or prove actual damages. In the event Company or any other member of the Company seeks such
            equitable relief in any such court, User hereby irrevocably submits to the personal jurisdiction of such
            court and waives all objections to such jurisdiction.
          </p>
        </section>
        <section>
          <h3>27. Prevailing Party.</h3>
          <p>
            In the event of any Dispute, and whether such Dispute is resolved via arbitration, litigation or otherwise,
            the prevailing party (as that term is commonly defined by the prevailing common and/or statutory law in the
            applicable jurisdiction) shall be entitled to recover its costs of suit, which costs shall include, without
            limitation, all reasonable attorneys’ fees and expenses incurred by the prevailing party related to the
            Dispute. In the event a party to any Dispute fails to proceed with arbitration, unsuccessfully challenges
            the Arbitrator’s award or fails to comply with an arbitration award, the other party shall be entitled to
            recover its costs of enforcement, including, without limitation, reasonable attorneys’ fees and expenses
            incurred in having to compel arbitration or to defend or enforce the arbitration award.
          </p>
        </section>
        <section>
          <h3>28. Time Limit to File Claims.</h3>
          <p>
            User expressly agrees that, except where applicable law prescribes a shorter applicable statute of
            limitation, or prohibits shortening the otherwise-applicable longer statute of limitations, User must file
            any claim or legal action of any kind arising in connection with or relating in any way to (a) any
            Services, the Website and/or any transaction with Company; (b) any act (or failure to act) on the part of
            any member of the Company or User; and/or (c) any Agreement with User and/or these Terms and Conditions
            within one year after the transaction or other event, circumstances or other facts giving rise to such
            claim or legal action. Notwithstanding the foregoing, if the law of the applicable jurisdiction has a
            “discovery rule,” whereby accrual of the claim is deferred, which is applicable to one or more claims, then
            the one-year (or shorter, if applicable) limitation period specified in this Section 28 shall begin running
            from the date of accrual of such claim or claims as determined by the law of the applicable jurisdiction.
            If this Section 28 is determined to be unenforceable as to any particular claim or claims under the law of
            the applicable jurisdiction, it shall remain fully enforceable as to all other claims.
          </p>
        </section>
        <section>
          <h3>29. Severability.</h3>
          <p>
            (a)	With the exception of the class action waiver in Section 26(c), which is essential to the agreement to
            arbitrate, if any provision of these Terms and Conditions is determined by any court of competent
            jurisdiction or arbitrator to be invalid, illegal or unenforceable to any extent, that provision shall, if
            possible, be construed as though more narrowly drawn, if a narrower construction would avoid such
            invalidity, illegality or unenforceability, or, if that is not possible, such provision shall, to the
            extent of such invalidity, illegality or unenforceability, be severed from these Terms and Conditions, and
            the remaining provisions of these Terms and Conditions shall remain in effect.
          </p>
          <p>
            (b)	If the class action waiver in Section 26(c) is determined by any court of competent jurisdiction or
            arbitrator to be invalid, illegal or unenforceable to any extent, then the entirety of the dispute
            resolution procedures specified in Section 26 shall be void and of no force and effect.
          </p>
        </section>
        <section>
          <h3>30. Survival.</h3>
          <p>
            All provisions of these Terms and Conditions other than those in Section 4 and Section 35(b) shall survive
            any termination of any User Account and the expiration or other termination of any subscription by User or
            any Agreement or other transaction between User and Company, regardless of the reason for such termination.
          </p>
        </section>
        <section>
          <h3>31. Notices.</h3>
          <p>
            Except as otherwise provided in these Terms and Conditions, including, without limitation, Section 7
            (Authorization to Contact User), and except for general notices from Company regarding the Services and
            notices regarding User Accounts, which may be posted on the Website or delivered to User via electronic
            mail, (a) all notices required or permitted under or in connection with these Terms and Conditions and/or
            any Agreement with User must be in writing; (b) User shall send all notices to Company by U.S. certified or
            registered mail, return receipt requested, addressed to Redhawk Research, LLC, 95 Homestead Rd., Tenafly,
            NJ 07670; and (c) Company shall send all notices to User by U.S. certified or registered mail, return
            receipt requested,, addressed to User at the then-current mailing address in User’s User Account.
          </p>
        </section>
        <section>
          <h3>32. Severability.</h3>
          <p>
            The provisions of any Agreement with User, including the provisions of these Terms of Service, are intended
            to be severable. If, for any reason, any such provision is held to be invalid or unenforceable, the
            remaining provisions shall not be affected by such invalidity or unenforceability, to the fullest extent
            permitted by applicable law.
          </p>
        </section>
        <section>
          <h3>33. Assignment.</h3>
          <p>
            User shall not assign these Terms and Conditions or any Agreement with User or assign any of User’s rights
            or delegate any of User’s obligations hereunder and thereunder. Any assignment or delegation by User in
            contravention of this Section 33 shall be void and of no force or effect. Company has the unlimited right
            to assign these Terms and Conditions, any Agreement with User and/or any or all of its obligations and
            rights hereunder and thereunder. These Terms of Service and each Agreement with User shall be binding upon
            and inure to the benefit of User and Company and their respective successors and permitted assigns.
          </p>
        </section>
        <section>
          <h3>34. Conflicts.</h3>
          <p>
            Notwithstanding anything to the contrary contained in any Agreement with User or in these Terms and
            Conditions, in the event of a conflict between any provision of these Terms of Service and the other
            provisions of any Agreement with User, the provisions of these Terms of Service shall control.
          </p>
        </section>
        <section>
          <h3>35. Nature of Relationship; Right to Identify User as Customer.</h3>
          <p>
            (a)	The relationship between Company and User is solely that of service provider and customer. No other
            relationship, including, without limitation, that of agent and principal, employer and employee, franchisor
            and franchisee, joint venturers or partners, exists between Company and User.
          </p>
          <p>
            (b)	User hereby grants Company the right to identify User as a customer of Company on the Website and in
            Company’s promotional materials.
          </p>
        </section>
        <section>
          <h3>36. Further Assurances.</h3>
          <p>
            User agrees to execute and deliver such other documents and take such other actions as Company may
            reasonably request in connection with any transaction between Company and User or as may be required for
            User to fully perform User’s obligations under any Agreement with User, including these Terms of Service,
            and/or for Company to fully exercise its rights thereunder.
          </p>
        </section>
        <section>
          <h3>37. Third-Party Beneficiaries.</h3>
          <p>
            The members of the Company are intended third-party beneficiaries of the disclaimer, indemnification,
            limitation of liability, dispute resolution and claim time limit provisions of these Terms and Conditions. 
          </p>
        </section>
        <section>
          <h3>38. Reservation of Rights.</h3>
          <p>
            Any rights not expressly granted by the foregoing provisions of these Terms and Conditions are reserved to Company.
          </p>
        </section>
        <section>
          <h3>39. Construction.</h3>
          <p>
            The rule of construction that ambiguities in a contract are to be construed against the drafter shall not
            be invoked or applied in any dispute regarding the meaning or interpretation of any provision of any
            Agreement with User or these Terms of Service.
          </p>
        </section>
        <section>
          <h3>40. Headings.</h3>
          <p>
            The headings of the sections of these Terms of Service are inserted solely for convenience of reference.
            They in no way are intended to or shall define, limit, extend or aid in the construction of the scope,
            extent or intent of these Terms of Service.
          </p>
        </section>
        <section>
          <h3>41. Entire Agreement.</h3>
          <p>
            Each Agreement with User, including these Terms of Service and the Privacy Policy (or in the absence of any
            other Agreement with User, these Terms of Service alone), constitutes the entire agreement between User and
            Company with respect to the subject matter thereof and supersedes any and all prior or contemporaneous
            agreements and understandings, whether written or oral, between Company and User with respect to such
            subject matter.
          </p>
        </section>
      </main>
    </div>
  )
}