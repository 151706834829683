import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box } from '@mui/material';
import { gql, useMutation } from "@apollo/client";
import CellWrapper from '../GridInputCell/CellWrapper';
import { UserContext } from '../../context/UserContext';
import { MessageContext } from "../../context/MessageContext";
import StandardGrid from '../Grid/Grid';
import TableDateTime from '../TableDateTime/TableDateTime';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ActionColumn from './ActionColumn';
import TypeSelector from './TypeSelector';
import "./styles.css";
import { capitalValueFormatter } from '../../utilities';

// eslint-disable-next-line 
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GET_TEAM = gql`
  query Query($input: findTeamsInput) {
    findTeams(input: $input) {
      id
      name
      type
      createdAt
      createdBy {
        firstName
        lastName
        email
      }
      memberships {
        type
        user {
          firstName
          lastName
          email
          memberStatus
        }
      }
      portfolio {
        users {
          email
        }
      }
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      id
      user {
        firstName
        lastName
        memberStatus
        portfolio {
          id
          name
        }
      }
    }
  }
`;

let lastClickedRowId = -1;
let editableCellId = -1;
const isCellEditable = (params) => {
  if (!params) return false;
  return params.data.id === editableCellId;
};

const onEdit = (value, ...rest) => {
  if (value) {
    editableCellId = value;
  } else
    editableCellId = lastClickedRowId;

  if (rest) {
    lastClickedRowId = rest[0]
  }
}

const cellEditorSelector = () => {
  return {
    component: TypeSelector,
    popup: true,
    popupPosition: 'under',
  };
}

const frameworkComponents = {
  ActionColumn,
  CellWrapper,
  TableDateTime
}

const TableContainer = styled(Box)`
margin: 0 auto;
height: 98%;
width: 98%;
border: ${({theme}) => `1px solid ${theme.themeColor.sectionStroke}`};
border-radius: ${({theme}) => theme.themeSizing.borderRadiusLarge};
`

const ls = window.localStorage;

export default function MembersList({ selectedTeam, selectedTeamMembers }) {
  const { portfolioID } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const gridApiRef = useRef(null);
  const theme = useTheme();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      filter: true,
      width: 350,
      editable: () => {
        return isCellEditable();
      },
      cellRenderer: "CellWrapper",
      cellRendererParams: {
        label: 'Member Email'
      }
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: "CellWrapper",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
      cellRendererParams: {
        dropdownValues: [
          { text:'Manager', value:"MANAGER" }, 
          { text:'Member', value:"MEMBER" }
        ],
        className: "member-type-selector"
      },
      editable: (params) => {
        return isCellEditable(params);
      },
      cellEditorSelector: cellEditorSelector,
    },
    {
      headerName: "First Name",
      field: "firstName",
      sortable: true,
      filter: true,
      width: 300,
      cellRenderer: "CellWrapper",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
      cellRendererParams: {
        label: 'First Name'
      }
    },
    {
      headerName: "Last Name",
      field: "lastName",
      sortable: true,
      filter: true,
      width: 300,
      cellRenderer: "CellWrapper",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
      cellRendererParams: {
        label: 'Last Name'
      }
    },
    {
      headerName: "Invite Status",
      field: "actionColumn",
      sortable: true,
      filter: true,
      width: 300,
      cellRenderer: "ActionColumn",
      filterParams: {
        valueFormatter: capitalValueFormatter
      },
      suppressColumnsToolPanel: true,
      cellRendererParams: {
        onEdit: onEdit,
        onSave: onSave,
        onCancel: () => {onEdit(-1)},
        editableCellId
      },
      cellClass: "ag-show-dropdown-modal",
    },
  ]);
  const [members, setMembers] = useState([]);
  const [teamType, setTeamType] = useState(null);

  const [createMember, { loading }] = useMutation(CREATE_MEMBER, {
    refetchQueries: "active",
    awaitRefetchQueries: true,
  });

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    rowClassRules: {
      // row style function
      'in-edit': (params) => {
        return params.data.isEditing && !params.data.needsSave;
      },
      'needs-save': (params) => {
        return params.data.needsSave
      },
    },
    columnDefs,
    pinnedTopRowData: [{
      email: 'fr-',
      type: 'fr-dropdown-',
      firstName: 'fr-',
      lastName: 'fr-',
      actionColumn: 'fr-create',
    }]
  };

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    setTimeout(gridApiRef.current.sizeColumnsToFit(), 0);
  }

  useEffect(async () => {
    if (selectedTeam && selectedTeamMembers) {
      setTeamType(selectedTeam.type);
      setMembers(transformData(selectedTeamMembers));
    } 

    if (gridApiRef.current) setTimeout(gridApiRef.current.sizeColumnsToFit(), 0);
  }, [selectedTeamMembers, selectedTeam]);

  useEffect(() => {
    const visibleColumns = ls.getItem('membersList') ? JSON.parse(ls.getItem('membersList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, []);

  function onCellClicked(e) {
    // ignore clicks on pinned row
    if (!e.data.id) return;
    lastClickedRowId = gridApiRef.current.getSelectedRows()[0]?.id;
  }

  function transformData(members) {
    const transformedTeams = members.map((item) => ({
      id: item.id,
      memberID: item.user.id,
      email: item.user.email,
      type: item.type,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      actionColumn: item.user.memberStatus || "PENDING",
      isEditing: false,
      needsSave: false,
      teamType: selectedTeam.type
    }));

    return transformedTeams;
  }

  function formDataValid(formData) {
    if (formData.email.length === 0) {
      addMessage({type: 'error', message: 'Please provide an email.'});
      return false;
    } else {
      const isEmail = emailRegExp.test(formData.email);
      if (!isEmail) {
        addMessage({type: "error", message: "Please provide a valid email."})
        return false;
      }
    }

    if (formData.type.length == 0) {
      addMessage({type: 'error', message: 'Please provide a member type.'});
      return false;
    }

    if (formData.firstName.length === 0) {
      addMessage({type: 'error', message: 'Please provide a first name.'})
      return false;
    }

    if (formData.lastName.length === 0) {
      addMessage({type: 'error', message: 'Please provide a last name.'})
      return false;
    }

    return true;
  }

  const onMemberAdd = async (event) => {
    event.preventDefault();
    if (loading) return;
    
    const data = gridApiRef.current.getPinnedTopRow(0).data;
    const formData = {
      email: data.email.replace('fr-', ''),
      firstName: data.firstName.replace('fr-', ''),
      lastName: data.lastName.replace('fr-', ''),
      type: data.type.replace('fr-dropdown-', ''),
    }

    if (formDataValid(formData)) {
      try {
        const input = {
          type: formData.type,
          id: selectedTeam.id,
          UserInput: {
            username: formData.email,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            memberStatus: "PENDING",
            confirmed: true,
          },
        };
        if (teamType === "INTERNAL") {
          input.UserInput.portfolio = portfolioID
        } else input.UserInput.portfolio = null;

        const updateReponse = await createMember({
          variables: { input }
        });

        if (updateReponse.errors || updateReponse.data.createMembership.user == null) {
          addMessage({ message: "Couldn't add user to team. Double check that the user is not already part of the team.", type: "error" })
        } else {
          const newMember = {
            id: updateReponse.data.createMembership.id,
            email: input.UserInput.email,
            type: input.type,
            firstName: input.UserInput.firstName,
            lastName: input.UserInput.lastName,
            actionColumn: input.UserInput.memberStatus || "PENDING",
            isEditing: false,
            needsSave: false,
            teamType: selectedTeam.type
          };
          setMembers([...members, newMember]);

          setTimeout(() => gridApiRef.current.setPinnedTopRowData([{
            email: 'fr-',
            type: 'fr-dropdown-',
            firstName: 'fr-',
            lastName: 'fr-',
            actionColumn: 'fr-create',
          }]), 0);

          setTimeout(() => gridApiRef.current.refreshCells(), 0);
          setTimeout(() => gridApiRef.current.sizeColumnsToFit(), 0);
          addMessage({ message: "User Added!" });
        }
      } catch (error) {
        addMessage({ message: error, type: "error" })
      }
    }
  }

  async function onSave(updateMembership, memberID, newType) {
    editableCellId = -1;

    try {
      const updateResponse = await updateMembership({
        variables: { 
          where: memberID,
          type: newType
        },
      });
      if (updateResponse.errors){
        throw updateResponse.errors[0].message;
      } else {
        addMessage({ message: "Member updated!" });
        setTimeout(gridApiRef.current.sizeColumnsToFit(), 0);
      }
    } catch(error) {
      addMessage({ message: error, type: "error" })
    }
  }

  return (
    <div className="lawfirms-list py-3">
      <TableContainer sx={{height: '100%', border: `1px solid ${theme.themeColor.sectionStroke}`}}>
        <form
          onSubmit={onMemberAdd}
          style={{height: '100%'}}
        >
          <StandardGrid
            tableName="membersList"
            onGridReady={onGridReady}
            rowData={members}
            onCellClicked={onCellClicked}
            gridOptions={gridOptions}
            onGridSizeChanged={onGridSizeChanged}
            components={frameworkComponents}
            singleClickEdit={true}
          />
        </form>
      </TableContainer>
    </div>
  )
}