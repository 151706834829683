const REGEX = /@(\[)(.*?)(\])(\()(.*?)(\))/g;

export const getMentionsFromComment = (comment) => {
  const matches = comment.match(REGEX);
  return matches ? matches.map(match => match.replace(/\([0-9]{1,}\)/g, '').replace('@', '').replace('[', '').replace(']', '')) : [];
}

export const replaceMentionsWithNames = (text, mentions) => {
  let comment = text;
  const toRemove = text.match(REGEX);
  if (toRemove) {
    toRemove.forEach((mention, idx) => {
      comment = comment.replace(mention, mentions[idx]);
    });
  }

  return comment;
}