import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div id='privacy-policy'>
      <h1 className='centered'>Privacy Notice</h1>
      <main className='tnc-content'>
        <section>
          <h3>Privacy Notice</h3>
          <p>
            Redhawk Research LLC (“We”, “Our” or “Us”) is committed to protecting your privacy and providing you
            with a safe online experience. This Privacy Policy covers the collection, use and disclosure of information
            collected through the website www.jonahdirect.com (“Site”) and the services offered through the Site and
            platform (“Services”) as data controllers. The use of information collected through our Services shall be
            limited to the purpose of providing the service for which you have engaged Us.
          </p>
          <p>
            If there is a discrepancy, conflict or inconsistency with this Privacy Notice and your Terms of Service
            Agreement with Us, the Data Privacy provisions in your Agreement with us will take precedence over the
            Data Privacy terms in this Privacy Notice.
          </p>
          <p>
            This Privacy Notice does not apply to data that we process on behalf of our Customer’s
            “Personal Data”in our capacity as our Customer’s data processor.
          </p>
        </section>
        <section>
          <h3>PRIVACY PRINCIPLES</h3>
          <p>
            We take your privacy very seriously. We acknowledge that data privacy is an ongoing responsibility.
            Thus, from time to time we will update this Privacy Notice as our business expands, and we undertake new
            busines opportunities that involve your personal data. Therefore, we strive to adhere to the following
            principles laid out in this Privacy Notice:
          </p>
          <p>
            BASIC PRINCIPLES OF OUR PRIVACY POLICY
          </p>
          <ol>
            <li>
              Notice – We tell you what information we collect, how we use it,
              how our users and Customers use it and when and how we share it.
            </li>
            <li>
              Choice – Upon request we will use best efforts to cooperate with individuals who choose (opt-out) not
              to allow their Personal Data (a) to be disclosed to a third party (other than our third party processors),
              or (b) to be used for a purpose other than the purpose for which it was originally collected or
              subsequently authorized by the individual.
            </li>
            <li>
              Accountability for Onward Transfers – We take steps to gain assurances from our processors that they will safeguard
              Personal Data consistent with this policy and take steps against to stop disclosure in violation of this policy.
            </li>
            <li>
              Security – We will take reasonable precautions to protect Personal Data in our possession from loss,
              misuse and unauthorized access, disclosure, alteration, and destruction.
            </li>
            <li>
              Data Integrity & Purpose Limitation – We will endeavor to use Personal Data only in ways that are compatible
              with the purposes for which it was collected or subsequently authorized by the subject individual. We will
              take reasonable steps consistent with our goal of ensuring that Personal Data is relevant to its intended
              use, accurate, complete, and current.
            </li>
            <li>
              Personal Data Requests – Upon request, we will grant individuals reasonable access to Personal Data that
              we hold about them, and we will take reasonable steps to permit individuals to correct, amend, or delete
              information that is demonstrated to be inaccurate or incomplete.
            </li>
            <li>
              Resource, Enforcement and Liability – Upon request we will endeavor to reasonably cooperate with compliance
              audits conducted by our customers or data providers. Any employee that we determine is in violation of
              this policy will be subject to disciplinary action up to and including termination of employment. We also
              may pursue reasonable procedures for dispute resolution and binding arbitration in certain cases where a
              data subject believes that we have not complied with the law with respect to the application of our privacy policy.
            </li>
          </ol>
        </section>
        <section>
          <h3>PERSONAL DATA WE COLLECT</h3>
          <p>
            Personal Data, or Personally Identifiable Information or “PII” means any information relating to or which
            can be reasonably connected to an identified or identifiable natural person. We may collect Personal Data
            from users of the Services (“Users”) our website, marketing efforts, in-person events and trade shows and other means.
          </p>
          <p>
            WE COLLECT:
          </p>
          <ul>
            <li>
              <strong>Contact information</strong> – such as your first and last name, email address, phone number, business
              name and physical address when you contact US through our website, social media or other means. We collect some
              of this information using various third-party vendors.
            </li>
            <li>
              <strong>Online data</strong> – such as IP address, personal information contained in cookies (see below regarding detailed
              information regarding cookie collection). We also track usage information about how you interact with our
              website and other internet accessible sites that we may use to interact with you, such as Twitter, Facebook,
              LinkedIn and Youtube.
            </li>
            <li>
              <strong>Website and Server Logs</strong> – When you visit our website and/or use our online platform, our servers capture your
              activity, your Internet Protocol (IP) address, computer settings, which browser you use, what language your
              browser is using, the date/time of your usage, any referring URL (the website that sent you to us), and additional
              information contained in cookies. (see detailed cookie information below)
            </li>
            <li>
              <strong>Correspondence</strong> – If you contact us by email, postal mail, or other form of communication that reveals your personal
              data, we may store such information and use it to respond to you, to notify you of additional services, or to
              keep a record of your comment or complaint, accommodation request, or similar issues. As with other forms of
              data collection, if you desire to have your personal data “erased” please contact us at info@redhawkresearch.com.
            </li>
            <li>
              <strong>Customer feedback</strong> – from time to time, we may ask you to provide feedback either directly, over the phone, through
              our software or through our support team. You are not required to provide feedback, but if you do, your name and
              comments will be stored and used to improve the services we deliver to you through our platform.
            </li>
            <li>
              <strong>Our Services Platform</strong>
              <ul>
                <li>
                  Data Collected through our services via uploading of data (“Content”) may include third party personally identifiable
                  information collected and processed under the direction of our Users, who are the controllers of that data.
                </li>
                <li>
                  We process Customer Data, which may contain Personal Data, under the control of our User, and we have no direct
                  control or ownership of the Personal Data that we process on behalf of our Users. Users are fully responsible for
                  complying with any applicable data protection laws or regulations that require notice, disclosure, and/or obtaining
                  consent prior to transferring the Personal Data to us for processing. Terms that are not otherwise defined in this
                  notice shall have the meaning as set forth in our Terms of Service or Data Processing Agreement addendum, if any.
                  In the event of a conflict between this Privacy Notice and the Terms of Service and/or Customer Data Processing
                  Agreement, the Terms of Service and Data Processing Agreement will control.
                </li>
                <li>
                  An individual who seeks access to their data, seeks to correct, amend or delete inaccurate data or wishes to
                  opt-out of or remove Personal Data provided by our Users should direct his/her query to the User he/she interacts
                  with directly (the data controller). If a third party requests that we remove personally identifiable information
                  on their behalf, we will attempt to redirect their demand/inquiry to the relevant User.
                </li>
                <li>
                  We may transfer personal information to other companies that help us provide our Services. Transfers to subsequent
                  third parties are covered by the provisions in this policy regarding notice and choice and the service agreements with our Users.
                </li>
                <li>
                  Users who use our website and Services will have their Content stored in the United States. In the future,
                  we may expand services into other locations, and intend to provide updates on where customer data and content is stored.
                </li>
              </ul>
            </li>
            <li>
              <strong>Detailed information on our collection of Cookies</strong>
              <ul>
                <li>
                  <strong>Use of Cookies</strong> When you visit our Site we use cookies, or similar technologies like single-pixel
                  gifs and web beacons, to record and log data. A cookie is a text file that is placed on your local storage by a web
                  page server. Cookies are useful to personalize your online experience. We use both session-based and persistent
                  cookies. Session-based cookies last only while your browser is open and are automatically deleted when you close
                  your browser. Persistent cookies last until you or your browser delete them or until they expire. They are unique
                  and allow us to do site analytics and customization, among other similar things. If you access our Site through your
                  browser, you can manage your cookie settings.
                </li>
                <li>
                  <strong>Disabling Cookies</strong> Most web browsers automatically accept cookies, but if you prefer, you can edit
                  and manage your browser options to block them in the future. The Help portion of the toolbar on most browsers will
                  tell you how to disable and manage your cookies settings. If you disable cookies you may still browse public areas
                  of the Site, but some features and Services may not function.
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section>
          <h3>HOW DO WE USE YOUR PERSONAL DATA?</h3>
          <p>
            We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we
            need to use it for another reason and that reason is compatible with the original purpose. If we need to use your
            personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
          </p>
          <ul>
            <li>
              <strong>Websites, Social Media, Events and other Marketing means</strong>
              <ul>
                <li>
                  We collect your personal data to administer our website, for marketing purposes, to continuously improve your
                  experience in using our website and services, to ensure that our website and social media content displays in
                  the most efficient and effective manner, for usage trend monitoring and advertising.
                </li>
                <li>
                  We also collect your information and use it to improve our network and information security practices.
                </li>
                <li>
                  We also collect your personal data that you provide to us to inform you of additional services, to schedule
                  meetings, or simply to reply to you based on your inquiry.
                </li>
              </ul>
            </li>
            <li>
              <strong>Our Platform</strong>
              <ul>
                <li>
                  We collect and use your personal information to operate our Site and deliver the services you have requested.
                  We may also use your personally identifiable information to inform you of other products or services available
                  from us and out affiliates with your prior consent. We may use the personal data we collect for the following
                  additional reasons:
                  <ul>
                    <li>
                      Manage your usage of our service, respond to inquiries and comments, and provide customer service and support.
                    </li>
                    <li>
                      Send customers alerts, updates, security notifications and other administrative communications.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>Customer Support</strong> – We both directly and through our third-party processors collect site analytics
              information detailed below and combine it with your email address and other information you provide using fields
              or sign-in for the purpose of providing customer service and follow up on the Services. We may also access content
              solely for the purpose of providing Customer Support.
            </li>
          </ul>
        </section>
        <section>
          <h3>Google Analytics</h3>
          <p>
            Google Analytics provides us reports with website trends without identifying individual visitors. Site usage is tracked
            using Google Analytics in accordance with their <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">Privacy Policy</a>.
            However, if you do not want your data to be used by Google Analytics, you may opt-out by installing <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">Google Analytics Opt-out Browser Add-on</a>.
          </p>
        </section>
        <section>
          <h3>Passwords and Logins (Unique Identifier)</h3>
          <p>
            Passwords and usernames are used for user authentication.
          </p>
        </section>
        <section>
          <h3>Direct Marketing and Opt-Out</h3>
          <p>
            We use User Personal Data to communicate with you regarding the provision of the Services, but also to let you know about additional features and services we provide that may be of interest to you. If you do not wish to receive marketing communications, you may opt out at any time.
          </p>
        </section>
        <section>
          <h3>Opt-Out</h3>
          <p>
            You may also sign up for blog updates from our Web site. In both of these cases, we will use your name and email address to send these materials to you. You may choose to stop receiving these communications by following the unsubscribe instructions included in these emails or you can contact us at:
          </p>
          <p>
            Email:  info@redhawkresearch.com
          </p>
          <p>
            Mail: Redhawk Research, LLC, 95 Homestead Rd, Tenafly, NJ 07670
          </p>
        </section>
        <section>
          <h3>INFORMATION SHARING</h3>
          <p>
            We do not sell, rent or lease its customer lists to third parties. Further, we believe that we do not disclose your Personal Data to any third party in a manner that would be considered a “sale” under applicable laws. We will share your personal information with trusted third parties only in the ways that are described in this privacy policy.
          </p>
          <p>
            We will not otherwise transmit, disclose or share your personal data to third parties for their independent use unless: 1. You specifically authorize it; 2. The information is given to comply with the law, such as a search warrant, court order or subpoena, to enforce an agreement with have with you, or to protect our rights, property or safety, or the rights, property or safety of our employees or others; 3. The information is given to our agents, vendors, affiliates or service providers who perform functions on our behalf; 4. To address emergencies or acts of God; 5. To address disputes, claims, or to persons who are your agents or representatives purporting to have legal authority to act on your behalf. We may also collect and analyze aggregated data about our Users and Site visitors and transmit the results of such aggregated (but de-identified) information to our partners, vendors, service providers, advertisers, and third parties for purposes of marketing and promotions.
          </p>
        </section>
        <section>
          <h3>Processing Your Payment</h3>
          <p>
            We do not store your payment information. Customer subscription level is recorded in our application and passed to Intercom only to verify the account for customer support purposes.
          </p>
          <p>
            Assisted credit card transactions, used when Customers provide us with their credit card payment; we will record the subscription level and provide the subscription level to our payment processor which processes the payment and renewals. We do not store credit card information separately from other data. Access to User data is limited to key personnel; such access is personally identifiable to specific individuals and password protected to maintain control over access and preserve accountability for misuse.
          </p>
          <p>
            Payments information is collected by our banking service provider in the course of receiving payments made by check/ACH.
          </p>
        </section>
        <section>
          <h3>Law Enforcement and Internal Operations </h3>
          <p>
            Personal Data may be provided where we are required to do so by law, or if we believe in good faith that it is reasonably necessary
          </p>
          <ul>
            <li>
              to respond to claims asserted against us or to comply with the legal process (for example, discovery requests, subpoenas or warrants);
            </li>
            <li>
              to enforce or administer our policies and agreements with users;
            </li>
            <li>
              for fraud prevention, risk assessment, investigation, customer support, product development and de-bugging purposes;
            </li>
            <li>
              or to protect the rights, property or safety of our users or members of the general public.
            </li>
          </ul>
          <p>
            We will use commercially reasonable efforts to notify users about law enforcement or court ordered requests for data unless otherwise prohibited by law. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to any third-party request to compel disclosure of your information.
          </p>
        </section>
        <section>
          <h3>Third-Party Data Processors or Service Providers</h3>
          <p>
            We engage Service Providers or “Data Processors” to perform services on our behalf to help us provide services to you. Additionally, we engage third-party Data Processors to assist us in processing of credit cards, marketing, IT infrastructure, cloud computing, SaaS, data enhancement and other services to enhance your experience with our subscription services as well as our website and business in general.
          </p>
          <p>
            These third-party Data Processors may have access to your personal data in order to provide such functions.
          </p>
          <p>
            We require all Data Processors to agree to take reasonable steps to keep Personal Data that we provide to them secure. We never authorize them to use or disclose your Personal Data except in connection with providing their services.
          </p>
          <p>
            In addition, we may share data with trusted partners as data processors to help us perform statistical analysis, send you email or postal mail, provide customer support, or to deliver our services to you. All such third parties are prohibited from using your personal information except to perform data processing according to our instructions in order to provide our services to you, our customer.
          </p>
        </section>
        <section>
          <h3>Business Transfer</h3>
          <p>
            We may sell, transfer, merge or otherwise share some or all of our assets, including your Personal Data, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy. Under such circumstances, we will use commercially reasonable efforts to notify its users if their personal information is to be disclosed or transferred and/or becomes subject to a different privacy policy.
          </p>
        </section>
        <section>
          <h3>SECURITY OF YOUR PERSONAL INFORMATION</h3>
          <p>
            <strong>How is my data protected? </strong>
          </p>
          <p>
            We secure your personal information from unauthorized access, use or disclosure. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. When sensitive information (such as log-in credentials) is submitted, it is protected through the use of encryption. We restrict access to your personal data to those employees who require that information to provide our services to you. Furthermore, our employees are trained regarding the importance of confidentiality and maintaining the privacy and security of your information.
          </p>
          <p>
            Reasonable administrative, technical, and physical security measures taken, may include but are not limited to:
          </p>
          <ul>
            <li>
              Restricting access to Personal Data protected by passwords, which are restricted and revoked when staff departs
            </li>
            <li>
              Restricting access to Personal Data to key staff on a need to know basis
            </li>
            <li>
              Staff privacy and security training
            </li>
            <li>
              Requiring key contractors sign non-disclosure agreements (NDA’s)
            </li>
            <li>
              Intrusion detection
            </li>
            <li>
              Vulnerability scans
            </li>
            <li>
              Penetration testing
            </li>
            <li>
              Backups at offsite location
            </li>
            <li>
              Web application firewall
            </li>
            <li>
              Encryption methods
            </li>
            <li>
              Use of SSL protocols
            </li>
            <li>
              Data centers that are reputable in the industry
            </li>
            <li>
              Malware detection
            </li>
          </ul>
          <p>
            No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee the security of any information we store, process, or transmit.
          </p>
        </section>
        <section>
          <h3>Data Storage and retention</h3>
          <p>
            <strong>
              Personal data is stored on our servers as well as the servers of our cloud and SaaS based systems, located in the United States. We keep personal data for as long as the User is a current subscriber. However, upon termination of subscription to our services, personal data will be removed after 6 months if there is no subscription renewal. For more specific information on where and how long personal data is stored, please contact us at info@redhawkresearch.com
            </strong>
          </p>
        </section>
        <section>
          <h3>YOUR CHOICES</h3>
          <p>
            <strong>Right to Review or Change Your Data</strong>
          </p>
          <p>
            If your personal information changes, you may correct, update, amend, remove, or ask to have it removed by making the change on your user account settings page or by contacting us by phone or email at the contact information available on our Web site.
          </p>
          <p>
            We will retain your information for as long as your account is active, as needed to provide you the Services and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </section>
        <section>
          <h3>Right to Withdraw Consent</h3>
          <p>
            You have the right to withdraw consent where such consent is required to share or use data and you may request that we delete your Personal Data.
          </p>
          <p>
            If you receive communications from us and no longer wish to receive them, please follow the removal instructions in the email or change your account settings.
          </p>
        </section>
        <section>
          <h3>Right to Remove</h3>
          <p>
            You have the right to delete your Personal Data at your discretion. However, since your Personal Data is required for us to provide the Services to you, deleting it will also terminate your access to the services. Deleting your Personal Data does not mean that all of it will be removed. We take steps to delete Personal Data and Content that is no longer necessary in relation to provide the Services by endeavoring to remove it within 12 months of you terminating your account.
          </p>
          <p>
            If we are legally required to comply with such a request, we will confirm your identity and delete your personal data in such time frame as required by law.
          </p>
          <p>
            We may be required by law or to retain it to exercise or defend legal claims, or contractual obligations with our customers to retain some information in connection with our obligation to provide the Services. We may de-identify and anonymize some data for purposes of retaining it.
          </p>
          <p>
            We are subject to the investigatory and enforcement powers of the Federal Trade Commission (“FTC”). Should an individual be unable to resolve a complaint with us, they may contact the FTC at the following address:
          </p>
          <p>Federal Trade Commission</p>
          <p>Attn: Consumer Response Center, 600 Pennsylvania Avenue NW, Washington, DC 20580</p>
          <p>or <a href='https://www.ftc.gov' target="_blank" rel="noreferrer">www.ftc.gov</a></p>
        </section>
        <section>
          <h3>THIRD PARTY LINKS</h3>
          <p>
            Our Site may include links to other Web sites whose privacy practices may differ from our practices. If you submit personal information to any of those sites, your information is governed by their privacy policies. We are not responsible for the privacy statements or other content on Web sites outside of our web site.
          </p>
        </section>
        <section>
          <h3>UPDATES TO THE PRIVACY POLICY</h3>
          <p>
            This policy may be amended from time to time to reflect changes in our business methods or changes in applicable laws and principles. We will announce changes to this policy either through email, posting on our website, communication via our Services, or other means as appropriate.
          </p>
        </section>
        <section>
          <h3>SUPPLEMENTAL CALIFORNIA AND NEVADA PRIVACY POLICY</h3>
          <p>
            This section solely applies to residents of Nevada and California.
          </p>
          <p>
            We do not knowingly collect the Personal Data of consumers, as defined in the California Consumer Privacy Act (CCPA) except as may be provided by our Users. As a “Service Provider” under CCPA, we may come into possession of consumer Personal Data and will treat such data in accordance with the CCPA to the extent we are required to do so.
          </p>
          <p>
            While the specifics as to what “sale” of consumer Personal Data is, still unsettled at this time, we believe that do not disclose your Personal Data to any third party in a manner that would be considered a “sale” of Personal Data of consumers under the CCPA.
          </p>
        </section>
        <section>
          <h3>Categories of California Data Collected</h3>
          <p>
            We collect the following categories of Personal Data from the authorized users of our Service and website:
          </p>
          <ul>
            <li>First name</li>
            <li>Last name</li>
            <li>Institutional affiliation</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>User history</li>
            <li>Location (beacon)</li>
            <li>Time zone</li>
            <li>IP address</li>
            <li>User actions</li>
            <li>Credit card information by our third party processors for billing and invoice purposes</li>
            <li>Device ID</li>
          </ul>
        </section>
        <section>
          <h3>Categories of Sources from Which We Collect Personal Data</h3>
          <p>
            The sources from which we may have collected personal information about you or your use of the Services are listed above in this Privacy Notice:
          </p>
          <p>
            More details concerning the business and commercial purposes are set forth in the “How We Use Your Personal Information” section. We may disclose the categories of personal information identified in this California Privacy Notice about our California consumers for our operational purposes where the use of such personal information is reasonably necessary and proportionate to achieve the operational purpose for which the personal information was collected or processed or for another operational purpose that is compatible with the context in which the personal information was collected.
          </p>
        </section>
        <section>
          <h3>Categories of Third Parties with Whom We Shared the Personal Information</h3>
          <p>
            The categories of third parties, as defined by the CCPA, with whom we share personal information include our business partners, data analytics and the third parties doing business with you.
          </p>
        </section>
        <section>
          <h3>Categories of Personal Information sold, shared or Disclosed for a Business Purpose</h3>
          <p>
            We may disclose for a business purpose (each as defined under CCPA) any or all of the personal information collected from and about you as set forth in the How Do We User Your Personal Data and Shared Information sections.
          </p>
        </section>
        <section>
          <h3>Your California Privacy Data Subject Rights</h3>
          <p>
            If you are a California resident who is considered a “consumer” in your dealings with us, you may have certain rights. California law may permit you to request that we:
          </p>
          <ul>
            <li>
              Provide you the categories of personal information we have collected or disclosed about you in the last twelve months; the categories of sources of such information; the business or commercial purpose for collecting or selling your personal information; and the categories of third parties with whom we shared personal information.
            </li>
            <li>
              Provide access to and/or a copy of certain information we hold about you.
            </li>
            <li>
              Delete certain information we have about you.
            </li>
          </ul>
          <p>
            You may have the right to receive information about the financial incentives that we offer to you (if any). You also have the right to not be discriminated against (as provided for in applicable law) for exercising certain of your rights. Certain information may be exempt from such requests under applicable law. For example, we need certain types of information so that we can provide the Services to you and for compliance with applicable law. If you ask us to delete certain information, you may no longer be able to access or use the Services.
          </p>
          <p>
            If you would like to exercise any of these rights, please email us at info@redhawkresearch.com or contact us at phone number (949) 385-0771. You will be required to verify your identify before we fulfill your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.
          </p>
          <p>
            If you are a consumer who has provided Personal Data to us, you also have the right to opt out of the sale of your personal information. California law broadly defines sale such that it may include allowing third parties to receive certain information, such as cookies, IP address (combined with other Personal Data) and/or browsing behavior, to deliver targeted advertising. If you would like to opt out, you may do so by emailing us info@redhawkresearch.com.
          </p>
        </section>
        <section>
          <h3>YOUR CALIFORNIA PRIVACY RIGHTS UNDER THE SHINE THE LIGHT LAW</h3>
          <p>
            California residents who have an established business relationship with us may make a written request to us about whether we disclosed any Personal Information to any third-parties for the third-parties’ direct marketing purposes during the prior calendar year. To make such a request, please send an email, call or write us:
          </p>
          <p>
            Email:  info@redhawkresearch.com
          </p>
          <p>
            Mail:  Redhawk Research, LLC, 95 Homestead Rd, Tenafly, NJ 07670
          </p>
        </section>
        <section>
          <h3>NOTICE FOR NEVADA RESIDENTS</h3>
          <p>
            Under Nevada law, certain Nevada consumers may opt out of the sale of “personally identifiable information” for monetary consideration to a person for that person to license or sell such information to additional persons. “Personally identifiable information” includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online.
          </p>
          <p>
            We do not engage in such activity; however, if you are a Nevada resident who has purchased or leased goods or services from us, you may submit a request to opt out of any potential future sales under Nevada law by email to: info@redhawkresearch.com. Please note we will take reasonable steps to verify your identity and the authenticity of the request. Once verified, we will maintain your request in the event our practices change.
          </p>
        </section>
        <section>
          <h3>CONTACT INFORMATION</h3>
          <p>
            If you believe that we have not adhered to this Statement or have questions, please contact us at:
          </p>
          <p>
            Email:  info@redhawkresearch.com
          </p>
          <p>
            Mail:  Redhawk Research, LLC, 95 Homestead Rd, Tenafly, NJ 07670
          </p>
          <p>
            <strong>UPDATED:</strong> February 1, 2022
          </p>
        </section>
      </main>
    </div>
  )
}