import { useMutation } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { SUBMIT_FORM_VALUES_MUTATION } from "../../apollo/mutations/submitFormValuesMutation";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { MessageContext } from "../../context/MessageContext";
import Button from "../../styled/Button/Button";
import FormField from "./FormField";
import FormFieldSelect from "./FormFieldSelect";
import { UserContext } from '../../context/UserContext';
import FormFieldFile from "./FormFieldFile";
import FormFieldCheckbox from "./FormFieldCheckbox";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";

const TaskForm = ({ form, processID, setFormIsConfirmed, onSaveForm, assetID, milestoneID }) => {
  const [formValues, setFormValues] = useState([]);
  const { addMessage } = useContext(MessageContext);
  const [validForm, setValidForm] = useState(true)
  const { user } = useContext(UserContext);

  const [submitFormValues] = useMutation(SUBMIT_FORM_VALUES_MUTATION, {
    refetchQueries: [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  useEffect(() => {
    if (form.form_fields) {
      setFormValues([]);
      form.form_fields.forEach((field) => {
        setFormValues((previousState) => ([
          ...previousState,
          {
            id: field.id,
            label: field.label,
            value: field.document ? field.document.name : field.value,
            condition: field.condition,
            type: field.type
          }
        ]));
      });
    }
  }, [form]);

  const fieldValidation = () => {
    setValidForm(true)
    formValues.forEach((field) => {
      if(field.type == 'number' && parseInt(field.value) < 0) {
        setValidForm(false)
      }
    }) 
    return validForm
  }

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (fieldValidation()) {
      const copy = [...formValues].map((item) => ({...item}));
      copy.forEach((item) => {delete item.condition; delete item.type});
  
      const response = await submitFormValues({variables: {
        input: { formValues: copy }
      }});
  
      if (setFormIsConfirmed) setFormIsConfirmed(true);
  
      if (response && !('errors' in response)) {
        addMessage({ message: 'Form updated!' });
        
        const hasSelect = formValues.find(field => field.type === 'select');
        let validFields = [];
        if (hasSelect) {
          // remove the fields that are not part of this form
          const chosenSelect = hasSelect.value;
          validFields = formValues.filter((item) => {
            return item.condition === null || item.condition.includes(chosenSelect);
          })
        }
  
        const event = {
          title: `${form.title} form updated`,
          type: "form-update",
          createdDate: new Date(),
          milestoneID: milestoneID,
          process: processID,
          description: validFields.length
            ? validFields.map(item => `${item.label}: ${item.value}`).join('; ')
            : formValues.map(item => `${item.label}: ${item.value}`).join('; '),
        }
  
        await createEvent({
          variables: {
            event
          }
        });
  
        if (onSaveForm) onSaveForm(formValues);
      } else {
        addMessage({ message: 'Something went wrong. Try again later!', type: 'error' });
      }

    } else if (!validForm) {
      addMessage({ message: 'Please check your inputs and try again!', type: 'error' })

    } else {
      addMessage({ message: 'Something went wrong. Try again later!', type: 'error' });
    }
  }

  const findValue = (fieldId) => {
    if (formValues) {
      const field = formValues.find((field) => field.id === fieldId);
      return field ? field.value : '';
    } 
    return ''
  }

  const handleChange = (event, field) => {
    const newValues = [...formValues];
    const idx = newValues.findIndex((newField) => newField.id === field.id);
    newValues[idx] = {
      id: field.id,
      value: field.type == 'number' ? (event.target.value >= 0 ? event.target.value : "") : event.target.value,
      label: field.label,
      condition: field.condition,
      type: field.type
    }

    setFormValues(newValues);
  }

  const handleCheckbox = (field, value) => {
    const newValues = [...formValues];
    const idx = newValues.findIndex((newField) => newField.id === field.id);
    newValues[idx] = {
      id: field.id,
      value: value.toString(),
      label: field.label,
      condition: field.condition,
      type: field.type
    }

    setFormValues(newValues);
  }

  const fields = [...form.form_fields].sort((fieldA, fieldB) => fieldA.order < fieldB.order ? -1 : 1);

  const isDisabled = (field) => {
    if (field.permissions && !field.permissions.includes(user.userType)) {
      return true;
    }

    return false;
  }

  const anyFieldIsRequired = fields.some((field) => field.required);

  return (
    <Box sx={{ width: '100%' }}>
      <strong>{`${form.title}${anyFieldIsRequired ? '*' : ''}`}</strong>
      <form onSubmit={onSubmitForm} style={{ marginTop: '15px' }}>
        <Stack direction="column" spacing={2}>
          {fields.map((field) => {
            
            if (field.type === "select")
              return (
                <FormFieldSelect
                  className={`${field.label.split(' ').join('-')}-select`}
                  key={field.label}  
                  field={field}
                  value={findValue(field.id)}
                  handleChange={handleChange}
                  disabled={isDisabled(field)}
                />
              )

            else if (field.type === "file")
              return (
                <FormFieldFile
                  assetID={assetID}
                  processID={processID}
                  milestoneID={milestoneID}
                  field={field}
                  file={field.document}
                />
              )
            else if (field.type === 'checkbox')
              return (
                <FormFieldCheckbox 
                  field={field}
                  value={findValue(field.id)}
                  handleChange={handleCheckbox}
                  disabled={isDisabled(field)}
                />
              )

            return (
              <FormField
                className={`${field.label.split(' ').join('-')}-input`}
                key={field.label}
                field={field}
                value={findValue(field.id)}
                handleChange={handleChange}
                formValues={formValues}
                disabled={isDisabled(field)}
              />
            )
          })}
          <Button className="submit-task-form-button" type="submit">Save</Button>
        </Stack>
      </form>
    </Box>
  )
}

export default TaskForm;