import { Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "../../styled/Button/Button";
import { useMutation } from "@apollo/client";
import { IMPORT_LOAN_LIST_MUTATION, IMPORT_LOANS_DETAILS_MUTATION, IMPORT_LOANS_PROPERTIES_MUTATION, IMPORT_LOANS_TO_JONAH } from "../../apollo/mutations/importLoanAssetsMutation";
import { GET_NOTIFICATIONS } from "../../apollo/queries/getNotifications";
import { GET_PORTFOLIOS } from "../../apollo/queries/getPortfolios";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import Typography from "../../styled/Typography/Typography";

const APIUpdate = ({ portfolioID, lastApiImportDate, assetCount=0, hasActiveProcess, client }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [importLoanList] = useMutation(IMPORT_LOAN_LIST_MUTATION);
  const [importLoanListDetails] = useMutation(IMPORT_LOANS_DETAILS_MUTATION);
  const [importLoanListProperties] = useMutation(IMPORT_LOANS_PROPERTIES_MUTATION);
  const [importLoanListToJonah] = useMutation(IMPORT_LOANS_TO_JONAH);

  const { addMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  const onRefreshList = async () => {
    setIsLoading(true);
    const loanListResponse = await importLoanList({ variables: { portfolioID }});
    const loanList = loanListResponse?.data?.importLoanList;
    if (!loanList || loanList.length === 0) {
      addMessage({ message: "The server has encountered an error while trying to get loans." });
      setIsLoading(false);
      await client.refetchQueries({ include: [{ query: GET_NOTIFICATIONS }] });
      return;
    }

    const loanListDetailsResponse = await importLoanListDetails({ variables: { portfolioId: portfolioID, loansList: loanList }});
    const loanListDetails = loanListDetailsResponse?.data?.importLoanListDetails;
    if (!loanListDetails || loanListDetails.length === 0) {
      addMessage({ message: "The server has encountered an error while trying to get loan details." });
      setIsLoading(false);
      await client.refetchQueries({ include: [{ query: GET_NOTIFICATIONS }] });
      return;
    }

    const loanListPropertiesResponse = await importLoanListProperties({ variables: { portfolioId: portfolioID, loanListWithDetails: loanListDetails }});
    const loanListProperties = loanListPropertiesResponse?.data?.importLoanListWithProperties;
    if (!loanListProperties || loanListProperties.length === 0) {
      addMessage({ message: "The server has encountered a 500 error while trying to get loan property details." });
      setIsLoading(false);
      await client.refetchQueries({ include: [{ query: GET_NOTIFICATIONS }] });
      return;
    }
        
    const imported = await importLoanListToJonah({ 
      variables: { portfolioId: portfolioID, loanListWithProperties: loanListProperties }, 
      awaitRefetchQueries: true,
      refetchQueries: [
        "active",
        { query: GET_NOTIFICATIONS },
        { query: GET_PORTFOLIOS }
      ],
    });

    if (imported.errors && imported.errors.length > 0) {
      addMessage({ message: "The server has encountered a 500 error while trying to update loans." });
      throw new Error(imported.errors[0].message);
    } else {
      addMessage({ message: "Assets imported successfully." });
      setIsLoading(false);
    }

    if (hasActiveProcess) {
      const form = new FormData();
      form.append("portfolioID", portfolioID);
      form.append("userID", user.id)
      await axios.post(process.env.REACT_APP_NEXT_IMPORT_API_URL + "/api/upload-to-tmo",
        form
      )
        .then(() => {
          addMessage({ message: "Asset data pushed successfully." });
        })
        .catch(() => {
          setIsLoading(false);
          addMessage({ message: "The server has encountered a 500 error while trying to upload to the TMO API." });
        })
      await client.refetchQueries({ include: [{ query: GET_NOTIFICATIONS }] });
    }
  }
  
  const fmtDate = new Date(+lastApiImportDate).toLocaleString();

  if (assetCount === 0 && !lastApiImportDate) return <span />;

  return (
    <Stack sx={{ maxWidth: 500, maxHeight: "46px", mt: 2, mb: -5, ml: 2 }} direction={'row'} spacing={2} alignItems="end">
      {assetCount > 0 && <Button sx={{ maxWidth: 200 }} onClick={onRefreshList} loading={isLoading}>Refresh asset list</Button>}
      {lastApiImportDate && <Typography variant={"caption"}>Last Update: {fmtDate}</Typography>}
    </Stack>
  );
}

export default APIUpdate;