import { gql } from "@apollo/client";

export const GET_TASK_FLOW_TEMPLATES_QUERY = gql`
  query FindTaskFlowTemplate($portfolioID: ID) {
    findTaskFlowTemplate(portfolioID: $portfolioID) {
      id
      name
      description
      subtasks
      deleted
      createOnMilestone
      createOnMilestoneID
      states
      formTemplate
      showToEveryone
      global
      subcategory
      processType
      version
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_TASK_FLOW_TEMPLATES_FOR_MILESTONE = gql`
  query findStepTaskFlowTemplate($stepID: String, $state: String!) {
    findStepTaskFlowTemplate(
      stepID: $stepID
      states_contains: $state
    ) {
      id
      name
      description
      subtasks
      deleted
      createOnMilestone
      createOnMilestoneID
      states
      version
    }
  }
`;