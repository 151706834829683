import React from "react";
import { Box } from '@mui/material';
import Modal from "../../styled/Modal/Modal";
import AssetImport from "../../pages/AssetImport";

const ImportAssets = ({ show, onClose, firstImport, assetRefetch }) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Box mt={5}>
        <AssetImport onClose={onClose} firstImport={firstImport} assetRefetch={assetRefetch} />
      </Box>
    </Modal>
  )
}

export default ImportAssets;