import React, { useRef, useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../../apollo/queries/getNotifications';
import { MARK_NOTIFICATIONS_READ } from '../../apollo/mutations/markNotificationsRead';
import { Box, Button } from '@mui/material';
import StandardGrid from '../Grid/Grid';
import NotificationType from './NotificationType';
import TableDateTime from '../TableDateTime/TableDateTime';
import ColoredDot from "../ColoredDot/ColoredDot";
import { MessageContext } from '../../context/MessageContext';


const components = {
  NotificationType,
  TableDateTime
}

const Notifications = () => {
  const gridApiRef = useRef(null);
  const { addMessage } = useContext(MessageContext);

  const [notifications, setNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  const { data } = useQuery(GET_NOTIFICATIONS);
  const [markNotificationsAsRead, { loading: dismissLoading }] = useMutation(MARK_NOTIFICATIONS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    if (data?.getNotifications) {
      const transformed = transformNotificationsData(data.getNotifications)

      const sorted = transformed.sort((a, b) => {
        if (a.read === b.read) 
          return new Date(b.createdAt) - new Date(a.createdAt);
        return a.read - b.read;
      });

      setNotifications(sorted);
    }
  }, [data]);

  const transformNotificationsData = (data) => (
    data.map((item) => ({
      id: item.id,
      type: item.type,
      message: item.message,
      read: item.read,
      createdAt: new Date(+item.createdAt),
      redirectUrl: item.redirectUrl,
    }))
  );
  
  const columnDefs = [
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      cellRenderer: 'NotificationType',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 275
    },
    {
      headerName: "Message",
      field: "message",
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      width: 750,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      sortable: true,
      filter: true,
      width: 400,
      cellRenderer: 'TableDateTime',
    },
    {
      headerName: "Seen",
      field: "read",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: (params) => {
        return params.data.read
          ? <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot color="green" /></span>
          : <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot /></span>
      }
    },
  ];
  
  const gridOptions = {
    suppressPropertyNamesCheck: true,
    columnDefs: columnDefs,
    rowSelection: 'multiple',
    getRowClass: (params) => {
      return params.data.read
        ? 'read-notification'
        : 'unread-notification'
    }
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const onGridSizeChanged = () => {
    gridApiRef.current.sizeColumnsToFit();
  };

  const onCellClicked = async () => {
    const link = gridApiRef.current.getSelectedRows()[0]?.redirectUrl;
    if (link) {
      // if (link.includes(process.env.REACT_APP_STRIPE_REDIRECT)) {
      // return <Redirect to={link}/>
      // } else {
      const win = window.open(link, "_blank");
      win?.focus();
      await markNotificationsAsRead({
        variables: { notificationIDs: [gridApiRef.current.getSelectedRows()[0].id] }
      });
      // }
    } else return;
  };

  const onCheckboxClick = () => {
    const rows = gridApiRef.current.getSelectedRows();
    setSelectedNotifications(rows);
  }

  const dismissNotifications = async (notificationIDs) => {
    if (!notificationIDs) {
      return addMessage({ message: 'No notifications selected', severity: 'error' });
    }

    await markNotificationsAsRead({
      variables: { notificationIDs: notifications.map((notification) => notification.id) }
    });

    setSelectedNotifications([]);
    gridApiRef.current.deselectAll();
  }

  const onDismissAll = async () => {
    const notificationIDs = notifications
      .filter((notification) => !notification.read)
      .map((notification) => notification.id);

    await markNotificationsAsRead({
      variables: { notificationIDs }
    });
  };

  return (
    <Box
      sx={{ height: '75vh', width: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <StandardGrid
        tableName="notifications"
        onCellClicked={onCellClicked}
        onGridReady={onGridReady}
        rowData={notifications}
        pagination={true}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={components}
        onSelectionChanged={onCheckboxClick}
      />
      {
        selectedNotifications.length > 0
          ? (
            <Button
              variant="contained"
              sx={{width: 200, mt: 10, justifySelf: 'flex-end', alignSelf: 'flex-end'}}
              onClick={() => dismissNotifications(selectedNotifications.map((notification) => notification.id))}
              disabled={dismissLoading}>
              Dismiss Selected
            </Button>
          )
          : (
            <Button
              variant="contained"
              sx={{width: 200, mt: 10, justifySelf: 'flex-end', alignSelf: 'flex-end'}}
              onClick={onDismissAll}
              disabled={dismissLoading}
            >
            Dismiss All
            </Button>
          )
      }

    </Box>
  )
}

export default Notifications;