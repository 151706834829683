import { gql } from "@apollo/client";

export const REFERRALS_BY_PORTFOLIO_QUERY = gql`
  query Query($portfolioID: ID!) {
    portfolioReferrals(portfolioID: $portfolioID) {
      id
      assetID
      deleted
      referredProcesses {
        id
        processType
      }
      state
      loanNumber
      portfolioID
      referralStatus
      seenByCreator
      propertyID
      referredBy {
        id
        firstName
        lastName
      }
      team {
        id
        name
        invitations {
          id
          email
          status
        }
      }
    }
  }
`;
