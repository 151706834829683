import { gql } from '@apollo/client';

export const GET_IMPORT_CONFLICTS = gql`
  mutation getImportConflicts($input: ImportItemsInput) {
    conflicts(input: $input) {
    state
    budgetDaysToSale
    daysInDlq
    id
    dbState
    loanNumber
    keep
    upb
    borrowerFirstName
    borrowerLastName
    assetAddress
    city
    county
    postal
    nodDate
    lienPosition
    interestRate
    servicer
    ltv
    hampObligation
    borough
    asOf
    currentValue
    paidThroughDate
    nextPaymentDueDate
    saleScheduledDate
    propertyID
    }
  }
`;