import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/createTaskFlowTemplateMutation";
import { UPDATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/updateTaskFlowTemplateMutation";
import { GET_STAFF_TEMPLATES } from "../../apollo/queries/staffTemplates";
import Modal from "../../styled/Modal/Modal";
import Radio from "../../styled/Radio/Radio";
import Button from "../../styled/Button/Button";
import { Box, TextField, Autocomplete } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { MessageContext } from "../../context/MessageContext";

const StaffOptionsModal = ({ show, onClose, selected, allPortfolios }) => {
  const [isForEveryone, setIsForEveryone] = useState(true);
  const [portfolios, setPortfolios] = useState([]);

  const { user } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext); 

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setPortfolios(newValue);
  }

  const [createTaskFlow, { loading: loadingCreateTemplate }] = useMutation(CREATE_TASK_FLOW_TEMPLATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_STAFF_TEMPLATES },
    ],
  });
  const [updateTaskFlow, { loading: loadingUpdateTemplate }] = useMutation(UPDATE_TASK_FLOW_TEMPLATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_STAFF_TEMPLATES },
    ],
  });

  const onCreate = async () => {
    if (!user.staff)
      return;
    if (isForEveryone) {
      // just update the field
      try {
        const everyoneQuery = selected.map(async (template) => {
          return await updateTaskFlow({
            variables: {
              input: {
                where: { id: template.id },
                data: { showToEveryone: true }
              }
            }
          })
        });
        await Promise.all(everyoneQuery);
        addMessage({ message:`Successfully updated ${selected.length} templates.` })
      } catch(err) {
        console.log(err)
      }
      onClose();
    } else {
      // creates for given portfolios
      try {
        const portfolioIds = portfolios.map((p) => p.id);

        const specificQuery = selected.map(async (template) => {
          portfolioIds.map(async (portfolioId) => (
            await createTaskFlow({
              variables: { input: {
                name: template.name,
                description: template.description,
                subtasks: template.subtasks,
                createOnMilestone: template.createOnMilestone,
                createOnMilestoneID: template.createOnMilestoneID,
                states: template.states,
                portfolio: portfolioId,
                processType: template.processType,
              }},
            })
          ))
        });

        await Promise.all(specificQuery);
        addMessage({ message:`Successfully created ${selected.length} templates.` })
      } catch(err) {
        console.log(err)
      }
      onClose();
    }
  }

  if (!selected || !selected.length)
    return null;

  return (
    <Modal open={show} onClose={onClose} height='90vh' width='75vw'>
      <Radio label="Add for everyone" checked={isForEveryone} onClick={() => setIsForEveryone(true)} />
      <Radio label="Add for specific portfolios" checked={!isForEveryone} onClick={() => setIsForEveryone(false)} />
      {!isForEveryone && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Autocomplete
            multiple
            disablePortal
            size="small"
            options={allPortfolios}
            getOptionLabel={(option) => `${option.name} (${option?.portfolioOwner?.firstName} ${option?.portfolioOwner?.lastName})`}
            onChange={handleChange}
            id="STAFF-select-portfolio"
            value={portfolios}
            renderInput={(params) => <TextField {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
              }}
              label="Select Portfolios"
            />}
          />
        </Box>
      )}
      <Button
        disabled={loadingCreateTemplate || loadingUpdateTemplate}
        loading={loadingCreateTemplate || loadingUpdateTemplate}
        onClick={onCreate}>
          Create
      </Button>
    </Modal>
  )
}

export default StaffOptionsModal;