import React from "react";
import MentionHighlighter from "../Mentions/MentionHighlighter";

export default function LatestComment({ value }) {
  if (!value) return '';

  return (
    <MentionHighlighter>
      {value}
    </MentionHighlighter>
  )
}
