import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { rgba } from "emotion-rgba";
import "../../styles/aggrid.scss";

const StyledGrid = styled('div')`
  height: ${(props) => props.height ? props.height : '100%'};
  width: ${(props) => props.width ? props.width: '100%'};

  .ag-root-wrapper {
    border-top: 1px solid ${(props) => rgba(props.theme.themeColor.bodyMain, 0.1)};
  }

  .ag-root-wrapper-body,
  .ag-root-wrapper {
    background: transparent !important;
  }

  .ag-header {
    background-color: ${(props) => rgba(props.theme.themeColor.sectionFill, 0.6)};
    border-bottom: 1px solid ${(props) => rgba(props.theme.themeColor.bodyMain, 0.1)};
    font-weight: 500;
    line-height: 20px;
  }

  .ag-header-cell:hover {
    background-color: ${(props) => props.theme.themeColor.sectionFill} !important;
  }
  
  .ag-cell-focus,.ag-cell-no-focus{
    border: none !important;
  }
  
  .ag-row {
    background: ${(props) => props.theme.themeColor.backgroundBody};
    z-index: 0;
    overflow: visible;
    border-bottom: 1px solid ${(props) => rgba(props.theme.themeColor.bodyMain, 0.1)};
  }
  
  .ag-row.ag-row-focus {
    z-index: 1;
  }

  .ag-row:hover {
    cursor: pointer;
  }

  .ag-row-even {
    background: $med-grey;
  }

  .ag-row-hover:not(.ag-full-width-row)::before {
    background: ${(props) => props.theme.themeColor.sectionFill}; 
  }

  .ag-row-selected::before {
    background: ${(props) => props.theme.themeColor.sectionStroke}; 
  }

  .ag-cell {
    color: ${(props) => props.theme.themeColor.bodySecondary};
    z-index: 0;
    display: flex;
    align-items: center;
  }

  .ag-header-cell-label {
    color: ${(props) => props.theme.themeColor.bodySecondary};
    font-weight: 500;
  }

  .ag-show-dropdown-modal {
    overflow: visible;
    z-index: 1;
  }

  .ag-paging-panel {
    border: none;
    background: ${(props) => props.theme.themeColor.backgroundBody};
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }

  .avatar {
    background: ${(props) => props.theme.themeColor.backgroundBody};
    top: 5px;
    max-height: 35px;
    max-width: 35px;
    border-radius: 50%;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-icon-columns {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-icon-filter {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .fa-solid {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-icon-tree-open {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-icon-tree-closed {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-filter-toolpanel-header {
    background: ${(props) => props.theme.themeColor.sectionFill};
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-tool-panel-wrapper {
    background: ${(props) => props.theme.themeColor.backgroundBody};
    border: 1px solid ${(props) => rgba(props.theme.themeColor.bodyMain, 0.1)};
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-side-button-label {
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .ag-root-wrapper-body .ag-checkbox-input-wrapper::after {
    color: ${(props) => props.theme.themeColor.bodyTetriary};
  }
  .ag-input-field-input {
    color: ${(props) => props.theme.themeColor.bodyMain} !important;
  }
  .ag-text-field-input::placeholder {
    color: ${(props) => props.theme.themeColor.bodySecondary} !important;
  }
  .ag-picker-field-wrapper {
    color: ${(props) => props.theme.themeColor.bodyMain} !important;
    background: ${(props) => props.theme.themeColor.backgroundBody} !important;
  }
  .ag-picker-field-display {
    color: ${(props) => props.theme.themeColor.bodyMain} !important;
    background: ${(props) => props.theme.themeColor.backgroundBody} !important;
  }

  .ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-checked::after,
  .ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: ${(props) => props.theme.themeColor.brandPrimaryBlue};
  }

  .lock-pinned {
    background: ${(props) => props.theme.themeColor.sectionFill};
  }
  .lock-pinned-header {
    background: ${(props) => props.theme.themeColor.sectionStroke};
  }
  .header-error {
    background-color: ${(props) => rgba(props.theme.themeColor.brandPrimaryRed, 0.1)};
  }

  .read-notification {
    background: ${(props) => rgba(props.theme.themeColor.sectionStroke, 0.4)};
    color: ${(props) => props.theme.themeColor.bodySecondary};
  }
  .unread-notification {
    background: ${(props) => props.theme.themeColor.backgroundBody};
    color: ${(props) => props.theme.themeColor.bodyMain};
  }
`

const Grid = ({ children, ...props }) => {
  const theme = useTheme();

  return <StyledGrid {...props} className="ag-theme-material tableStyles" theme={theme}>{children}</StyledGrid>
}

export default Grid;