import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Grid from '../../styled/Grid/StyledGrid';
import { Skeleton } from '@mui/material';
import { cloneDeep } from 'lodash';
import GridOptions from '../Grid/GridOptions';

const CellSkeleton = ({width}) => (<Skeleton variant="text" width={width}/>)

const TableSkeleton = (props) => {
  const numRows = 15;
  const fakeData = {};
  const fakeClDefs = cloneDeep(props.columnDefs);
  
  fakeClDefs.forEach(cl => {
    if(!cl.hide) {
      cl.cellRenderer = CellSkeleton;
      cl.cellRendererParams = {
        width: cl.width,
      }
      fakeData[cl.field] = '';
    }
    cl.rowGroup = false;
  });

  fakeClDefs[fakeClDefs.length - 1].headerComponent = 'GridOptions';

  return (
    <Grid {...props}>
      <AgGridReact
        columnDefs={fakeClDefs}
        rowData={Array(numRows).fill(fakeData)}
        components={[CellSkeleton, GridOptions]}
        onGridReady={(params) => params.api.sizeColumnsToFit()}
      />
    </Grid>
  );
}

export default TableSkeleton;