import React, { createContext, useState } from "react";

export const AssetContext = createContext(null);

export default function AssetProvider({ children }) {
  const [asset, setAsset] = useState(null);
  const [portfolioUsers, setPortfolioUsers] = useState([]);

  return (
    <AssetContext.Provider value={{ asset, setAsset, portfolioUsers, setPortfolioUsers }}>
      {children}
    </AssetContext.Provider>
  )
}