import { gql } from '@apollo/client';

export const GET_MISSING_ASSETS = gql`
  mutation missingAssets($input: ImportItemsInput) {
    missingAssets(input: $input) {
      loanNumber
      propertyID
      dbState
    }
  }
`