import React, { useContext, useState } from "react";
import { TextField, Box, Autocomplete } from "@mui/material";
import Button from "../../styled/Button/Button";
import Modal from "../../styled/Modal/Modal";
import Radio from "../../styled/Radio/Radio";
import { CREATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/createTaskFlowTemplateMutation";
import { UPDATE_TASK_FLOW_TEMPLATE_MUTATION } from "../../apollo/mutations/updateTaskFlowTemplateMutation";
import { useMutation } from "@apollo/client";
import { GET_STAFF_TEMPLATES } from "../../apollo/queries/staffTemplates";
import { UserContext } from "../../context/UserContext";
import { MessageContext } from "../../context/MessageContext";

const TaskFlowTemplateAction = ({ data, ...rest }) => {
  const allPortfolios = rest.allPortfolios;
  const [showModal, setShowModal] = useState(false);
  const [isForEveryone, setIsForEveryone] = useState(true);
  const [portfolios, setPortfolios] = useState([]);

  const { user } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setPortfolios(newValue);
  }

  const [createTaskFlow, { loading: loadingCreateTemplate }] = useMutation(CREATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_STAFF_TEMPLATES },
    ],
  });
  const [updateTaskFlow, { loading: loadingUpdateTemplate }] = useMutation(UPDATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_STAFF_TEMPLATES },
    ],
  });

  const onCreate = async () => {
    if (!user.staff) {
      return;
    }
    
    if (isForEveryone) {
      // just update the field
      await updateTaskFlow({
        variables: { input: {
          where: { id: data.id },
          data: { 
            showToEveryone: true,
          } 
        }},
      });

      setShowModal(false);
    } else {
      // creates for given portfolios
      try {
        portfolios.forEach(async (portfolio) => (
          await createTaskFlow({
            variables: {
              input: {
                name: data.name,
                description: data.description,
                subtasks: data.subtasks,
                createOnMilestone: data.createOnMilestone,
                createOnMilestoneID: data.createOnMilestoneId,
                states: data.states,
                portfolio: portfolio.id,
                processType: data.processType,
              }
            }
          })
        ));
        addMessage({ message:`Successfully created ${portfolios.length} templates.` });
      } catch(err) {
        addMessage({ message: "Failed to create templates." });
        console.log(err);
      }
      setShowModal(false);
    }
  }

  return (
    <>
      {user.staff && (
        <>
          <Button onClick={() => setShowModal(true)}>Use</Button>
          <Modal open={showModal} onClose={() => setShowModal(false)} height='90vh' width='75vw'>
            <Radio label="Add for everyone" checked={isForEveryone} onClick={() => setIsForEveryone(true)} />
            <Radio label="Add for specific portfolios" checked={!isForEveryone} onClick={() => setIsForEveryone(false)} />

            {!isForEveryone && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Autocomplete
                  multiple
                  disablePortal
                  size="small"
                  options={allPortfolios}
                  getOptionLabel={(option) => `${option.name} (${option?.portfolioOwner?.firstName} ${option?.portfolioOwner?.lastName})`}
                  onChange={handleChange}
                  id="STAFF-select-portfolio"
                  value={portfolios}
                  renderInput={(params) => <TextField {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    label="Select Portfolios"
                  />}
                />
              </Box>
            )}
            <Button
              disabled={loadingCreateTemplate || loadingUpdateTemplate}
              loading={loadingCreateTemplate || loadingUpdateTemplate}
              onClick={onCreate}>
            Create
            </Button>
          </Modal>
        </>
      )}
    </>

  )
}

export default TaskFlowTemplateAction;