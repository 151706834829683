import { gql } from '@apollo/client';

export const GET_MILESTONE_CLOSED_TOKEN = gql`
  query GetMilestoneClosedTokens($assetID: ID!) {
    getMilestoneClosedTokens(assetID: $assetID) {
      id
      token
      expiresAt
    }
  }
`;