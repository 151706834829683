export const formatPortfolioUsers = (portfolioTeams, portfolioUsers, asset, user) => {  
  const teams = portfolioTeams.filter((team) => {
    if (team.type === "EXTERNAL") {
      const teamAssetIDs = team.assets.map((asset) => asset.id);
      if (teamAssetIDs.includes(asset.id)) return team;
    } else if (team.type === "INTERNAL") {
      const teamPortfolioID = team.portfolio.id;
      if (asset.portfolio.id === teamPortfolioID) return team;
    } else if (team.type === "LAWFIRM") {
      // Show law firm that was sent the referral
      if (asset?.activeProcess?.referral?.teamID?.id === team.id) return team;
      // Show law firm that has accepted the referral
      const teamAssetIDs = team.assets.map((asset) => asset.id);
      if (teamAssetIDs.includes(asset.id)) return team;
    }
    else return team;
  });

  const teamUsers = teams.map((team) => team.memberships.map((member) => member.user)).flat();
  
  // Options should always start with the user itself
  let options = [{...user, __typename: "User"}, ...portfolioUsers, ...teamUsers, ...teams];

  // Remove duplicates from options
  options = options.filter((option, index, self) =>
    index === self.findIndex((t) => (
      t.id === option.id && t.__typename === option.__typename
    ))
  );

  // Sort options by __typename
  options = options.sort((a, b) => {
    if (a.__typename === "User") return -1;
    if (b.__typename === "Team") return 1;
    return 0;
  });

  // Sort options by name
  const allOptions = options.sort((a, b) => {
    if (a.__typename === "User") {
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
    }
    if (b.__typename === "Team") {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
    return 0;
  });

  return allOptions;
}