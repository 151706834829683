import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import AssetReferral from "../AssetReferral/AssetReferral";
import DocumentTable from "../DocumentTable/DocumentTable";
import Foreclosure from "./Foreclosure/Foreclosure";
import Bankruptcy from "./Bankruptcy/Bankruptcy";
import Activities from "./Activities/Activities";
import { isForeclosure, isBankruptcy } from "../../utilities";

export const filters = [
  { name: "Comment", value: "comment", checked: true },
  { name: "Task Comment", value: "task-comment", checked: true },
  { name: "Hold Comment", value: "hold-comment", checked: true },
  { name: "Changed Process Type", value: "process-changed", checked: true },
  { name: "Closed Process", value: "process-closed", checked: true },
  { name: "Document Uploaded", value: "document-upload", checked: true },
  { name: "Foreclosure Closed", value: "foreclosure-closed", checked: true },
  { name: "Hold Created", value: "hold-created", checked: true },
  { name: "Hold Reopened", value: "hold-reopened", checked: true },
  { name: "Hold Closed", value: "hold-closed", checked: true },
  { name: "Milestone Opened", value: "milestone-opened", checked: true },
  { name: "Milestone Closed", value: "milestone-closed", checked: true },
  { name: "Task Created", value: "task-created", checked: true },
  { name: "Task Reopened", value: "task-reopened", checked: true },
  { name: "Task Complete", value: "task-complete", checked: true },
  { name: "Task Reassigned", value: "task-reassigned", checked: true },
  { name: "Task Due Date Changed", value: "task-duedate-update", checked: true },
  { name: "Task Flow Template Created", value: "flow-template-created", checked: true },
  { name: "Task Form Updated", value: "form-update", checked: true },
];

export default function Processes({
  screen,
  asset,
  hasBK,
  currentMilestone,
  setCurrentMilestone,
  originalFC,
  sideBarStats,
  nextStepStats,
  fcMilestones,
  bkMilestones,
  client,
  hasSentReferral,
  setIsFullScreen,
  setScreen,
  activities,
  activeProcess,
  setActiveProcess,
  setAsset
}) {
  const { user, portfolioID } = useContext(UserContext);
  const hasDeletedProcess = asset.processes.some((process) => process?.deleted && process?.stepID);

  const fcReadOnly = activeProcess
    ? isBankruptcy(activeProcess)
    : false;

  const bkReadOnly = activeProcess
    ? isForeclosure(activeProcess) && hasBK
    : null;

  // select which tab to display documents
  const displayDocuments = 
    (screen === activities.length+2 && !hasSentReferral) ||
    (screen === activities.length+2 && (!hasBK && !originalFC) && hasSentReferral) ||
    (screen === activities.length+2 && (!originalFC && hasBK) && hasSentReferral) ||
    (screen === activities.length+3 && (originalFC && hasSentReferral)) ||
    (screen === activities.length+3 && (originalFC && hasBK) && hasSentReferral)

  return (
    <>
      {/* Activities tab(s) */}
      { screen <= activities.length &&
        activities.map((activity, i) => {
          return <Activities activity={activity} key={activity.name-i} asset={asset} filters={filters} activeProcess={activeProcess} />
        })
      }

      {/* Asset Referral */}
      {(screen === activities.length+1 && !hasSentReferral) &&
          <AssetReferral
            user={user}
            portfolioID={portfolioID}
            loanNumber={asset.loanNumber}
            state={asset.state}
            hasDeletedProcess={hasDeletedProcess}
            client={client}
            setIsFullScreen={setIsFullScreen}
          />
      }
      {/* Regular Foreclosure */}
      {(screen === activities.length+1 && (!hasBK && !originalFC) && hasSentReferral) &&
        <Foreclosure
          asset={asset}
          client={client}
          originalFC={originalFC}
          sideBarStats={sideBarStats}
          nextStepStats={nextStepStats}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          fcMilestones={fcMilestones}
          bkMilestones={bkMilestones}
          setScreen={setScreen}
          filters={filters}
          activeProcess={activeProcess}
          setAsset={setAsset}
          setActiveProcess={setActiveProcess}
        />
      }
      {/* Inactive Foreclosure with an open BK Hold */}
      {(screen === activities.length+1 && originalFC && hasSentReferral) &&
        <Foreclosure
          asset={asset}
          client={client}
          originalFC={originalFC}
          sideBarStats={sideBarStats}
          nextStepStats={nextStepStats}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          fcMilestones={fcMilestones}
          bkMilestones={bkMilestones}
          readOnly={fcReadOnly}
          filters={filters}
          activeProcess={activeProcess}
          setAsset={setAsset}
          setActiveProcess={setActiveProcess}
        />
      }

      {/* Regular BK */}
      {screen === activities.length+1 && (!originalFC && hasBK) && hasSentReferral &&
        <Bankruptcy 
          asset={asset}
          client={client}
          originalFC={originalFC}
          sideBarStats={sideBarStats}
          nextStepStats={nextStepStats}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          fcMilestones={fcMilestones}
          bkMilestones={bkMilestones}
          setScreen={setScreen}
          filters={filters}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
        />
      }

      {/* BK if opened by FC */}
      {screen === activities.length+2 && (originalFC && hasBK) && hasSentReferral &&
        <Bankruptcy
          asset={asset}
          client={client}
          originalFC={originalFC}
          sideBarStats={sideBarStats}
          nextStepStats={nextStepStats}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          fcMilestones={fcMilestones}
          bkMilestones={bkMilestones}
          readOnly={bkReadOnly}
          setScreen={setScreen}
          filters={filters}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
        />
      }

      {/* Documents Tab */}
      {displayDocuments &&
        <DocumentTable
          asset={asset}
          processID={asset?.activeProcess?.id}
          milestoneID={hasBK ? currentMilestone?.bk?.stepID : currentMilestone?.fc?.stepID}
          activity={activities[0]}
          originalFC={originalFC}
        />
      }
    </>
  )
}