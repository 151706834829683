import { Box } from "@mui/material";
import React, { useState } from "react";
import Button from "../../styled/Button/Button";
import EditTaskFlow from "./EditTaskFlow";
import DeleteTaskFlow from "./DeleteTaskFlow";

const TemplateOptions = ({ data }) => {
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);

  if (!data || !data?.showButtons) return null;
  return (
    <>
      <Box sx={{ width: '100px' }}>
        <Button sx={{ mr: 1 }} variant="secondary" onClick={() => setShowEditDrawer(true)}>Edit</Button>
        { !data?.global &&
          <Button variant="secondary" onClick={() => setShowDeleteDrawer(true)}>Delete</Button>
        }
      </Box>
      <EditTaskFlow taskFlow={data} show={showEditDrawer} setShow={() => setShowEditDrawer(false)} />
      <DeleteTaskFlow taskFlow={data} show={showDeleteDrawer} setShow={() => setShowDeleteDrawer(false)} />
    </>
  )
}

export default TemplateOptions;