import React, { useState, useContext } from "react";
import { CREATE_EVENT_MUTATION, CREATE_TASK_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { GET_ACTIVITY_EVENTS } from "../../apollo/queries/getActivityEvents";
import { useMutation } from "@apollo/client";
import { Box, FormControl, TextareaAutosize, Container, Autocomplete, InputAdornment, Select, MenuItem } from "@mui/material";
import Modal from "../../styled/Modal/Modal";
import { TextInput } from "../../styled/TextInput/TextInput";
import Checkbox from "../../styled/Checkbox/Checkbox";
import Button from "../../styled/Button/Button";
import SearchIcon from '@mui/icons-material/Search';
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import DatePickerSelect from "../DatePickerSelect/DatePickerSelect";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { ACTIVITY_TASKS_QUERY } from "../../apollo/queries/activityTasksQuery";
import { AssetContext } from "../../context/AssetContext";

const INITIAL_ERROR_STATE = {
  title: false,
  description: false,
  assigned: false,
  milestone: false,
  blocker: false,
};

const AddTask = ({ show, onClose, substeps, asset, milestoneID, processID, activity }) => {
  const theme = useTheme();

  const { addMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);
  const { portfolioUsers } = useContext(AssetContext);
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [milestone, setMilestone] = useState(() => setActiveSubstep());
  const [dueDate, setDueDate] = useState(null);
  const [blocker, setBlocker] = useState("non-blocking");
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isActivity] = useState((activity?.id && !process?.id) ? true : false)

  const [createTask, response] = useMutation(CREATE_TASK_MUTATION);

  const eventRefetch = isActivity
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: activity.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: activity.id } },
      { query: ASSET_QUERY, variables: { assetID: asset.id, userID: user.userID } }
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID: asset.id, userID: user.userID } }
    ];

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: eventRefetch,
  });

  function setActiveSubstep() {
    return currentAvailableSubsteps(substeps).find(
      (item) => item.status === "active"
    );
  }

  function currentAvailableSubsteps(substeps) {
    if (!substeps || !substeps.length) return [];
    return substeps.filter((substep) => substep.status !== "completed");
  }

  function formValidation() {
    let hasError = false;

    if (title.length === 0) {
      setError((prevState) => ({ ...prevState, title: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, title: false }));
    }

    if (description.length === 0) {
      setError((prevState) => ({ ...prevState, description: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, description: false }));
    }

    if (assignments.length === 0) {
      setError((prevState) => ({ ...prevState, assigned: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, assigned: false }));
    }

    if (milestone === null) {
      setError((prevState) => ({ ...prevState, milestone: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, milestone: false }));
    }

    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!formValidation()) {
      const task = {
        title,
        description,
        assignments,
        dueByMilestone: milestone?.stepID,
        milestone: milestoneID,
        blocking: blocker === "blocking" ? true : false,
        deadlineAt: dueDate,
        createdAt: new Date()
      };
      isActivity
        ? task.activity = activity.id
        : task.process = processID;

      try {
        const taskResponse = await createTask({
          variables: {
            task,
          }
        });

        if (taskResponse) {
          const event = {
            type: "task-created",
            description,
            dueBy: dueDate,
            createdDate: new Date(),
            createdFor: assignments[0],
            dueByMilestone: milestone?.stepID,
            milestoneID: milestoneID,
            process: processID,
            task: taskResponse.data.id,
            title: title,
          }
          isActivity ? event.activity = activity.id : event.process = processID;

          await createEvent({
            variables: {
              event: event
            }
          });
        }
      } catch (error) {
        console.log(error);
        addMessage({ message: "Unable to create task. Please try again or come back later.", type: "error" })
      }

      setError(INITIAL_ERROR_STATE);
      setErrorMessage(null);
      setTitle("");
      setDescription("");
      setAssignments([]);
      setMilestone(setActiveSubstep());
      setDueDate(null);
      onClose();
    } else {
      setErrorMessage("Please complete all necessary fields.");
    }
  }

  const onSelectAssigned = (_event, newAssignments) => {
    const assignees = newAssignments.map((assignee) => {
      if (assignee.__typename === "User")
        return assignee.id
      else if (assignee.__typename === "Team") {
        const members = assignee.memberships.map((member) => member.user.id);
        return members;
      }
    })

    setAssignments([...new Set(assignees.flat())])
  }
  const availableSteps = currentAvailableSubsteps(substeps);
  
  if (response.error) <p>{response.error}</p>;

  const getOptionLabel = (option) => (
    option.name ? option.name : `${option.firstName} ${option.lastName}`
  )

  return (
    <Modal open={show} onClose={onClose}>
      <Container sx={{
        marginBottom: '30px',
        background: theme.themeColor.backgroundBody
      }}>
        <Typography component="h1" variant="h4">New Task</Typography>
      </Container>

      <Container sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '60px'}}>
        <Box sx={{display: 'flex', flexDirection: "column", width: '48%'}}>
          <FormControl>
            <label>Task Name</label>
            <TextInput placeholder="Enter a Task Name" 
              name="title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              theme={theme}
            />
          </FormControl>

          <FormControl>
            <label>Assign To:</label>
            <Autocomplete
              multiple
              options={portfolioUsers}
              getOptionLabel={getOptionLabel}
              onChange={onSelectAssigned}
              groupBy={(option) => `${option.__typename}s`}
              renderGroup={(params) => (
                <li style={{ margin: '10px' }}key={params.key}>
                  <Typography sx={{fontWeight: 'bold'}}>{params.group}</Typography>
                  <Typography>{params.children}</Typography>
                </li>
              )}
              renderInput={(params) => <TextInput {...params}
                data-testid="testid"
                theme={theme}
                InputProps={{
                  ...params.InputProps,
                  "data-testid": "assign-task-to",
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: theme.themeColor.oppositeBodyMain }}/>
                    </InputAdornment>
                  )
                }}
              />}
            />
          </FormControl>
        </Box>

        <Box sx={{width: '48%'}}>
          <FormControl fullWidth>
            <label htmlFor="task-description">Description</label>
            <TextareaAutosize
              data-testid='task-description'
              id='task-description'
              minRows={5.6}
              name="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              style={{
                background: theme.themeColor.backgroundBody,
                color: theme.themeColor.bodyMain,
                resize: 'none',
                fontFamily: 'inherit',
                fontSize: '1.25em',
                borderRadius: theme.themeSizing.borderRadiusSmall
              }}
            />
          </FormControl>
        </Box>
      </Container>

      <Container sx={{marginBottom: '60px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>      
          {!isActivity && (
            <FormControl sx={{width: '48%'}}>
              <label>Due By Milestone</label>
              <Select
                value={milestone && milestone.stepID}
                label=""
                id="due-by-milestone"
                onChange={(_event, step) => {
                  const { props } = step;
                  setMilestone({
                    milestone: props,
                    stepID: props.stepID
                  })
                }}
              >
                {
                  availableSteps && availableSteps.map((item) => {
                    return <MenuItem 
                      {...item}
                      key={item.stepID}
                      value={item.stepID}
                    >
                      {`${item.stepID} - ${item.label}`}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          )}

          <FormControl sx={{width: "48%", background: theme.themeColor.backgroundBody}}>
            <label>Due By Date</label>
            <DatePickerSelect
              id='task-dueby-date'
              data-testid="task-datepicker"
              placeholder="Choose a date"
              selected={dueDate}
              setSelected={setDueDate}
              error={error.dueDate}
              minDate={new Date()}
            />
          </FormControl>
        </Box>
      </Container>
      
      {!isActivity && (
        <Container sx={{marginBottom: '60px'}}>
          <Checkbox 
            value="blocking"
            onChange={() => blocker === 'blocking' ? setBlocker('non-blocking') : setBlocker('blocking')}
            checked={blocker === "blocking"}
            label="This task is blocking"
          />
        </Container>
      )}

      <Container>
        <div>{errorMessage && <ErrorMessage error={errorMessage} />}</div>
        <Button id="submit-task" role="submit" onClick={handleSubmit} sx={{width: '150px', marginRight: '15px'}}>Add</Button>
        <Button onClick={onClose} variant="secondary" sx={{width: 'auto'}}>Cancel</Button>
      </Container>
    </Modal>
  )
}

export default AddTask;