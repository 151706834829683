import React, { createContext, useState } from "react";

export const MilestoneTokenContext = createContext(null);

export default function MilestoneTokenProvider({ children }) {
  const [milestoneToken, setMilestoneToken] = useState(null);
  const [milestoneExpAt, setMilestoneExpAt] = useState(null);

  return (
    <MilestoneTokenContext.Provider value={{ milestoneToken, setMilestoneToken, milestoneExpAt, setMilestoneExpAt }}>
      {children}
    </MilestoneTokenContext.Provider>
  )
}