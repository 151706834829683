import { isForeclosure, isBankruptcy } from "../utilities/index";

export const checkAssetNextMilestoneAction = async (
  asset,
  process,
  portfolioID,
  nextMilestone,
  createTask,
  createForm,
  createEvent,
  dataPortfolioManager,
  getFlowTemplates,
  taskFlowTemplates,
  createFlow,
  createFlowTemplate,
) => {
  const portfolioManagers = dataPortfolioManager.getPortfolioManager.map((user) => user.id);

  // Handle automatic creation of task flows:
  await createTemplates(
    process.id,
    getFlowTemplates,
    createFlow,
    portfolioManagers,
    nextMilestone
  );

  // Initial FC Checklist
  if (isForeclosure(process) && nextMilestone.stepID === "0.10") {
    await createInitialFcChecklist(
      process,
      createFlow,
      createEvent,
      dataPortfolioManager,
      nextMilestone,
      taskFlowTemplates
    );
  }

  // Handle automatic creation of forms:
  if (isForeclosure(process) && nextMilestone.stepID === "4.00") {
    await createTaskForSaleSchedule(
      portfolioManagers,
      nextMilestone,
      process.id,
      createTask,
      createForm,
      createEvent
    );
  }

  if (isForeclosure(process) && nextMilestone.stepID === "5.00") {
    await createTaskForFCSaleHeld(
      process.id,
      nextMilestone,
      createTask,
      createForm,
      createEvent,
      portfolioManagers
    );
  }
  const hasFlow = (templates, name) => {
    if (!templates) return false;
    return templates.some((template) => {
      return template.name === name && !template.deleted
        ? true : false;
    })
  }

  if (isBankruptcy(process) && nextMilestone.stepID === "5.10") {
    const resp = await taskFlowTemplates();
    const flowTemplates = resp.data.findTaskFlowTemplate;

    if (!hasFlow(flowTemplates, "Borrower Breaks Payment Plan")) {
      await createTemplateForBKBreakPlan(
        asset,
        process.id,
        portfolioID,
        nextMilestone,
        createEvent,
        createFlowTemplate,
      )
    }
  }
};

const createTemplates = async (
  processID,
  getFlowTemplates,
  createFlow,
  portfolioManagers,
  nextMilestone
) => {
  // Create templates
  const resp = await getFlowTemplates();
  const flowTemplates = resp.data.findStepTaskFlowTemplate;

  if (flowTemplates?.length) {
    const promisses = flowTemplates.map(async (flowTemplate) => {
      await createFlow({
        variables: {
          input: {
            name: flowTemplate.name,
            flowID: flowTemplate.id,
            assignments: portfolioManagers,
            milestone: nextMilestone.stepID,
            process: processID,
            blocking: false,
          },
        },
      });
    });

    await Promise.all(promisses);
  }
};
  
const createTaskForFCSaleHeld = async (
  processID,
  nextMilestone,
  createTask,
  createForm,
  createEvent,
  portfolioManagers
) => {
  await createFCBIDForm(
    portfolioManagers,
    nextMilestone,
    processID,
    createTask,
    createForm,
    createEvent
  );

  await createTaskForSaleResult(
    portfolioManagers,
    nextMilestone,
    processID,
    createTask,
    createForm,
    createEvent
  );
};

const createFCBIDForm = async (
  portfolioManagers,
  nextMilestone,
  processID,
  createTask,
  createForm,
  createEvent
) => {
  // Create FC Bid form
  const fcBidForm = await createForm({
    variables: {
      input: {
        title: "FC Bid",
        formFields: [
          {
            label: "Bid amount",
            type: "number",
            order: 1,
            required: true,
          },
          {
            label: "Total debt amount",
            type: "number",
            order: 2,
            required: true,
          },
        ],
      },
    },
  });

  if (fcBidForm && !("errors" in fcBidForm)) {
    // Create task
    const createdAt = new Date();
    const deadline = new Date(createdAt.getTime() + 7 * 24 * 60 * 60 * 1000);
    const title = "Submit FC Bid";

    const task = {
      title,
      description: "Task created automatically.",
      assignments: portfolioManagers,
      dueByMilestone: nextMilestone.stepID,
      milestone: nextMilestone.stepID,
      process: processID,
      blocking: true,
      deadlineAt: deadline,
      createdAt: createdAt,
      forms: [fcBidForm.data.createFormWithFields.id],
    };

    const taskResponse = await createTask({
      variables: {
        task,
      },
    });

    if (taskResponse) {
      if (taskResponse) {
        const event = {
          type: "task-created",
          description: "Task created automatically",
          dueBy: deadline,
          createdDate: new Date(),
          dueByMilestone: nextMilestone.stepID,
          milestoneID: nextMilestone.stepID,
          process: processID,
          task: taskResponse.data.createTask.id,
          title: title
        };

        await createEvent({
          variables: {
            event,
          },
        });
      }
    }
  }
};

const createTaskForSaleResult = async (
  portfolioManagers,
  nextMilestone,
  processID,
  createTask,
  createForm,
  createEvent
) => {
  const saleResultForm = await createForm({
    variables: {
      input: {
        title: "Sale Results",
        formFields: [
          {
            label: "Sale outcome",
            type: "select",
            options: "3rd party,REO",
            order: 1,
            required: true,
          },
          {
            label: "Amount of winning bid in $",
            type: "number",
            order: 2,
            required: true,
          },
          {
            label: "3rd party name",
            type: "text",
            condition: "Sale outcome = 3rd party",
            order: 3,
            required: true,
          },
          {
            label: "3rd party address",
            type: "text",
            condition: "Sale outcome = 3rd party",
            order: 4,
            required: true,
          },
          {
            label: "3rd party phone number",
            type: "text",
            condition: "Sale outcome = 3rd party",
            order: 5,
            required: true,
          },
        ],
      },
    },
  });

  if (saleResultForm && !("errors" in saleResultForm)) {
    // Create task
    const createdAt = new Date();
    const deadline = new Date(createdAt.getTime() + 7 * 24 * 60 * 60 * 1000);
    const title = "Submit Sale Results";

    const task = {
      title,
      description: "Task created automatically.",
      assignments: portfolioManagers,
      dueByMilestone: nextMilestone.stepID,
      milestone: nextMilestone.stepID,
      process: processID,
      blocking: true,
      deadlineAt: deadline,
      createdAt: createdAt,
      forms: [saleResultForm.data.createFormWithFields.id],
    };

    const taskResponse = await createTask({
      variables: {
        task,
      },
    });

    if (taskResponse) {
      if (taskResponse) {
        const event = {
          type: "task-created",
          description: "Task created automatically.",
          dueBy: deadline,
          createdDate: new Date(),
          dueByMilestone: nextMilestone.stepID,
          milestoneID: nextMilestone.stepID,
          process: processID,
          task: taskResponse.data.createTask.id,
          title,
        };

        await createEvent({
          variables: {
            event,
          },
        });
      }
    }
  }
};

const createTaskForSaleSchedule = async (
  portfolioManagers,
  nextMilestone,
  processID,
  createTask,
  createForm,
  createEvent
) => {
  const saleScheduleForm = await createForm({
    variables: {
      input: {
        title: "Sale Schedule",
        formFields: [
          {
            label: "Sale scheduled date",
            type: "date",
            required: true,
            order: 1,
          },
        ],
      },
    },
  });

  if (saleScheduleForm && !("errors" in saleScheduleForm)) {
    // Create task
    const createdAt = new Date();
    const deadline = new Date(createdAt.getTime() + 7 * 24 * 60 * 60 * 1000);
    const title = "Submit Sale Schedule Date";

    const task = {
      title,
      description: "Task created automatically.",
      assignments: portfolioManagers,
      dueByMilestone: nextMilestone.stepID,
      milestone: nextMilestone.stepID,
      process: processID,
      blocking: true,
      deadlineAt: deadline,
      createdAt: createdAt,
      forms: [saleScheduleForm.data.createFormWithFields.id],
    };

    const taskResponse = await createTask({
      variables: {
        task,
      },
    });

    if (taskResponse) {
      if (taskResponse) {
        const event = {
          type: "task-created",
          description: "Task created automatically.",
          dueBy: deadline,
          createdDate: new Date(),
          dueByMilestone: nextMilestone.stepID,
          milestoneID: nextMilestone.stepID,
          process: processID,
          task: taskResponse.data.createTask.id,
          title,
        };

        await createEvent({
          variables: {
            event,
          },
        });
      }
    }
  }
}

const createTemplateForBKBreakPlan = async (
  asset,
  processID,
  portfolioID,
  nextMilestone,
  createEvent,
  createFlowTemplate
) => {
  const breakPlanData = {
    name: 'Borrower Breaks Payment Plan',
    description: 'The borrower has failed to pay their invoice',
    subtasks: [
      {
        name: "Refer MFR",
        order: 1,
        budgetDays: 120
      },
      {
        name: "File MFR",
        order: 2,
        budgetDays: 7
      },
      {
        name: "MFR Hearing",
        order: 3,
        budgetDays: 30
      },
    ],
    createOnMilestone: false,
    createOnMilestoneId: null,
    states: null,
    portfolio: portfolioID
  }
  const apoData = {
    name: 'Agreed Order/APO',
    description: 'Borrower Filed Agreed Order or Adequate Protection Order',
    subtasks: [
      {
        name: "File Agreed Order/APO",
        order: 1,
        budgetDays: 7
      },
      {
        name: "APO Hearing",
        order: 2,
        budgetDays: 21
      },
    ],
    createOnMilestone: false,
    createOnMilestoneId: null,
    states: null,
    portfolio: portfolioID
  }

  const breakPlanTemplate = await createFlowTemplate({
    variables: { input: { data: breakPlanData } }
  });
  const apoFiledTemplate = await createFlowTemplate({
    variables: { input: { data: apoData }}
  })

  const breakPlanDataCopy = {
    ...breakPlanData,
    name: "Borrower Breaks Payment Plan (In REM Relief)"
  }
  const breakPlanInRemTemplate = await createFlowTemplate({
    variables: { input: { data: breakPlanDataCopy }}
  })

  if (
    (breakPlanTemplate && !("errors" in breakPlanTemplate)) &&
    (apoFiledTemplate && !("errors" in apoFiledTemplate)) &&
    (breakPlanInRemTemplate && !("errors" in breakPlanInRemTemplate))
  ) {
    const event = {
      type: "flow-template-created",
      description: `"Borrower Breaks Payment Plan" and "Agreed Order/APO"`,
      dueBy: null,
      createdDate: new Date(),
      dueByMilestone: nextMilestone.stepID,
      milestoneID: nextMilestone.stepID,
      process: processID,
      title: "Created 3 new flow templates"
    };

    await createEvent({
      variables: {
        event,
      }
    });
  }
}

const createInitialFcChecklist = async (
  process,
  createFlow,
  createEvent,
  dataPortfolioManager,
  selectedStep,
  taskFlowTemplates
) => {
  const resp = await taskFlowTemplates();
  const flowTemplates = resp.data?.findTaskFlowTemplate;

  const initialFcTemplate = flowTemplates.find((template) => {
    return template.name === "Initial Foreclosure Checklist"
  });

  const portfolioManagers = dataPortfolioManager.getPortfolioManager.map((user) => user.id);
  const today = new Date();
  const fiveDaysFromToday = new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000);

  const newFlow = await createFlow({
    variables: {
      input: {
        name: initialFcTemplate.name,
        flowID: initialFcTemplate.id,
        assignments: portfolioManagers,
        milestone: selectedStep.stepID,
        process: process.id,
        blocking: false,
        startDate: today,
        dueDate: fiveDaysFromToday,
        subtaskData: initialFcTemplate.subtasks.map((subtask) => {
          return {
            name: subtask.name,
            order: subtask.order,
            budgetDays: subtask.budgetDays,
            assignments: portfolioManagers,
          };
        })
      }
    }
  });

  if (newFlow && !("errors" in newFlow)) {
    const event = {
      type: "task-created",
      description: `Task flow with ${initialFcTemplate.subtasks.length} subtasks`,
      createdDate: new Date(),
      createdFor: portfolioManagers[0].id,
      milestoneID: selectedStep.stepID,
      process: process.id,
      title: "Initial Foreclosure Checklist"
    };

    await createEvent({
      variables: {
        event,
      }
    });
  }
}