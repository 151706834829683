import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($input: createNotificationInput!) {
    createNotification(input: $input) {
      id
      message
      type
      redirectUrl
      read
      createdAt
    } 
  }
`;