import React, { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, TextField, Button, List, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FaCheck } from "react-icons/fa";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { UserContext } from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ASSIGN_REFRESH_TOKEN } from "../../apollo/mutations/assignRefreshToken";
import Drawer from "../../styled/Drawer/Drawer";
import { passwordStrength } from 'check-password-strength';
import { containsNumber, containsLowerCase, containsUpperCase, containsSpecialChars } from "../../utilities";
import { UPDATE_ACOUNT_INFO_MUTATION } from "../../apollo/mutations/updateAccountInfoMutation";
import Rating from "../../styled/Rating/Rating";
import styled from "@emotion/styled";


export const LOGIN_USER = gql`
  mutation loginMutation($input: UsersPermissionsLoginInput!) {
    loginMutation(input: $input) {
      jwt
      user {
        username
        email
        confirmed
        blocked
        id
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query GET_USER_INFO {
    getUserInfo {
      id
      firstName
      lastName
      userType
      tncStatus
      email
      verifiedEmail
      paymentMethodAdded
    }
  }
`;

const INITIAL_FORM_STATE = {
  identifier: "",
  password: "",
  newPassword: "",
};

const INITIAL_ERROR_STATE = {
  identifier: false,
  newPassword: false,
};

const ListIcon = styled(ListItemIcon)`
  min-width: 30px;
`

export default function AcceptTeamInviteForm() {
  const params = useParams();
  const history = useHistory();
  const { setUser } = useContext(UserContext);
  const { setValue: setUserLocal } = useLocalStorage("user");
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  useState(() => {
    if (params) {
      setFormData({
        ...formData,
        identifier: params.inviteEmail,
        password: params.tempPassword,
      });
    }
  });

  const [getUserInfo, { loading: userInfoLoading }] =
    useLazyQuery(GET_USER_INFO);

  const [LoginMutation, { error: loginError, loading: loginLoading }] =
    useMutation(LOGIN_USER);

  const [updateAccount, { error: updateError, loading: updateLoading }] =
    useMutation(UPDATE_ACOUNT_INFO_MUTATION);

  const [assignRefreshToken, { loading: tokenLoading, error: tokenError }] =
    useMutation(ASSIGN_REFRESH_TOKEN);

  function formValidation() {
    let hasError = false;

    if (formData.identifier.length === 0) {
      setFormError((prevState) => ({ ...prevState, identifier: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, identifier: false }));
    }

    if (formData.newPassword.length < 5) {
      setFormError((prevState) => ({ ...prevState, newPassword: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, newPassword: false }));
    }

    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!formValidation()) {
      try {
        const response = await LoginMutation({
          variables: {
            input: {
              identifier: formData.identifier,
              password: formData.password,
            },
          },
        });

        if (response) {
          const { data } = response;
          if (data) {
            setUserLocal({
              token: data.loginMutation.jwt,
              userID: data.loginMutation.user.id,
            });

            await updateAccount({
              variables: {
                input: {
                  id: data.loginMutation.user.id,
                  oldPassword: params.tempPassword,
                  password: formData.newPassword,
                  verifiedEmail: true,
                  confirmed: true,
                  memberStatus: "ACCEPTED"
                }
              },
            });

            const userInfoResponse = await getUserInfo({
              variables: { id: data.loginMutation.user.id },
            });

            const tokenResponse = await assignRefreshToken();
            if (tokenResponse) {
              sessionStorage.setItem('refresh', tokenResponse.data.assignRefreshToken.refresh)
            }

            setUser({
              token: data.loginMutation.jwt,
              userID: data.loginMutation.user.id,
              ...userInfoResponse.data.getUserInfo,
            });
            history.push("/");
          }
        }
      } catch (error) {
        localStorage.removeItem("user")
        setFormError({ loginError: error });
      }
    }
  }

  function handleChange(event) {
    const {name, value} = event.target;

    if (name == "newPassword") {
      if (value == "") setPasswordStrengthValue(0)
      else setPasswordStrengthValue(passwordStrength(value).id + 1)
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <Drawer open={true} width="23rem" height="89vh" hideBackdrop={true}>
      <div className="signup">
        <form onSubmit={handleSubmit}>
          <p className="form-heading">Welcome! Please sign in to accept invite.</p>
          {process.env.NODE_ENV === "development" && (
            <small className="mb-4 d-inline-block">
            You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
            mode.
            </small>
          )}
          <h2>Sign Up</h2>

          <Box
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            spacing={2}
          >
            <TextField
              type="email"
              name="identifier"
              value={formData.identifier}
              onChange={handleChange}
              label="E-mail"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              disabled
            />

            <TextField
              type={showPassword ? "text" : "password"}
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              label="Password"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              error={formError.newPassword}
              helperText={formError.newPassword ? 'The password must be at least 5 characters in length.' : ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />

            <div className="password-strength">
              <span>Password Strength</span>
              <span> <Rating value={passwordStrengthValue} max={4}/> </span>
            </div>
          </Box>

          <div>
            <List dense={true}>
              <ListItem>
                <ListIcon sx={{minWidth: '30px'}}>
                  <FaCheck />
                </ListIcon>
                { formData.newPassword.length > 8 ? 
                  <s><ListItemText
                    primary="Longer than 8 characters"
                  /></s>:
                  <ListItemText
                    primary="Longer than 8 characters"
                  />
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { (containsLowerCase(formData.newPassword) && containsUpperCase(formData.newPassword)) ? 
                  <s><ListItemText
                    primary="Use both lowercase and uppercase letters"
                  /></s> : 
                  <ListItemText
                    primary="Use both lowercase and uppercase letters"
                  /> 
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { containsSpecialChars(formData.newPassword) ? 
                  <s><ListItemText
                    primary="Use special symbols"
                  /></s> : 
                  <ListItemText
                    primary="Use special symbols"
                  /> 
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { containsNumber(formData.newPassword) ?
                  <s><ListItemText
                    primary="Use numbers"
                  /></s>: 
                  <ListItemText
                    primary="Use numbers"
                  />
                }
              </ListItem>
            </List>
          </div>

          <div className="bottom-container">
            {loginError && <ErrorMessage error="The server has encountered an error while creating the user profile. Please try again or come back later." />}
            {updateError && <ErrorMessage error="Could not update account." />}
            {tokenError && <ErrorMessage error="Could not fetch token. Please refresh or logout and try again." />}
            <Button 
              disabled={loginLoading || updateLoading || userInfoLoading || tokenLoading || passwordStrengthValue !== 4}
              loading={loginLoading || updateLoading || userInfoLoading || tokenLoading}
              type="submit"
              variant="contained"
              sx={{ width: 200 }}
            >
            Accept Invite
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  );
}
