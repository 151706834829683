import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import Typography from '../../../styled/Typography/Typography';
import CurrentMilestone from '../CurrentMilestone';

const StyledContainer = styled(Box)`
  position: absolute;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
`

const DetailsContainer = styled(Box)`
  border-left: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  padding-left: 10px;
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
`

const DetailColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 42%;
`
const DetailRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailNumber = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.themeColor.bodyMain};
  display: flex;
`

export default function Overview({
  sideBarStats,
  asset,
  setAsset,
  client,
  originalFC,
  activeProcess,
  setActiveProcess,
  currentMilestone,
  milestones,
  hasBlockingTask,
  setCurrentMilestone,
  hasOpenHolds,
  readOnly,
  setScreen,
  activeStep,
  milestoneID
}) {
  const theme = useTheme();
  const { stepGrossFcDays, netDuration, stepBudgetDays, netVsBudget } = sideBarStats;

  const needsSpace = (show, compare) => {
    if (compare < 0 && show > 0) {
      return <DetailNumber mr={.3} sx={{visibility: 'hidden'}}> - </DetailNumber>
    }
  }

  return (
    <StyledContainer theme={theme}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CurrentMilestone
          asset={asset}
          setAsset={setAsset}
          client={client}
          activeProcess={originalFC || activeProcess}
          setActiveProcess={setActiveProcess}
          milestoneID={milestoneID}
          milestones={milestones}
          activeStep={activeStep}
          process={originalFC || activeProcess}
          hasBlockingTask={hasBlockingTask}
          currentMilestone={currentMilestone}
          setCurrentMilestone={setCurrentMilestone}
          hasOpenHolds={hasOpenHolds}
          assetState={asset.state}
          readOnly={readOnly}
          originalFC={originalFC}
          setScreen={setScreen}
        />
      </Box>
      <DetailsContainer theme={theme}>
        <DetailColumn>
          <DetailRow theme={theme}>
            Step Gross 
            <DetailNumber number theme={theme}>
              {needsSpace(stepGrossFcDays, netDuration)} {stepGrossFcDays}
            </DetailNumber>
          </DetailRow>
          <DetailRow theme={theme}>
            Step Net
            <DetailNumber number theme={theme}>
              {needsSpace(netDuration, stepGrossFcDays)} {netDuration}
            </DetailNumber>
          </DetailRow>
        </DetailColumn>
        <DetailColumn>
          <DetailRow theme={theme}>
            <DetailNumber number theme={theme}>
              {needsSpace(stepBudgetDays, netVsBudget)} {stepBudgetDays}
            </DetailNumber>
             Step Budget
          </DetailRow>
          <DetailRow theme={theme}>
            <DetailNumber number theme={theme}>
              {needsSpace(netVsBudget, stepBudgetDays)} {netVsBudget}
            </DetailNumber>
             I/O
          </DetailRow>
        </DetailColumn>
      </DetailsContainer>
    </StyledContainer>
  )
}
