import { gql } from "@apollo/client";

export const CREATE_PROCESS = gql`
  mutation CreateProcess($input: createProcessInput, $activeProcessID: ID, $additionalProperties: [ID]) {
    createProcess(input: $input, activeProcessID: $activeProcessID, additionalProperties: $additionalProperties) {
      id
      processSteps
      processType
      stepID
      deleted
      status
      startDate

      referral {
        id
        referralStatus
        deleted
        team {
          id
          name
        }
      }

      holds {
        id
        startDate
        closeDate
        expectedCloseDate
        createdInMilestone
        holdDescription
        holdType
      }

      events {
        id
        type
        createdDate
        milestoneID
      }

      tasks {
        id
        blocking
        deleted
        completedAt
      }

      task_flows {
        id
        blocking
        completedAt
      }

      asset {
        id
      }
    }
  }
`