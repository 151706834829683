import React, { useState, useContext, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../styled/Button/Button";
import CreateTaskFlowTemplate from "./CreateTaskFlow";
import { UserContext } from '../../context/UserContext';
import StandardGrid from "../Grid/Grid";
import { useQuery } from "@apollo/client";
import { GET_TASK_FLOW_TEMPLATES_QUERY } from '../../apollo/queries/getTaskFlowTemplatesQuery';
import TemplateOptions from "./TemplateOptions";

const myColsDef = [
  {
    headerName: "Category",
    field: "subcategory",
    sortable: true,
    sort: 'asc',
    width: 200,
    hide: true,
    rowGroup: true,
  },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    sort: 'asc',
    width: 300,
    hide: true,
    rowGroup: true,
  },
  {
    headerName: "Description",
    field: "description",
    hide: true,
    sortable: true,
    width: 300,
  },
  {
    headerName: "Create on Milestone",
    field: "createOnMilestoneID",
    sortable: true,
    width: 200,
  },
  {
    headerName: "States",
    field: "states",
    sortable: true,
    width: 200,
  },
  {
    headerName: "Subtasks",
    field: "subtaskName",
    sortable: true,
    width: 350,
  },
  {
    headerName: "",
    field: "",
    sortable: true,
    width: 300,
    cellRenderer: "TemplateOptions",
  },
]

const components = {
  TemplateOptions,
}

const ls = window.localStorage;

const TaskFlows = ({ selectedTab, screen, isTeam }) => {
  const [showTaskFlowDrawer, setShowTaskFlowDrawer] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [columnDefs, setColumnDefs] = useState(myColsDef);
  const { portfolioID } = useContext(UserContext);
  
  const { loading, data } = useQuery(GET_TASK_FLOW_TEMPLATES_QUERY, {
    variables: { portfolioID },
  });

  useEffect(() => {
    if (data) {
      const templates = data.findTaskFlowTemplate.filter((taskFlow) => !taskFlow.deleted);

      const categoryData = {
        'FORECLOSURE': [],
        'BANKRUPTCY': [],
        'LOSS_MITIGATION': [],
        'EXPENSE/FEES/ADVANCE': [],
        'OTHER': [],
      }
      templates.map((taskFlow) => {      
        if (!taskFlow.processType) {
          categoryData['OTHER'].push(taskFlow);
        }
        if (taskFlow.processType && taskFlow.processType.includes('OTHER')) {
          categoryData['OTHER'].push(taskFlow)
        }
        if (taskFlow.processType && taskFlow.processType.includes('EXPENSE/FEES/ADVANCE')) {
          categoryData['EXPENSE/FEES/ADVANCE'].push(taskFlow)
        }
        if (taskFlow.processType && taskFlow.processType.includes('FORECLOSURE')) {
          categoryData['FORECLOSURE'].push(taskFlow)
        } 
        if (taskFlow.processType && taskFlow.processType.includes('BANKRUPTCY')) {
          categoryData['BANKRUPTCY'].push(taskFlow)
        } 
        if (taskFlow.processType && (taskFlow.processType.includes('LOSS_MITIGATION') || taskFlow.processType.includes('LOSS MITIGATION'))) {
          categoryData['LOSS_MITIGATION'].push(taskFlow)
        }
      });

      setTemplates(categoryData);
    }
  }, [data]);

  useEffect(() => {
    const visibleColumns = ls.getItem('assignedTaskFlows') ? JSON.parse(ls.getItem('assignedTaskFlows')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [selectedTab, screen]);

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    columnDefs,
    groupDisplayType: 'multipleColumns',
    animateRows: true,
    groupHideOpenParents: true,
  };

  const onClickCreate = () => {
    setShowTaskFlowDrawer(true);
  }
  const autoGroupColumnDef = { minWidth: 300, cellRendererParams: { suppressCount: true } }
  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const transformAssetData = (templates) => {
    const result = [];
    const processData = (flow, process) => {
      flow.subtasks.forEach((subtask, i) => {
        const data = {
          subcategory: process.replace('_', ' '),
          name: flow.name,
          description: flow.description,
          createOnMilestone: flow.createOnMilestone,
          createOnMilestoneID: flow.createOnMilestone ? flow.createOnMilestoneID : null,
          createdBy: flow.createdBy,
          id: flow.id,
          states: flow.createOnMilestone ? flow.states : null,
          subtasks: flow.subtasks,
          subtaskName: subtask.name,
          global: flow.global,
          showToEveryone: flow.showToEveryone,
          version: flow.version,
          processType: flow.processType,
          originalID: flow.originalID,
        };

        if (flow.subtasks[i - 1]) {
          result.push({ ...data, showButtons: false })
        } else {
          result.push({ ...data, showButtons: true })
        }
      })
    }

    for (const [process, flows] of Object.entries(templates)) {
      flows.forEach((flow) => {
        processData(flow, process);
      });
    }

    return result;
  }

  if (loading) return <CircularProgress />;

  return (
    <>
      <StandardGrid
        tableName="assignedTaskFlows"
        rowData={transformAssetData(templates)}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={components}
        autoGroupColumnDef={autoGroupColumnDef}
        isTeam={isTeam}
        floatingButton={
          <Button onClick={onClickCreate}>Create task flow</Button>
        }
      />
      <CreateTaskFlowTemplate
        show={showTaskFlowDrawer}
        setShow={setShowTaskFlowDrawer}
        portfolioID={portfolioID} 
      />
    </>
  )
}

export default TaskFlows;