import React, { useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { MessageContext } from '../../context/MessageContext';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EVENT } from '../AssetDetail/DetailsSidebar/ProcessDrawer';
import { CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION } from '../../apollo/mutations/createTaskFlowAndSubtasksMutation';
import { GET_TASK_FLOW_TEMPLATES_QUERY } from '../../apollo/queries/getTaskFlowTemplatesQuery';
import { GET_LAW_FIRM_MANAGER } from '../../apollo/queries/getLawFirmManager';
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import styled from '@emotion/styled';
import { UserContext } from '../../context/UserContext';
import { useTheme } from '@emotion/react';

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
`;
const ConfirmButton = styled(Button)`
  width: auto;
  margin-right: 25px;
`;
const CancelButton = styled(Button)`
  background-color: transparent !important;
  width: auto;
  border: none;
  box-shadow: none;
  margin-left: 15px;
`;

const RequestBillOnClose = ({ open, onClose, milestoneID, closeProcessNow, process, client }) => {
  const { addMessage } = useContext(MessageContext);
  const { portfolioID, user } = useContext(UserContext);
  const theme = useTheme();

  const [createEvent] = useMutation(CREATE_EVENT);
  const { data: tfData, loading: tfLoading } = useQuery(GET_TASK_FLOW_TEMPLATES_QUERY, {
    variables: { portfolioID }
  });
  const {data: lfData, loading: lfLoading } = useQuery(GET_LAW_FIRM_MANAGER, {
    skip: !process?.id,
    variables: { processID: process?.id }
  });
  const [createTaskFlowAndSubtasks] = useMutation(CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION);

  if (tfLoading || lfLoading) return <CircularProgress />;

  const handleCloseNow = async (e) => {
    await closeProcessNow(e);
    onClose();
  };

  const handleBillAttorney = async (e) => {
    e.preventDefault();
    const attorney = lfData?.findLawFirmManager;

    if (!attorney)
      return addMessage({
        message: "Please ensure this asset is properly referred and accepted by an attorney or law firm."
      });
    
    const closeEvent = {
      type: "process-closed",
      description: `Process has been closed by ${user.firstName} ${user.lastName}`,
      createdDate: new Date(),
      process: process.id,
      milestoneID
    };

    try {
      await createEvent({ variables: { event: closeEvent } });
      const userTemplates = tfData?.findTaskFlowTemplate;
      if (!userTemplates || !userTemplates.length) {
        addMessage({ message: "There has been an error closing this process. Please try again." });
        return;
      }

      const finalBill = tfData.findTaskFlowTemplate
        .find((template) => template.name.toLowerCase() === "request final bill from attorney");

      await createTaskFlowAndSubtasks({
        variables: {
          input: {
            flowID: finalBill.id,
            assignments: [attorney.id, user.id],
            process: process.id,
            name: finalBill.name,
            startDate: new Date(),
            blocking: true,
            subtaskData: finalBill.subtasks.map((subtask, i) => {
              if (i !== 2) 
                return { ...subtask, assignments: [attorney.id] }
              else
                return { ...subtask, assignments: [user.id] }
            })
          }
        }
      })

      const tfEvent = {
        type: "task-created",
        description: `Task flow with ${finalBill.subtasks.length} subtasks`,
        createdDate: new Date(),
        createdFor: attorney.id,
        milestoneID,
        process: process.id,
        title: finalBill.name,
      }
      await createEvent({ variables: { event: tfEvent } });
      await client.refetchQueries({ include: "all" });
      onClose();
    } catch(err) {
      addMessage({ message: "There has been an error closing this process. Please try again."})
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Typography
        variant="body1"
        mt={1} mb={5}
        color={theme.themeColor.bodyMain}
      >
        Would you like to request a final bill from your attorney?
      </Typography>

      <ButtonBox>
        <ConfirmButton onClick={handleBillAttorney}>Yes, Continue</ConfirmButton>
        <ConfirmButton onClick={(e) => handleCloseNow(e)}>No, Continue</ConfirmButton>
        <CancelButton
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </CancelButton>
      </ButtonBox>
    </Modal>
  )
};

export default RequestBillOnClose;