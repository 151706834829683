import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import { IMPORT_LOAN_LIST_MUTATION, IMPORT_LOANS_DETAILS_MUTATION, IMPORT_LOANS_PROPERTIES_MUTATION, IMPORT_LOANS_TO_JONAH } from "../../apollo/mutations/importLoanAssetsMutation";
import { UPDATE_PORTFOLIO_MUTATION } from "../../apollo/mutations/updatePortfolioMutation";
import Button from "../../styled/Button/Button";
import { TextField, Box, LinearProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";

const INITIAL_FORM_STATE = {
  token: "",
  database: "",
};

const INITIAL_ERROR_STATE = {
  token: false,
  database: false,
};

const errorMessages = {
  token: "Please provide a token.",
  database: "Please provide a database name.",
};

export default function AddApiForm({ onCloseImportModal, loading, setLoading, assetRefetch }) {
  const history = useHistory();
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const { user, portfolioID } = useContext(UserContext);
  const { userType } = user;

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [error, setError] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState("");

  const [updateApiAndToken] = useMutation(UPDATE_PORTFOLIO_MUTATION);
  const [importLoanList] = useMutation(IMPORT_LOAN_LIST_MUTATION);
  const [importLoanListDetails] = useMutation(IMPORT_LOANS_DETAILS_MUTATION);
  const [importLoanListProperties] = useMutation(IMPORT_LOANS_PROPERTIES_MUTATION);
  const [importLoanListToJonah] = useMutation(IMPORT_LOANS_TO_JONAH);

  function formValidation() {
    let hasError = false;

    if (formData.token.length === 0) {
      setFormError((prevState) => ({ ...prevState, token: true }));
    } else {
      setFormError((prevState) => ({ ...prevState, token: false }));
    }

    if (formData.database.length === 0) {
      setFormError((prevState) => ({ ...prevState, database: true }));
    } else {
      setFormError((prevState) => ({ ...prevState, database: false }));
    }
    return hasError;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!formValidation() && userType === "PORTFOLIO_MANAGER") {
      setLoading(true);
      setError(null);
      setShowProgress(true);
      setProgress(1);
      const input = {
        where: {
          id: portfolioID,
        },
        data: {
          token: formData.token,
          database: formData.database,
        },
      };

      try {
        setCurrentStep("Updating API information...");
        await updateApiAndToken({
          variables: { input },
        });
        setCurrentStep({ message: "API information added successfully..." });
        setProgress(5);
        addMessage({ message: "Importing assets now." });

        try {
          setCurrentStep("Importing assets from TMO...");
          const loanListResponse = await importLoanList({ variables: { portfolioID }});
          const loanList = loanListResponse.data.importLoanList;
          setProgress(25);

          setCurrentStep(`Imported ${loanList.length} assets. Updating assets details...`);
          const loanListDetailsResponse = await importLoanListDetails({ variables: { portfolioId: portfolioID, loansList: loanList }});
          const loanListDetails = loanListDetailsResponse.data.importLoanListDetails;
          setProgress(50);

          setCurrentStep(`${loanListDetails.length} to be imported to Jonah Direct. Checking properties...`);
          const loanListPropertiesResponse = await importLoanListProperties({ variables: { portfolioId: portfolioID, loanListWithDetails: loanListDetails }});
          const loanListProperties = loanListPropertiesResponse.data.importLoanListWithProperties;
          setProgress(75);
          
          setCurrentStep(`Finishing importing ${loanListProperties.length} assets to Jonah Direct...`);
          const imported = await importLoanListToJonah({ 
            variables: { portfolioId: portfolioID, loanListWithProperties: loanListProperties }, 
          });
          await assetRefetch();
          if (imported.errors && imported.errors.length > 0) {
            throw new Error(imported.errors[0].message);
          } else {
            setProgress(100);
            setCurrentStep("");
            addMessage({ message: "Assets imported successfully." });
            history.push("/");
          }

        } catch (importResponseError) {
          const errorMessage = 'The server has encountered an error while trying to import loans. Please try again or come back later.'
          setError({ message: errorMessage });
          addMessage({ message: errorMessage, type: "error" })
          setLoading(false);
        }
      } catch (error) {
        const errorMessage = 'The server has encountered an error while trying to update the portfolio. Please try again or come back later.'
        setError({ message: errorMessage });
        addMessage({ message: errorMessage, type: "error" })
        setLoading(false);
      }
    }
  }

  function handleChange(event) {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  const { token, database } = formError;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          name="token"
          value={formData.token}
          onChange={handleChange}
          label="Token Key"
          variant="outlined"
          fullWidth
          error={token}
          helperText={token ? errorMessages.token : ''}
        />

        <TextField
          type="text"
          name="database"
          value={formData.database}
          onChange={handleChange}
          label="Database Name"
          variant="outlined"
          fullWidth
          sx={{ mt: 1, mb: 1 }}
          error={database}
          helperText={database ? errorMessages.database : ''}
        />

        <Typography variant="p" component="p" sx={{color: theme.themeColor.bodySecondary}}>
          Your Token Key and Database Name are provided by your organization.
        </Typography>

        <Box sx={{marginTop: '50px'}}>
          {error && <ErrorMessage error={error.message} />}
          {!error && showProgress && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
              {currentStep && <Typography variant="p" component="p" sx={{color: theme.themeColor.bodySecondary}}>{`${currentStep}`}</Typography>}
            </Box>
          )}
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Button
              type="submit"
              sx={{
                mr: 1,
                width: '225px'
              }}
              disabled={formData.token.length === 0 || formData.database.length === 0 || loading}
              loading={loading}
            >
              Import
            </Button>

            {!showProgress && !loading && (
              <Button
                variant="secondary"
                sx={{fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
                onClick={onCloseImportModal}
              >
              Skip and import later
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </div>
  );
}
