import React, { useMemo } from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ApolloProvider } from "@apollo/client";
import {
  ApolloClient,
  ApolloLink,
  concat
} from "@apollo/client";
import { cache, defaultOptions, nextLink, authMiddleware } from "./apollo/index";
import { onError } from "@apollo/client/link/error";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import MessageProvider from "./context/MessageContext";
import UserProvider from "./context/UserContext";
import AssetImportProvider from "./context/AssetImportContext";
import ErrorNotifier from "./components/ErrorNotifier/ErrorNotifier";
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';
import { checkPath } from "./helpers";
import AssetProvider from "./context/AssetContext";
import MilestoneTokenProvider from "./context/MilestoneTokenContext";

// Setting AG Grid license
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-046594 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Redhawk Research LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Jonah Direct )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Jonah Direct )_need_to_be_licensed___( Jonah Direct )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 August 2024 )____[v2]_MTcyMzY3NjQwMDAwMA==284c792054e5c7292ff42335463a57a4")

// Initialising Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const MyApolloProvider = ({ showError }) => {
  const client = useMemo(
    () => new ApolloClient({
      cache,
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          console.log(graphQLErrors);
          console.log(networkError);
          if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            showError()
          }
     
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path, extensions }) => {
              if (extensions.code === "INTERNAL_SERVER_ERROR" && checkPath(path)) {
                showError()
                console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
              }
            })
          }}),
        concat(authMiddleware, nextLink)
      ]),
      defaultOptions
    }), 
    [showError]
  )
 
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <MessageProvider>
          <AssetImportProvider>
            <AssetProvider>
              <MilestoneTokenProvider>
                <Router>
                  <App client={client} />
                </Router>
              </MilestoneTokenProvider>
            </AssetProvider>
          </AssetImportProvider>
        </MessageProvider>
      </UserProvider>
    </ApolloProvider>
  );
}

render(
  <ErrorNotifier>
    {(showError) => (
      <MyApolloProvider showError={showError} />
    )}
  </ErrorNotifier>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

