import { gql } from "@apollo/client";

export const ASSETS_QUERY = gql`
  query FindAssets($portfolioID: ID) {
    findAssets(portfolioID: $portfolioID) {
      id
      loanNumber
      state
      borough
      statusValue
      upb
      assetAddress
      borrowerFirstName
      borrowerLastName
      servicer
      propertyID
      borrowerAddress
      borrowerState
      borrowerCity
      borrowerZip

      documents {
        id
      }

      activities {
        id
        comments {
          id
          description
          createdDate
          deleted
          createdBy {
            firstName
            lastName
          }
        }
        tasks {
          id
          createdAt
          completedAt
          deleted
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        task_flows {
          id
          startDate
          completedAt
          deleted
          subtasks {
            id
            completedAt
            task_comments {
              id
              description
              comment
              createdAt
              createdBy {
                firstName
                lastName
              }
            }
          }
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
      }

      activeProcess {
        id
        stepID
        processSteps
        processType
        tldr

        referral {
          id
          referralStatus
          deleted
          referredBy {
            firstName
            lastName
          }

          team {
            id
            name
          }
        }
        task_flows {
          id
          startDate
          completedAt
          deleted
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        tasks {
          id
          createdAt
          completedAt
          deleted
          task_comments {
            id
            description
            comment
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        comments {
          id
          description
          createdDate
          deleted
          createdBy {
            firstName
            lastName
          }
        }
        holds {
          id
          holdDescription
          holdType
          holdComment
          active
          createdInMilestone
          deleted
          hold_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
      
      }

      processes {
        id
        stepID
        processSteps
        tldr

        referral {
          id
          referralStatus
          deleted
          referredBy {
            firstName
            lastName
          }

          team {
            id
            name
          }
        }
        task_flows {
          id
          startDate
          completedAt
          deleted
          task_comments {
            id
            description
            createdAt
            createdBy {
              firstName
              lastName
            }
          }
        }
        tasks {
          id
          createdAt
          completedAt
          deleted
          task_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
        comments {
          id
          description
          createdDate
          deleted
          createdBy {
            firstName
            lastName
          }
        }
        holds {
          id
          holdDescription
          holdType
          holdComment
          active
          createdInMilestone
          deleted
          hold_comments {
            id
            description
            createdAt
            comment
            createdBy {
              firstName
              lastName
            }
          }
        }
      }
      loan_status {
        totalDaysInFc
        totalDaysInBk
        stepDaysOnHold
        stepBudgetDays
        totalDaysOverdue
        netVsBudget
        daysInDlq
        budgetDaysToSale
        stepNetDaysOverdue
        stepGrossDaysOverdue
        grossVsBudget
        stepGrossFcDays
      }
    }
  }
`;