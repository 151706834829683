import React, { useEffect, useState } from 'react';
import { Box, Button, Badge, InputAdornment, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import Typography from '../../styled/Typography/Typography';
import { gql, useMutation } from '@apollo/client';
import { EVENTS_QUERY } from '../../apollo/queries/eventsQuery';

const StyledContainer = styled(Box)`
  position: absolute;
  top: 59px;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};

  &::-webkit-scrollbar {
    display: none;
  };
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin-left: 5px;
  background-color: transparent;
`;

const TLDRInput = styled(TextField)`
  width: 100%;
  font-size: 12px;
  backdrop-filter: blur(20px);
  
  & .MuiInputBase-input {
    background-color: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  }
`;

const UPDATE_PROCESS_MUTATION = gql`
  mutation UpdateProcess($updateProcessInput: updateProcessInput!, $createEventInput: createEventInput!) {
    updateProcess(input: $updateProcessInput) {
      id
    }
    createEvent(input: $createEventInput) {
      id
    }
  }
`;


export default function TLDR({
  openFilter,
  numFiltersChanged,
  process
}) {
  const theme = useTheme();
  const [originalTldr, setOriginalTldr] = useState('');
  const [tldr, setTldr] = useState('');
  const [count, setCount] = useState(0);

  const [updateProcess] = useMutation(UPDATE_PROCESS_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID: process.stepID, processID: process.id } },
    ],
  });

  useEffect(() => {
    if (process && process.tldr) {
      setTldr(process.tldr);
      setOriginalTldr(process.tldr);
      setCount(process.tldr.length);
    }
  }, [process]);

  const handleChange = (ev) => {
    if (ev.target.value.length > 200) return
    setTldr(ev.target.value);
    setCount(ev.target.value.length);
  }

  const handleSave = async () => {
    if (tldr.length > 200) return
    if (tldr === originalTldr) return;
    
    const eventData = {
      type: 'tldr-update',
      milestoneID: process.stepID,
      process: process.id,
      createdDate: new Date(),
      description: tldr
    }

    await updateProcess({
      variables: {
        createEventInput: eventData,
        updateProcessInput: {
          where: {
            id: process.id
          },
          data: {
            tldr
          }
        }
      }
    });

    setOriginalTldr(tldr);
  }

  return (
    <form onSubmit={handleSave} onKeyPress={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSave();
      }
    }}>
      <StyledContainer theme={theme}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <TLDRInput
            theme={theme}
            variant="standard"
            placeholder="Add a summary or important note here."
            multiline
            maxRows={2}
            value={tldr}
            onChange={handleChange}
            onBlur={handleSave}
            InputProps={{
              startAdornment: <InputAdornment position="end" sx={{ mr: 1 }}>
                <Typography><b>TLDR</b></Typography>
              </InputAdornment>,
              endAdornment: <InputAdornment position="end" sx={{ mr: 1 }}>
                <Typography><span style={{ fontSize: '8px' }}>{count}/200</span></Typography>
              </InputAdornment>,
            }}
          />
        </Box>

        <StyledButton
          onClick={() => openFilter(true)}
        >
          FILTER TIMELINE
          <Badge sx={{ml: 2, fontFamily: 'IBM Plex Mono'}} badgeContent={numFiltersChanged} color="secondary" />
        </StyledButton>
      </StyledContainer>
    </form>
  )
}