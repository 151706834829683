import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import { Autocomplete, Box, CircularProgress, Container, FormControl, InputAdornment } from "@mui/material";
import { Timeline, TimelineOppositeContent, TimelineItem, TimelineConnector, TimelineSeparator, TimelineDot, TimelineContent } from '@mui/lab';
import Modal from "../../styled/Modal/Modal";
import { TextInput } from "../../styled/TextInput/TextInput";
import Typography from "../../styled/Typography/Typography";
import SearchIcon from '@mui/icons-material/Search';
import { GET_USERS_BY_PORTFOLIO_AND_TEAM } from "../../apollo/queries/getUsersByPortfolioAndTeam";
import { GET_TEAMS } from "../../apollo/queries/getTeams";
import { GET_TASK_FLOW_TEMPLATES_QUERY } from "../../apollo/queries/getTaskFlowTemplatesQuery";
import DatePickerSelect from "../DatePickerSelect/DatePickerSelect";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { UserContext } from "../../context/UserContext";
import Button from "../../styled/Button/Button";
import { MessageContext } from "../../context/MessageContext";
import { CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION } from '../../apollo/mutations/createTaskFlowAndSubtasksMutation';
import { useEffect } from "react";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import styled from "@emotion/styled";
import { ACTIVITY_TASKS_QUERY } from "../../apollo/queries/activityTasksQuery";
import { ACTIVITY_TASK_FLOWS_QUERY } from "../../apollo/queries/activityTaskFlowsQuery";
import { GET_ACTIVITY_EVENTS } from "../../apollo/queries/getActivityEvents";
import { formatPortfolioUsers } from "../../utilities/portfolioUsers";

const TimelineDate = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color:  ${({theme}) => theme.themeColor.bodyMain};
  font-size: 16px;
`
const Content = styled(Box)`
  display: flex;
  justify-content: space-between;
`

const CreateTaskFlow = ({ show, onClose, flowTemplate, processID, assetID, milestoneID, activity }) => {
  const theme = useTheme();
  const { portfolioID, teamID, user } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const [isActivity] = useState((activity?.id && !process?.id) ? true : false);

  const { loading, data, error: loadError } = useQuery(GET_USERS_BY_PORTFOLIO_AND_TEAM, {
    variables: { portfolioID, teamID, assetID },
  });
  const { loading: teamLoading, data: teamData, error: teamLoadError } = useQuery(GET_TEAMS);

  const [createFlow, { loading: loadingCreateFlow }] = useMutation(CREATE_TASK_FLOW_AND_SUBTASKS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } }
    ]
  });

  const { loading: assetLoading, data: assetRes } = useQuery(ASSET_QUERY, {
    variables: { assetID, userID: user.userID },
    fetchPolicy: 'no-cache'
  });

  const eventRefetchQueries = isActivity
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: activity?.id } },
      { query: ACTIVITY_TASK_FLOWS_QUERY, variables: { activityID: activity?.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: activity?.id } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: eventRefetchQueries
  });

  const initialData = {
    name: flowTemplate.name,
    milestone: milestoneID,
    blocking: false,
    process: processID,
    startDate: null,
    subtaskData: [],
    activityID: activity?.id
  }

  useEffect(() => {
    if (flowTemplate) {
      const subtasksCopy = flowTemplate.subtasks.map((subtask) => {
        const newSubtask = { name: subtask.name, order: subtask.order, budgetDays: subtask.budgetDays, assignments: [] };
        if (subtask.responsible) {
          if (subtask.responsible === "PORTFOLIO_MANAGER") {
            newSubtask.assignments = data.getUsersByPortfolioAndTeam.filter((user) => user.userType === "PORTFOLIO_MANAGER");
          } else if (subtask.responsible === "TEAM_MANAGER") {
            newSubtask.assignments = teamData.findTeams.filter((team) => team.type === "TEAM_MANAGER");
          }
        }
        return newSubtask;
      });

      setFormData((prevState) => ({
        ...prevState,
        name: flowTemplate.name,
        subtaskData: subtasksCopy,
        startDate: flowTemplate.startDate || new Date()
      }));
    }
  }, [flowTemplate])

  const [formData, setFormData] = useState(initialData);
  const [assignments, setAssignments] = useState([]);
  const [error, setError] = useState('');

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const setBlocking = (blocking) => {
    setFormData((prevState) => ({
      ...prevState,
      blocking,
    }));
  }

  const setStartDate = (startDate) => {
    setFormData((prevState) => ({
      ...prevState,
      startDate,
    }));
  }

  const setBudgetDays = (e, i) => {
    if (e.target.value.length === 0) return;
    const value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    const subtaskCopy = formData.subtaskData.map((subtask) => ({...subtask}));
    subtaskCopy[i].budgetDays = Number(value);
    const formDataCopy = {...formData, subtaskData: subtaskCopy }
    setFormData(formDataCopy);
  }

  if (loading || teamLoading || assetLoading) return <CircularProgress />;
  if (loadError || teamLoadError) return <p>{loadError || teamLoadError}</p>;

  const portfolioUsers = data?.getUsersByPortfolioAndTeam?.length > 0 ? data.getUsersByPortfolioAndTeam : [];
  const allOptions = formatPortfolioUsers(teamData.findTeams, portfolioUsers, assetRes?.asset, user);

  const getOptionLabel = (option) => (
    option.name ? option.name : `${option.firstName} ${option.lastName}`
  )

  const formValidation = () => {
    let hasError = false;

    if (formData.name.length === 0) {
      setError('You must give a name this task flow.');
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, title: false }));
    }

    if (assignments.length === 0) {
      setError('You must assigned some user to this task flow.');
      hasError = true;
    } else {
      setError('');
    }


    return hasError;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValidation()) {
      const dueDate = getExpectedDueDate(formData.startDate, formData.subtaskData, formData.subtaskData.length - 1);
      try {
        formData.subtaskData.forEach((subtask) => {
          subtask.assignments = subtask.assignments.map((user) => user.id)
        })

        const input = {
          ...formData,
          flowID: flowTemplate.id,
          assignments: assignments.map((user) => user.id),
          dueDate: new Date(dueDate).toISOString(),
          milestone: milestoneID
        }
        if (activity?.id) input.activityID = activity.id;
        else input.process = processID;

        const newFlow = await createFlow({
          variables: { 
            input
          }
        });

        if (newFlow?.data?.createTaskFlow) {
          addMessage({ message: "Task flow created sucessfully. "});
          onClose();
          setFormData(initialData);
          setAssignments([]);

          const event = {
            type: "task-created",
            description: `Task flow with ${flowTemplate.subtasks.length} subtasks`,
            createdDate: new Date(),
            createdFor: assignments[0].id,
            milestoneID: milestoneID,
            process: processID,
            title: formData.name,
            activity: activity?.id
          }

          await createEvent({
            variables: {
              event
            }
          });
        }
      } catch(e) {
        console.log(e)
        addMessage({ message: 'Something went wrong. Try again.', type: 'error' });
      }
    }
  }

  const onSelectAssigned = (_event, newAssignments) => {
    const assignees = newAssignments.map((assignee) => {
      if (assignee.__typename === "User")
        return assignee
      else if (assignee.__typename === "Team") {
        const members = assignee.memberships.map((member) => member.user);
        return members;
      }
    })
    const newAssignees = [...new Set(assignees.flat())];

    setAssignments(newAssignees)
    setFormData((prev) => {
      const subtaskCopy = prev.subtaskData.map((subtask) => ({ ...subtask }));
      subtaskCopy.forEach((subtask) => {
        subtask.assignments = newAssignees;
      });
      return { ...prev, subtaskData: subtaskCopy }
    })
  };
  const onSelectSubtaskAssigned = (_event, newAssignments, i) => {
    const assignees = newAssignments.map((assignee) => {
      if (assignee.__typename === "User")
        return assignee
      else if (assignee.__typename === "Team") {
        const members = assignee.memberships.map((member) => member.user);
        return members;
      }
    })

    setFormData((prev) => {
      const subtaskCopy = prev.subtaskData.map((subtask) => ({...subtask}));
      subtaskCopy[i].assignments = [...new Set(assignees.flat())];
      return {...prev, subtaskData: subtaskCopy}
    })
  }

  const getExpectedDueDate = (startDate, subtasks, i) => {
    let start = startDate ? new Date(startDate) : new Date();
    let daysToAdd = subtasks.slice(0).reduce((acc, curr, item, arr) => {
      if (item === i) arr.splice(1);
      return acc + curr.budgetDays;
    }, 0)
    if (isNaN(daysToAdd))
      daysToAdd = 7 * i; // Default 7 days, add another 7 to calculate expected due date
    start.setDate(start.getDate() + daysToAdd);
    return start;
  }

  return (
    <Modal open={show} onClose={onClose} height="95vh">
      <Container sx={{
        marginBottom: '30px',
        background: theme.themeColor.backgroundBody
      }}>
        <Typography component="h1" variant="h4">New Task Flow</Typography>
      </Container>

      <Container sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '60px'}}>
        <Box sx={{display: 'flex', flexDirection: "column", width: '48%'}}>
          <FormControl>
            <label>Flow Name</label>
            <TextInput 
              placeholder="Task Flow Name" 
              name="title"
              value={formData.name}
              onChange={handleChange}
              theme={theme}
            />
          </FormControl>

          <FormControl>
            <label>Assign To:</label>
            <Autocomplete
              multiple
              options={allOptions}
              getOptionLabel={getOptionLabel}
              groupBy={(option) => `${option.__typename}s`}
              renderGroup={(params) => (
                <li style={{ margin: '10px' }}key={params.key}>
                  <Typography sx={{fontWeight: 'bold'}}>{params.group}</Typography>
                  <Typography>{params.children}</Typography>
                </li>
              )}
              onChange={onSelectAssigned}
              renderInput={(params) => <TextInput {...params}
                data-testid="testid"
                theme={theme}
                InputProps={{
                  ...params.InputProps,
                  "data-testid": "assign-task-to",
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />}
            />
          </FormControl>
        </Box>
      </Container>
      
      <Container>
        <Checkbox
          value="blocking"
          onChange={() => setBlocking(!formData.blocking)}
          checked={formData.blocking}
          label="This task flow is blocking"
        />
      </Container>

      <Container>
        <Box>
          <FormControl sx={{width: "48%", background: theme.themeColor.backgroundBody, marginBottom: "25px"}}>
            <label>Start Date:</label>
            <DatePickerSelect
              id='task-startat-date'
              data-testid="task-datepicker"
              placeholder="Choose a date"
              selected={formData.startDate}
              setSelected={setStartDate}
              minDate={new Date()}
            />
          </FormControl>
        </Box>
      </Container>

      {(formData?.subtaskData?.length > 0) &&
        <Container sx={{ marginBottom: "75px", display: "flex", flexDirection: "column", maxHeight: "30%", overflowY: "scroll", width: "100%" }}>
          <Typography>Subtasks:</Typography>
          <Timeline sx={{width: "100% !important"}}>
            {formData.subtaskData.map((subtask, i) => {
              return (
                <TimelineItem key={`${subtask.name}-${i}`}>
                  <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ pr: 0 }}>
                    <TimelineDate theme={theme}>{subtask.name}</TimelineDate>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "100%", pr: 2 }}>
                        <Content sx={{ paddingBottom: "10px"}}>
                          <Typography sx={{ alignSelf: "center" }}>Budgeted Days</Typography>
                          <TextInput
                            id={`budgeted-days-${i}`}
                            size="small"
                            sx={{width: "75px"}}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                            label={subtask?.budgetDays || 7} 
                            onChange={(e) => setBudgetDays(e, i)}
                          />
                        </Content>
                        <Content sx={{ paddingBottom: "10px"}}>
                          <Typography>Expected Due Date:</Typography>
                          <Typography number>{getExpectedDueDate(formData.startDate, formData.subtaskData, i).toLocaleDateString()}</Typography>
                        </Content>
                        <Content>
                          <Typography sx={{ alignSelf: "center" }}>Assigned to:</Typography>
                          <Autocomplete
                            multiple
                            options={allOptions}
                            getOptionLabel={getOptionLabel}
                            groupBy={(option) => `${option.__typename}s`}
                            renderGroup={(params) => (
                              <li style={{ margin: '10px' }}key={params.key}>
                                <Typography sx={{fontWeight: 'bold'}}>{params.group}</Typography>
                                <Typography>{params.children}</Typography>
                              </li>
                            )}
                            onChange={(e, v) => onSelectSubtaskAssigned(e, v, i)}
                            forcePopupIcon={false}
                            sx={{minWidth:"40%"}}
                            renderInput={(params) => <TextInput {...params}
                              theme={theme}
                              InputProps={{
                                ...params.InputProps,
                                "data-testid": "assign-subtask-to",
                              }}
                            />}
                            value={subtask.assignments}
                          />
                        </Content>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              )
            })}
          </Timeline>
        </Container>
      }
      

      <Container>
        <div>{error && <ErrorMessage error={error} />}</div>
        <Button id="add-taskflow-button"
          loading={loadingCreateFlow}
          disabled={loadingCreateFlow}
          role="submit"
          onClick={handleSubmit}
          sx={{width: '150px', marginRight: '15px'}}>
          Add
        </Button>
        <Button onClick={onClose} variant="secondary" sx={{width: 'auto'}}>Cancel</Button>
      </Container>
    </Modal>
  )
}

export default CreateTaskFlow;