import React, { useContext, useState } from 'react';
import UserList from "../components/UserList/UserList";
import { UserContext } from '../context/UserContext';
import Typography from '../styled/Typography/Typography';
import { useQuery } from "@apollo/client";
import { FIND_TEAM_USERS } from '../apollo/queries/findTeamUsers';

export default function Users() {
  const { user, teamID } = useContext(UserContext);
  const [teamUsers, setTeamUsers] = useState([]);

  const { loading } = useQuery(FIND_TEAM_USERS, {
    variables: {
      teamID: ~~teamID
    },
    onCompleted: (data) => {
      setTeamUsers(data.findTeamUsers.map(m => ({ userID: m.user.id, ...m.team, ...m.user, ...m })))
    }
  })


  if (user?.accDeleted || user?.noTeamsOrPortfolio) {
    return (
      <Typography component="h1" variant="h3" align="center" mt={50}>
        {user.userType === "PORTFOLIO_MANAGER"
          ? "Your account has been deleted"
          : "You do not currently belong to a team or portfolio."
        }
      </Typography>
    )
  }

  if (user.userType === "TEAM_MANAGER")
    return (
      <div className="lawfirms-list">
        <UserList
          title="Team Users"
          loading={loading}
          users={teamUsers}
          setTeamUsers={setTeamUsers}
          teamID={teamID}
        />
      </div>
    );
}
