import { Box, FormHelperText, InputAdornment } from "@mui/material";
import React from "react";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import Typography from "../../styled/Typography/Typography";
import InitialsCircle from "../../styled/InitialsCircle/InitialsCircle";
import { getInitials } from "../../helpers";
import { useTheme } from "@emotion/react";
import { MdKeyboardReturn } from 'react-icons/md';
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import MentionInput from "../Mentions/MentionInput";
import MentionHighlighter from "../Mentions/MentionHighlighter";

const Content = styled(Box)`
  margin-top: 10px;
  max-height: 100%;
`

const TimelineContainer = styled(Box)`
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ${({showEffect}) => showEffect && `
    &:after {
      content  : "";
      position : absolute;
      z-index  : 1;
      bottom   : 78px;
      left     : 0;
      pointer-events   : none;
      background-image : linear-gradient(to bottom, 
                        rgba(255,255,255, 0), 
                        ${({theme}) => rgba(theme.themeColor.backgroundBody, 0.9)}
                        );
      width    : 100%;
      height   : 5em;
    }
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`

const TimelineDate = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color:  ${({theme}) => theme.themeColor.bodySecondary};
  font-size: 16px;
`

const CommentsTimeline = ({ comments, showEffect }) => {
  const theme = useTheme();
  const timelineComments = [...comments];
  timelineComments.sort((ca, cb) => new Date(+cb.createdAt) - new Date(+ca.createdAt));
  const options = {
    day: '2-digit',
    month: '2-digit',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  }

  return (
    <TimelineContainer sx={{overflowY: 'scroll', height: '40%'}} theme={theme} showEffect={showEffect}>
      <Timeline position="right" sx={{padding: 0}}>
        {timelineComments.map((cmt, i) => { 
          const fullName = `${cmt.createdBy.firstName} ${cmt.createdBy.lastName}`;
          const initials = getInitials(fullName);

          return (
            <TimelineItem key={`${cmt.id}-${i}`}>
              <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{pr: 0}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <TimelineDate theme={theme} number>{new Date(+cmt.createdAt).toLocaleString(undefined, options)}</TimelineDate>
                  <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Typography sx={{
                      mr: 1,
                      fontSize: '16px',
                      lineHeight: '18px'
                    }}>{`${cmt.createdBy.firstName} ${cmt.createdBy.lastName}`}</Typography>
                    <InitialsCircle size="25px" fontSize="10px" fullName={fullName} initials={initials} />
                  </Box>
                </Box>
                <Typography sx={{
                  fontSize: '16px',
                  lineHeight: '18px'
                }}>
                  <MentionHighlighter>{cmt.comment}</MentionHighlighter>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </TimelineContainer>
  )
}


const TaskCommentsTimeline = ({
  handleSubmit,
  newComment,
  handleChange,
  formError,
  loading,
  addToTimeline,
  setAddToTimeline,
  comments,
  showEffect,
  readOnly,
  portfolioUsers
}) => {
  const theme = useTheme();

  return (
    <Content>
      <form 
        onSubmit={handleSubmit}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
          }
        }}
      >
        <MentionInput
          value={newComment}
          label="Enter your comment"
          multiline
          rows={3}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          error={formError}
          loading={loading}
          options={portfolioUsers}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdKeyboardReturn color={theme.themeColor.bodySecondary} />
              </InputAdornment>
            )
          }}
        />
        {formError && <FormHelperText sx={{color: theme.themeColor.brandPrimaryRed}}>{formError}</FormHelperText>}
        <Checkbox disabled={loading || readOnly} checked={addToTimeline} onChange={() => setAddToTimeline(!addToTimeline)} label="Add my comment to the main timeline" />
      </form>
      <CommentsTimeline comments={comments} showEffect={showEffect} />
    </Content>
  )
}

export default TaskCommentsTimeline;