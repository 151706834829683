import React, {useState, useRef, useEffect} from 'react';
import { InputBase } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import LawfirmStates from '../LawfirmsList/LawfirmStates';

const teamTypes = {
  INTERNAL: [
    "Has access to all assets in portfolio",
  ],
  EXTERNAL: [
    "Can only access assets assigned to the team",
  ]
}
const memberTypes = {
  MEMBER: {
    INTERNAL: [
      "- Add/Complete/Reopen on tasks",
      "- Open/Close/Reopen holds",
      "- Comment",
      "- Upload and download documents",
    ],
    EXTERNAL: [
      "- Add/Complete/Reopen on tasks",
      "- Open/Close/Reopen holds",
      "- Comment",
      "- Upload and download documents",
    ],
    LAWFIRM: [
      "- Add/Complete/Reopen on tasks",
      "- Open/Close/Reopen holds",
      "- Comment",
      "- Upload and download documents",
    ]
  },
  MANAGER: {
    INTERNAL: [
      "- All member-type permissions",
      "- Refer assets",
      "- Re-refer assets to another law firm",
      "- Close processes",
      "- Change process types",
      "- Assign assets to team",
      "- Create teams",
      "- Invite members to team",
    ],
    EXTERNAL: [
      "- All member-type permissions",
      "- Invite members to team",
    ],
    LAWFIRM: [
      "- All member-type permissions",
      "- Accept/Reject Referrals",
      "- Change process types",
      "- Invite members to team",
    ]
  }
}

const TableInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: theme.themeColor.sectionFill,
    fontSize: 12,
    height: 32,
    width: '140px',
    padding: '2px 5px',
    color: theme.themeColor.bodySecondary,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.themeColor.bodyMain, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CellWrapper = ({ value, setValue, label, api, dropdownValues, className, ...rest }) => {
  const [inputValue, setInputValue] = useState('');
  const [multipleInputValue, setMultipleInputValue] = useState([]);

  const inputRef = useRef(null);
  const focusedCell = api.getFocusedCell();
  const autoFocus = label && label === focusedCell?.column.userProvidedColDef.headerName;

  useEffect(() => {
    if (autoFocus) inputRef.current?.focus();
  }, [autoFocus]);

  const onUpdateFieldValue = (event) => {
    const {value} = event.target;
    setValue('fr-' + value);
    setInputValue(value);
  }

  const onUpdateMultipleFieldValue = (event) => {
    const {value} = event.target;
    setValue('fr-multi-dropdown-' + value);
    setMultipleInputValue(value);
  }

  if (value?.toString().startsWith('fr-dropdown-')) {
    return (
      <Select sx={{height: 35, minWidth: 120}}
        value={inputValue}
        onChange={onUpdateFieldValue}
        className={className}
      >
        {dropdownValues.map((dv) =>
          <MenuItem key={dv.value} value={`dropdown-${dv.value}`}>{dv.text}</MenuItem>
        )}
      </Select>
    )
  } else if (value?.toString().startsWith('fr-multi-dropdown-')) {
    return (
      <Select
        multiple
        sx={{height: 35, minWidth: 120}}
        value={multipleInputValue}
        onChange={onUpdateMultipleFieldValue}
        className={className}
      >
        {dropdownValues.map((dv) =>
          <MenuItem key={dv.value} value={`multi-dropdown-${dv.value}`}>{dv.text}</MenuItem>
        )}
      </Select>
    )
  } else if (value?.toString().startsWith('fr-')) {
    return (
      <TableInput inputProps={{ autoComplete: 'new-password' }} placeholder={label} value={inputValue} onChange={onUpdateFieldValue} inputRef={inputRef} />
    )
  } else if ((value === "INTERNAL" || value === "EXTERNAL") && rest.colDef.field === "type") {
    return (
      <Tooltip
        title={
          <div style={{display: "flex", flexDirection: "column"}}>
            {teamTypes[value].map((val) => <span key={val}>{val}</span>)}
          </div>
        }
        placement='top'
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -20],
              },
            },
          ],
        }}
      >
        <span>{value}</span>
      </Tooltip>
    )
  } else if ((value === "MEMBER" || value === "MANAGER") && rest.colDef.field === "type") {
    return (
      <Tooltip
        title={
          <div style={{display: "flex", flexDirection: "column"}}>
            <span>PERMISSIONS: </span>
            {memberTypes[value][rest.data.teamType].map((val) => <span key={val}>{val}</span>)}
          </div>
        }
        placement='top'
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -20],
              },
            },
          ],
        }}
      >
        <span>{value}</span>
      </Tooltip>
    )
  } else if (rest.colDef.field === "states") {
    return <LawfirmStates value={value} {...rest} />
  }
  
  return value;
}

export default CellWrapper;