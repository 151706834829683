import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import { useTheme } from '@emotion/react';
import { MessageContext } from '../../context/MessageContext';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

const ReportContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ReportItem = styled(Button)`
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.themeColor.sectionStroke};
  box-shadow: none !important;
  background: inherit !important;
  color: ${({ theme }) => theme.themeColor.bodyMain};

  &:hover {
    background: ${({ theme }) => theme.themeColor.sectionFill} !important;
    box-shadow: 1px 1px 5px ${({ theme }) => theme.themeColor.sectionStroke} !important;
  };
`;

const GENERATE_HOLDS_REPORT = gql`
  mutation createHoldsReport {
    createHoldsReport {
      reportLink
    }
  }
`;
const GENERATE_TIMELINE_PERFORMANCE_REPORT = gql`
  mutation createTimelinePerformanceReport {
    createTimelinePerformanceReport {
      reportLink
    }
  }
`;
const GENERATE_HISTORICAL_TIMELINE_REPORT = gql`
  mutation createHistoricalTimelinesReport {
    createHistoricalTimelinesReport {
      reportLink
    }
  }
`;
const CREATE_NOTIFICATION = gql`
  mutation createNotification($input: createNotificationInput!) {
    createNotification(input: $input) {
      message
      type
      redirectUrl
    }
  }
`;


const ReportToolPanel = () => {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const [createHoldsReport, {
    loading: holdsReportLoading,
  }] = useMutation(GENERATE_HOLDS_REPORT);

  const [createTimelinePerformanceReport, {
    loading: timelinePerformanceLoading,
  }] = useMutation(GENERATE_TIMELINE_PERFORMANCE_REPORT);

  const [createHistoricalTimelineReport, {
    loading: historicalTimelineLoading,
  }] = useMutation(GENERATE_HISTORICAL_TIMELINE_REPORT);

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    refetchQueries: ['getNotifications'],
    awaitRefetchQueries: true,
  });

  const generateHoldsReport = async () => {
    await addMessage({ message: 'Generating Holds Report', severity: 'info' });

    await createNotification({
      variables: {
        input: {
          message: `Holds Report Requested`,
          type: 'Info',
        }
      }
    })
    
    const holdReport = await createHoldsReport();

    if (holdReport.data.createHoldsReport?.reportLink) {
      await createNotification({
        variables: {
          input: {
            message: `Hold Report Generated. Click this message to download.`,
            type: 'Report',
            redirectUrl: holdReport.data.createHoldsReport.reportLink,
          }
        }
      });
      await addMessage({ message: 'Holds Report Generated', severity: 'success' });      
    } else {
      const message = holdReport?.errors[0]?.message || 'Error generating report';
      await addMessage({ message: message, severity: 'error' });
    }
  };

  const generateTimelinePerformance = async () => {
    await addMessage({ message: 'Generating Timeline Performance Report', severity: 'info' });

    await createNotification({
      variables: {
        input: {
          message: `Timeline Performance Report requested`,
          type: 'Info',
        }
      }
    })

    const timelinePerf = await createTimelinePerformanceReport();
    if (timelinePerf.data.createTimelinePerformanceReport?.reportLink) {
      await createNotification({
        variables: {
          input: {
            message: `Timeline Performance Report Generated. Click this message to download.`,
            type: 'Report',
            redirectUrl: timelinePerf.data.createTimelinePerformanceReport.reportLink,
          }
        }
      });
      await addMessage({ message: 'Timeline Performance Report Generated', severity: 'success' });
    } else {
      const message = timelinePerf?.errors[0]?.message || 'Error generating report';
      await addMessage({ message: message, severity: 'error' });
    }
  };

  const generateHistoricalTimeline = async () => {
    await addMessage({ message: 'Generating Historical Timeline Report', severity: 'info' });

    await createNotification({
      variables: {
        input: {
          message: `Historical Timeline Report requested`,
          type: 'Info',
        }
      }
    })

    const historicalTimeline = await createHistoricalTimelineReport();
    if (historicalTimeline.data.createHistoricalTimelinesReport?.reportLink) {
      await createNotification({
        variables: {
          input: {
            message: `Historical Timeline Report Generated. Click this message to download.`,
            type: 'Report',
            redirectUrl: historicalTimeline.data.createHistoricalTimelinesReport.reportLink,
          }
        }
      });
      await addMessage({ message: 'Historical Timeline Report Generated', severity: 'success' });
    } else {
      const message = historicalTimeline?.errors[0]?.message || 'Error generating report';
      await addMessage({ message: message, severity: 'error' });
    }
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '10px',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <Typography variant="h6" mb={2} mt={2}>Generate a Report</Typography>
      <Typography variant="body1">
        * Reports are generated from all assets in your portfolio and will be sent to your notifications upon completion
      </Typography>

      <ReportContainer>
        <ReportItem
          theme={theme}
          secondary={true}
          onClick={generateHoldsReport}
          disabled={holdsReportLoading}
        >
          Holds Report
        </ReportItem>
        <ReportItem
          theme={theme}
          secondary={true}
          onClick={generateTimelinePerformance}
          disabled={timelinePerformanceLoading}
        >
          Timeline Performance Report
        </ReportItem>
        <ReportItem
          theme={theme}
          secondary={true}
          onClick={generateHistoricalTimeline}
          disabled={historicalTimelineLoading}
        >
          Historical Timeline Report
        </ReportItem>
      </ReportContainer>
    </Box>
  )
};

export default ReportToolPanel;