import { gql } from "@apollo/client";

export const TEAM_ASSETS_QUERY = gql`
  query Query($teamID: ID!) {
    getTeamAssets(teamID: $teamID) {
      id
      name

      portfolio {
        id
        name
        deleted
      }

      assets {
        id
        loanNumber
        state
        statusValue
        upb
        assetAddress
        borrowerFirstName
        borrowerLastName
        servicer
        propertyID
        activeProcess {
          id
          startDate
          closeDate
          processSteps
          stepID
          tldr

          referral {
            id
            seenByCreator
            referralStatus
            deleted
            team {
              id
              name
            }
          }

          tasks {
            id
            createdAt
            completedAt
            deleted
            task_comments {
              id
              comment
              description
              createdAt
              createdBy {
                firstName
                lastName
              }
            }
          }

          task_flows {
            id
            startDate
            completedAt
            deleted
            task_comments {
              id
              description
              createdAt
              createdBy {
                firstName
                lastName
              }
            }

            subtasks {
              id
              completedAt
              task_comments {
                id
                comment
                description
                createdAt
                createdBy {
                  firstName
                  lastName
                }
              }
            }
          }
          
          comments {
            id
            createdDate
            description
            deleted
          }

          holds {
            id
            holdType
            holdDescription
            holdComment
            active
            deleted
            hold_comments {
              id
              description
              createdAt
              comment
              createdBy {
                firstName
                lastName
              }
            }
          }
        }

        loan_status {
          totalDaysInFc
          totalDaysInBk
          stepDaysOnHold
          stepBudgetDays
          totalDaysOverdue
          netVsBudget
          daysInDlq
          budgetDaysToSale
          stepNetDaysOverdue
          stepGrossDaysOverdue
          grossVsBudget
        }

        activities {
          id
          comments {
            id
            description
            createdDate
            createdBy {
              firstName
              lastName
            }
          }
          tasks {
            id
            createdAt
            completedAt
            deleted
            task_comments {
              id
              comment
              description
              createdAt
              createdBy {
                firstName
                lastName
              }
            }
          }
          task_flows {
            id
            startDate
            completedAt
            deleted
            subtasks {
              id
              completedAt
              task_comments {
                id
                comment
                description
                createdAt
                createdBy {
                  firstName
                  lastName
                }
              }
            }
            task_comments {
              id
              description
              createdAt
              comment
              createdBy {
                firstName
                lastName
              }
            }
          }
        }

        processes {
          id
          startDate
          closeDate
          processSteps
          stepID
          tldr

          tasks {
            id
            createdAt
            completedAt
            deleted
            task_comments {
              id
              comment
              description
              createdAt
              createdBy {
                firstName
                lastName
              }
            }
          }

          task_flows {
            id
            startDate
            completedAt
            deleted
            task_comments {
              id
              description
              createdAt
              createdBy {
                firstName
                lastName
              }
            }

            subtasks {
              id
              completedAt
              task_comments {
                id
                comment
                description
                createdAt
                createdBy {
                  firstName
                  lastName
                }
              }
            }
          }
          
          comments {
            id
            createdDate
            description
            deleted
          }

          holds {
            id
            holdType
            holdDescription
            holdComment
            active
            deleted
            hold_comments {
              id
              description
              createdAt
              comment
              createdBy {
                firstName
                lastName
              }
            }
          }
        }        
      }
    }
  }
`;