import React, { useState, useContext, useEffect } from "react";
import { Box, Container, FormControl, Select, MenuItem, TextareaAutosize, Autocomplete } from "@mui/material";
import Checkbox from "../../styled/Checkbox/Checkbox";
import Button from "../../styled/Button/Button";
import Modal from "../../styled/Modal/Modal";
import DatePickerSelect from "../DatePickerSelect/DatePickerSelect";
import { MessageContext } from "../../context/MessageContext";
import { MilestoneTokenContext } from "../../context/MilestoneTokenContext";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "@apollo/client";
import { HOLDS_QUERY } from "../../apollo/queries/holdsQuery";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { CREATE_PROCESS } from "../../apollo/mutations/createProcess";
import { CREATE_HOLD_MUTATION, CREATE_EVENT_MUTATION } from "../../apollo/mutations/createHoldAndEventsMutation";
import { START_REFERRAL_PROCESS_MUTATION } from "../../apollo/mutations/startReferralProcessMutation";
import { GET_TEAMS_BY_OWNER } from "../../apollo/queries/getTeamsByOwner";
import { TextInput } from "../../styled/TextInput/TextInput";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { UserContext } from "../../context/UserContext";
import { isBankruptcy } from "../../utilities";
import styled from "@emotion/styled";
const bkSteps = require('../../utilities/bkSteps.json');

const initialHoldTypes = [
  { id: 1, value: "BK" },
  { id: 2, value: "Collateral" },
  { id: 3, value: "Court Delay" },
  { id: 4, value: "FC Process" },
  { id: 5, value: "Litigation" },
  { id: 6, value: "Loss Mitigation" },
  { id: 7, value: "Mediation" },
  { id: 8, value: "Other" },
  { id: 9, value: "Probate" },
  { id: 10, value: "Servicer" },
  { id: 11, value: "Statute of Limitations" },
  { id: 12, value: "Title" },
];

const INITIAL_ERROR_STATE = {
  description: false,
  startDate: false,
  endDate: false,
  holdType: false,
};
const StyledInput = styled(TextInput)`
  margin-top: 25px;
`

export default function OpenHold({ show, onClose, process, milestoneID, asset, setScreen, portfolioID, activeProcess, setActiveProcess, setAsset, isProperty }) {
  const theme = useTheme();
  const { id: processID } = process
  const { user } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const { milestoneToken } = useContext(MilestoneTokenContext);

  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [holdType, setHoldType] = useState(null);
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [bkType, setBkType] = useState("chapter13");
  const [holdTypes, setHoldTypes] = useState(initialHoldTypes);
  const [newReferral, setNewReferral] = useState(false);
  const [allFirms, setAllFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState();
  const [comment, setComment] = useState("Opened a BK Hold during Foreclosure");

  useEffect(() => {
    // Removes 'BK' Hold if the process is a BK or if asset is a property
    if (isBankruptcy(activeProcess)) {
      const copy = [...holdTypes];
      copy.shift();
      setHoldTypes(copy);
    } else if (!isBankruptcy(activeProcess) && holdTypes.length === 12 && milestoneToken) {
      const copy = [...holdTypes];
      copy.shift();
      setHoldTypes(copy);
    } else {
      setHoldTypes(initialHoldTypes);
    }
  }, [milestoneToken])


  const { data: firmsData } = useQuery(GET_TEAMS_BY_OWNER, {
    variables: { portfolioManager: user.userID },
  });

  useEffect(() => {
    if (firmsData?.findTeamsByOwner) {
      const onlyLawfirms = firmsData.findTeamsByOwner.filter((team) => team.type === "LAWFIRM");
      setAllFirms(onlyLawfirms);
      setSelectedFirm(activeProcess?.referral?.team);
    }
  }, [firmsData])

  const [createHold, response] = useMutation(CREATE_HOLD_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID: asset?.id, userID: user.userID } }
    ],
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: HOLDS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ],
  });

  const [createProcess] = useMutation(CREATE_PROCESS);

  const [startReferral] = useMutation(START_REFERRAL_PROCESS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });

  async function handleSubmit(event) {
    event.preventDefault();

    // TODO: RENAME FIELDS IN DATASTRUCTURE TO MAKE MORE SENSE IN NEXT PR
    if (!formValidation()) {
      if (holdType.value === "BK" && !openConfirmation) {
        setOpenConfirmation(true);
        return;
      }

      const hold = {
        holdType: holdType.value,
        holdDescription: description,
        startDate,
        expectedCloseDate: endDate,
        process: processID,
        createdInMilestone: milestoneID,
        active: true,
      };

      try {
        const newHold = await createHold({
          variables: {
            hold,
          }
        });
        
        if (newHold && !("errors" in newHold)) {
          const event = {
            type: "hold-created",
            holdType: holdType.value,
            description,
            dueBy: endDate,
            createdDate: startDate,
            milestoneID,
            process: processID,
            hold: newHold.data.createHold.id,
          }
          
          if (openConfirmation) {
            event.holdType = "A Bankruptcy Process has started on another tab."
          }

          await createEvent({
            variables: {
              event
            }
          });
        }

        if (openConfirmation) {
          const newProcess = await createProcess({
            variables: {
              input: {
                asset: asset.id,
                stepID: "1.00",
                processType: bkType,
                processSteps: bkType === "chapter7" ? [...bkSteps.chapter7.steps] : [...bkSteps.chapter13and11.steps],
                startDate: new Date(),
                startStepID: "1.00",
              }
            }
          })

          const data = {
            assetID: asset.id,
            portfolioID,
            portfolio: portfolioID,
            referredAsset: asset.id,
            loanNumber: asset.loanNumber,
            state: asset.state,
            referralStartDate: new Date(),
            referralStatus: "PENDING",
            referredBy: user.id,
            teamID: selectedFirm.id,
            comment,
            statusValue: "1.00",
            referredProcess: newProcess.data.createProcess.id,
          }

          if (asset.propertyID) data.propertyID = asset.propertyID;

          const refResp = await startReferral({
            variables: {
              input: data,
            },
          })
          const newActiveProcess = newProcess.data.createProcess;
          newActiveProcess.referral = refResp.data.createReferral;

          setActiveProcess(newActiveProcess);
          setAsset((prevState) => ({ ...prevState, activeProcess: newActiveProcess }))
          setScreen && setScreen(3)
        }
      } catch (error) {
        console.log(error);
        addMessage({ message: "Unable to create hold. Please try again or come back later.", type: "error" })
      }
      setError(INITIAL_ERROR_STATE);
      setErrorMessage(null);
      setDescription("");
      setHoldType(null);
      setStartDate(null);
      setEndDate(null);
      onClose();
      setOpenConfirmation(false);
    } else {
      setErrorMessage("Please complete all necessary fields.");
    }
  }

  function formValidation() {
    let hasError = false;

    if (description.length === 0) {
      setError((prevState) => ({ ...prevState, description: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, description: false }));
    }

    if (holdType === null) {
      setError((prevState) => ({ ...prevState, holdType: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, holdType: false }));
    }

    if (startDate === null) {
      setError((prevState) => ({ ...prevState, startDate: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, startDate: false }));
    }

    if (endDate === null) {
      setError((prevState) => ({ ...prevState, endDate: true }));
      hasError = true;
    } else {
      setError((prevState) => ({ ...prevState, endDate: false }));
    }

    return hasError;
  }

  const handleFirmChange = (_event, newValue) => {
    _event.preventDefault();
    setSelectedFirm(newValue);
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  }

  const handleClose = () => {
    setDescription("");
    setHoldType(null);
    setStartDate(new Date());
    setEndDate(null);
    setOpenConfirmation(false);
    onClose();
  }

  const isBKButtonDisabled = (response) => {
    if (response.loading)
      return true;
    else if (response?.data?.createHold && response.data.createHold.holdType === "BK")
      return true;

    if (!selectedFirm && newReferral)
      return true;
    return false;
  }

  if (response.error) <p>{response.error}</p>;

  return (
    <Modal open={show} onClose={handleClose}>
      <Container sx={{marginBottom: '30px'}}>
        <Typography component="h1" variant="h4">New Hold</Typography>
      </Container>

      <Container sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <FormControl fullWidth mb={2}>
          <label>Hold Type</label>
          <Select
            id="hold-type-dropdown"
            value={holdType && holdType.value}
            label=""
            onChange={(_event, hold) => {
              const { props } = hold;
              setHoldType({
                id: props.id,
                value: props.value,
              })
            }}
          >
            {holdTypes &&
              holdTypes.map((hold) => {
                return (
                  <MenuItem
                    {...hold}
                    key={hold.id}
                    value={hold.value}
                    onSelect={(event) =>
                      setHoldType({
                        id: event,
                        value: hold.value,
                      })
                    }
                  >
                    {hold.value}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <label>Description</label>
          <TextareaAutosize
            minRows={5}
            name="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            style={{
              backgroundColor: theme.themeColor.backgroundBody,
              resize: 'none',
              color: theme.themeColor.bodyMain,
              // fontFamily: theme.themeFonts.main,
              fontFamily: 'inherit',
              fontSize: '1.25em',
              borderRadius: theme.themeSizing.borderRadiusSmall
            }}
          />
        </FormControl>

        <FormControl fullWidth>
          <label>Start Date:</label>
          <DatePickerSelect
            placeholder="Choose a start date"
            selected={startDate}
            setSelected={setStartDate}
            error={error.endDate}
            minDate={new Date()}
          />
        </FormControl>

        <FormControl fullWidth>
          <label>Expected End Date:</label>
          <DatePickerSelect
            placeholder="Choose an end date"
            selected={endDate}
            setSelected={setEndDate}
            error={error.endDate}
            minDate={new Date()}
          />
        </FormControl>

        <div>{errorMessage && <ErrorMessage error={errorMessage} />}</div>
        <Box mt={2}>
          <Button id="submit-hold" onClick={handleSubmit} sx={{width: '150px', marginRight: '15px'}}>Add</Button>
          <Button onClick={onClose} variant="secondary" sx={{width: 'auto'}}>Cancel</Button>
        </Box>
      </Container>

      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <Typography mb={2}>
          Opening this hold will pause the current Foreclosure process and start a new Bankruptcy process.
          Would you like to continue?
        </Typography>

        {isProperty && <Typography mb={2}><strong>This BK will be open to ALL properties related to this loan. The selected law firm will be applied to all properties.</strong></Typography>}

        <FormControl sx={{display: "flex", }}>
          <label>Select BK Type: </label>
          <Select
            id="bk-type-dropdown"
            value={bkType}
            label=""
            onChange={(_event, type) => {
              const { props } = type;
              setBkType(props.value)
            }}>
            <MenuItem value="chapter7">Chapter 7</MenuItem>
            <MenuItem value="chapter11">Chapter 11</MenuItem>
            <MenuItem value="chapter13">Chapter 13</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "space-around"}}>
          <Checkbox
            onClick={() => setNewReferral(true)}
            checked={newReferral}
            label="Refer to Another Law Firm" />
          <Checkbox
            onClick={() => setNewReferral(false)}
            checked={!newReferral}
            label="Continue with Same Law Firm" />
        </Box>
        {newReferral && 
          <>
            <Autocomplete
              className="select-lawfirm-dropdown"
              popupIcon={""}
              sx={{ width: 'auto', height: '80%', marginTop: "10px" }}
              size="small"
              options={allFirms}
              disablePortal
              getOptionLabel={option => `${option?.name}`}
              onChange={handleFirmChange}
              value={selectedFirm}
              renderInput={(params) => <TextInput {...params}
                size="small"
                InputProps={{
                  ...params.InputProps,
                }}
                label="Select Law Firm"
              />}
            />
            <StyledInput fullWidth className="asset-referral-comment" label="Comment" onChange={handleCommentChange}/>
          </>
        }
        <Box mt={3}>
          <Button
            id="confirm-BK"
            loading={response.loading}
            disabled={isBKButtonDisabled(response)}
            onClick={handleSubmit}
            sx={{width: '200px', marginRight: '25px'}}
          >
            Continue
          </Button>
          <Button onClick={onClose} variant="secondary" sx={{width: 'auto'}}>Cancel</Button>
        </Box>
      </Modal>
    </Modal>
  )
}