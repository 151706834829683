import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { TextField, InputAdornment } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker-select.scss";
import { FaCaretDown } from "react-icons/fa";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const StyledDatePicker = styled(TextField)`
  .MuiOutlinedInput-input {
    cursor: pointer;
  }  

  .MuiInputBase-root {
    cursor: pointer;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;
`;

export default function DatePickerSelect(props) {
  const theme = useTheme();

  const {
    placeholder,
    selected,
    setSelected,
    minDate,
    error,
  } = props;
  
  const DropdownButton = forwardRef(({ value, onClick }) => (
    <StyledDatePicker
      {...props}
      error={error}
      onClick={onClick}
      value={value || placeholder} 
      readOnly 
      InputProps={{
        endAdornment: <StyledInputAdornment position="end"><FaCaretDown color={theme.themeColor.bodySecondary} /></StyledInputAdornment>,
      }}
    />
  ));

  DropdownButton.displayName = 'DropdownButton'

  return (
    <DatePicker
      {...props}
      className="react-date-picker-popper"
      selected={selected}
      onChange={(date) => setSelected(date)}
      customInput={<DropdownButton />}
      minDate={minDate}
    />
  );
}
