import { useTheme } from "@emotion/react";
import { rgba } from "emotion-rgba";
import React, { useContext } from "react"
import Highlighter from "react-highlight-words";
import { getMentionsFromComment, replaceMentionsWithNames } from "./utils";
import { UserContext } from "../../context/UserContext";
import { Box, CircularProgress, Popper } from "@mui/material";

const MentionDetail = ({ show, anchorEl }) => {
  return (
    <Popper open={show} anchorEl={anchorEl} sx={{zIndex: 999}}>
      <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
        <CircularProgress />
      </Box>
    </Popper>
  );
}

const Tag = (props) => {
  const theme = useTheme();

  const { user } = useContext(UserContext);
  const userName = `${user.firstName} ${user.lastName}`;
  
  const { children } = props;
  const isFromUser = children === userName;
  
  return (
    <>
      <span
        style={{
          backgroundColor: isFromUser ? rgba(theme.themeColor.brandPrimaryOrange, 0.05) : rgba(theme.themeColor.brandPrimaryBlue, 0.05),
          cursor: 'pointer',
        }}
      >
        @{children}
      </span>
      <MentionDetail />
    </>
  )
}

const MentionHighlighter = ({ children }) => {
  const mentions = getMentionsFromComment(children);
  const comment = replaceMentionsWithNames(children, mentions);

  return (
    <Highlighter
      searchWords={mentions}
      textToHighlight={comment}
      highlightTag={Tag}
    />
  )}

export default MentionHighlighter;