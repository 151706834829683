import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { UPDATE_TASK_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateTaskAndLog";
import { MessageContext } from '../../context/MessageContext';
import { Box, AccordionDetails, Collapse, Stack } from "@mui/material";
import Checkbox from "../../styled/Checkbox/Checkbox";
import CommentIcon from "../CommentIcon/CommentIcon";
import TaskComments from "../TaskComments/TaskComments";
import { UserContext } from "../../context/UserContext";
import { AssetContext } from "../../context/AssetContext";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import { ACTIVITY_TASKS_QUERY } from "../../apollo/queries/activityTasksQuery";
import { useTheme } from "@emotion/react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { UPDATE_SUBTASK_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateSubtaskAndEventsMutation";
import { UPDATE_SUBTASK_MUTATION } from "../../apollo/mutations/updateSubtask";
import { useEffect } from "react";
import { UPDATE_TASK_FLOW_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateTaskFlowAndEventsMutation";
import { rgba } from "emotion-rgba";
import AssingmentsBadge from '../AssignmentsBadge/AssignmentsBadge';
import { GET_ACTIVITY_EVENTS } from "../../apollo/queries/getActivityEvents";
import { ACTIVITY_TASK_FLOWS_QUERY } from "../../apollo/queries/activityTaskFlowsQuery";
import { END_CURRENT_PROCESS } from "../../apollo/mutations/endCurrentProcess";
import FinalBillConfirmation from "./FinalBillConfirmation";

export const MilestoneTask = ({
  id,
  title,
  description,
  completedAt,
  assignments,
  milestoneID,
  deadlineAt,
  dueByMilestone,
  process,
  task_comments,
  createdAt,
  assetID,
  blocking,
  forms,
  className,
  readOnly,
  taskActivity
}) => {
  const theme = useTheme();
  const processID = process?.id;
  const { addMessage } = useContext(MessageContext)
  const { user } = useContext(UserContext);

  const [checked, setChecked] = useState(!!completedAt);
  const [showTaskComments, setShowTaskComments] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [validForms, setValidForms] = useState([]);

  const taskRefetch = (taskActivity?.id || !processID)
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: taskActivity.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: taskActivity.id } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ];

  const [updateTask] = useMutation(UPDATE_TASK_AND_EVENTS_MUTATION, {
    refetchQueries: taskRefetch
  });

  useEffect(() => {
    const emptyValues = [];
    if (forms?.length) {
      forms.forEach((form) => {
        const hasSelect = form.form_fields.find((field) => field.type === 'select');
        if (hasSelect) {
          const validFields = form.form_fields.filter((item) => {
            return item.condition === null || item.condition.includes(hasSelect.value);
          });
          setValidForms([{...form, form_fields: validFields}])
        } else {
          setValidForms([ form ]) 
        }

        const hasSomeEmptyValue = form.form_fields.find((field) => {
          if (field.required && !field.condition) {

            return (!field.value || field.value === '');
          } else if (field.required && field.condition) {
            // Needs to check the condition of the field
            const conditionsParts = field.condition.split(' = ');
            const condField = conditionsParts[0];
            const condValue = conditionsParts[1];

            const formField = form.form_fields.find((field) => field.label === condField);
            if (formField?.value === condValue) {
              // If condition is true, then we must check if the value is setted
              return (!field.value || field.value === '')
            }
          }

          return false;
        });

        if (hasSomeEmptyValue) emptyValues.push(hasSomeEmptyValue);
      });
    }

    setIsFormFilled(!(emptyValues.length > 0));
  }, [forms]);

  const comments = task_comments ? task_comments.length : 0;

  async function handleTaskUpdate() {
    if (!checked && !isFormFilled) {
      addMessage({ type: 'error', message: 'You need to first fill all forms to close this task.'});
      return;
    }

    setChecked((prevState) => !prevState)
    if (!checked) {
      const task = {
        completedAt: new Date(),
      };
      const event = {
        type: "task-complete",
        createdFor: assignments[0].id,
        completedDate: new Date(),
        description: title,
      }
      if (taskActivity) {
        event.activity = taskActivity.id
      } else {
        event.milestoneID = milestoneID
        event.process = processID
      }

      // Add form values to event description
      if (forms?.length) {
        let taskDescription = `${title}: `;
        forms.forEach((form) => {
          taskDescription = taskDescription.concat(form.form_fields.map((field) => `${field.label}: ${field.value}`).join(', '));
        });

        event.description = taskDescription;
      }

      try {
        await updateTask({
          variables: {
            task: { where: { id: id }, data: task },
            event,
          },
        });
      } catch (error) {
        addMessage({ message: 'Unable to close current task. Please refresh and try again.', type: 'error' })
      }
    } else {
      const task = {
        completedAt: null,
      }

      const event = {
        type: 'task-reopened',
        description: title,
        createdFor: assignments[0].id,
        milestoneID,
        process: processID,
        createdDate: new Date(),
        dueByMilestone,
        dueBy: new Date(+deadlineAt).toISOString()
      }

      try {
        await updateTask({
          variables: {
            task: { where: { id: id }, data: task },
            event,
          },
        });
      } catch (error) {
        addMessage({ message: 'Unable to reopen current task. Please refresh and try again.', type: 'error' })
      }

    }
  }

  const disableClose = (e) => e.stopPropagation();

  return (
    <AccordionDetails
      onClick={disableClose}
      className={className}
      sx={{ 
        display: 'flex',
        justifyContent:' space-between',
        backgroundColor: (blocking && !completedAt)&& rgba(theme.themeColor.brandPrimaryRed, .33),
        borderRadius: blocking && '4px'
      }}
    >
      <div>
        <Checkbox
          className={`${className}-checkbox`}
          size="medium"
          type="checkbox"
          onChange={handleTaskUpdate}
          checked={checked}
          disabled={readOnly}
        />

        <span onClick={() => setShowTaskComments(true)} style={{
          cursor: 'pointer',
          color: theme.themeColor.bodyMain,
          textDecoration: completedAt ? 
            `line-through solid 1px ${theme.themeColor.brandPrimaryBlue}`
            : 'none',
        }}>
          {title}
        </span>
        {forms && forms.length > 0 && (
          <div>
            {validForms.map((form) => 
              <Stack key={form.id} spacing={0.5} ml={4} fontSize={12}>
                {form.form_fields && form.form_fields.map((formValue) => 
                  <span key={formValue.label}>
                    {formValue.value && (
                      <span>{formValue.label}: {formValue.value}</span>
                    )}
                  </span>
                )}
              </Stack>
            )}
          </div>
        )}
      </div>
        
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <>
          {
            assignments.length > 0 && (
              <AssingmentsBadge assignments={assignments} />
            )
          }
          <CommentIcon className={`${className}-comment`} onClick={() => setShowTaskComments(true)} value={comments} />
          <TaskComments
            open={showTaskComments}
            comments={task_comments}
            taskName={title}
            onClose={() => setShowTaskComments(false)}
            milestoneID={milestoneID}
            process={process}
            taskId={id}
            taskDescription={description}
            assignments={assignments}
            dueByMilestone={dueByMilestone}
            deadlineAt={deadlineAt || ""}
            createdAt={createdAt}
            assetID={assetID}
            blocking={blocking}
            forms={forms}
            readOnly={readOnly}
            taskActivity={taskActivity}
          />
        </>
      </div>
    </AccordionDetails>
  )
}

const MilestoneSubtask = ({ 
  subtask,
  milestoneID,
  process,
  dueByMilestone,
  deadlineAt,
  assetID,
  user,
  isCompleted,
  forms,
  className,
  readOnly,
  taskActivity
}) => {
  const [checked, setChecked] = useState(false);
  const [showTaskComments, setShowTaskComments] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const comments = subtask.task_comments?.length || 0;
  const processID = process?.id;

  useEffect(() => {
    if (subtask.completedAt) setChecked(true);
  }, [subtask]);

  useEffect(() => {
    const emptyValues = [];
    if (forms?.length) {
      forms.forEach((form) => {
        const hasSomeEmptyValue = form.form_fields.find((field) => {
          if (field.required && !field.condition) {
            return (!field.value || field.value === '');
          } else if (field.required && field.condition) {
            // Needs to check the condition of the field
            const conditionsParts = field.condition.split(' = ');
            const condField = conditionsParts[0];
            const condValue = conditionsParts[1];

            const formField = form.form_fields.find((field) => field.label === condField);
            if (formField?.value === condValue) {
              // If condition is true, then we must check if the value is setted
              return (!field.value || field.value === '')
            }
          }

          return false;
        })
        if (hasSomeEmptyValue) emptyValues.push(hasSomeEmptyValue);
      });
    }

    setIsFormFilled(!(emptyValues.length > 0));
  }, [forms]);

  const { addMessage } = useContext(MessageContext)
  const theme = useTheme();

  const refetch = (taskActivity?.id && !processID)
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: taskActivity.id } },
      { query: ACTIVITY_TASK_FLOWS_QUERY, variables: { activityID: taskActivity.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: taskActivity.id } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ];

  const [updateSubtask] = useMutation(UPDATE_SUBTASK_AND_EVENTS_MUTATION, {
    refetchQueries: refetch
  });

  const onCheckSubtask = async () => {
    if (!checked && !isFormFilled) {
      addMessage({ type: 'error', message: 'You need to first fill all forms to close this task.'});
      return;
    }
    
    if (!checked) {
      const taskFlow = {
        completedAt: new Date(),
      };
      const event = {
        type: "task-complete",
        description: `Subtask ${subtask.name}`,
        createdFor: user.id,
        completedDate: new Date(),
      }

      if (taskActivity) event.activity = taskActivity.id;
      else {
        event.milestoneID = milestoneID
        event.process = processID
      }

      // Add form values to event description
      if (forms?.length) {
        let taskDescription = `${subtask.name}: `;
        forms.forEach((form) => {
          taskDescription = taskDescription.concat(form.form_fields.map((field) => `${field.label}: ${field.value}`).join(', '));
        });

        event.description = taskDescription;
      }

      try {
        await updateSubtask({
          variables: {
            subtask: { where: { id: subtask.id }, data: taskFlow },
            event,
          },
        });
      } catch (error) {
        addMessage({ message: 'Unable to close current subtask. Please refresh and try again.', type: 'error' })
      }
    } else {
      const task = {
        completedAt: null,
      }

      const event = {
        type: 'task-reopened',
        description: `Subtask ${subtask.name}`,
        createdFor: user.id,
        createdDate: new Date(),
      }

      if (taskActivity) event.activity = taskActivity.id;
      else {
        event.milestoneID = milestoneID
        event.process = processID
        event.dueByMilestone = dueByMilestone
      }

      try {
        await updateSubtask({
          variables: {
            subtask: { where: { id: subtask.id }, data: task },
            event,
          },
        });
      } catch (error) {
        addMessage({ message: 'Unable to reopen current task. Please refresh and try again.', type: 'error' })
      }

    }
    setChecked(!checked);
  }
  
  return (
    <Box 
      sx={{ 
        display: 'flex',
        justifyContent:' space-between',
        width: '95%'
      }}
    >
      <div>
        <Checkbox
          className={`${className}-checkbox`}
          size="medium"
          type="checkbox"
          onChange={onCheckSubtask}
          checked={checked}
          disabled={isCompleted || readOnly}
        />

        <span style={{
          cursor: 'pointer',
          color: theme.themeColor.bodyMain,
          textDecoration: subtask.completedAt ? 
            `line-through solid 1px ${theme.themeColor.brandPrimaryBlue}`
            : 'none',
        }}>
          {subtask.name}
        </span>
        {forms && forms.length > 0 && (
          <div>
            {forms.map((form) => (
              <Stack key={form.id} spacing={0.5} ml={4} fontSize={12}>
                {form.form_fields && form.form_fields.map((formValue) => (
                  <span key={formValue.label}>{formValue.value && (
                    <span>{formValue.label}: {formValue.value}</span>
                  )}
                  </span>
                ))}
              </Stack>
            ))}
          </div>
        )}
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {
          subtask.assignments.length > 0 && (
            <AssingmentsBadge assignments={subtask.assignments} />
          )
        }
        <CommentIcon className={`${className}-comment`} onClick={() => setShowTaskComments(true)} value={comments} />
        <TaskComments
          open={showTaskComments}
          comments={subtask.task_comments}
          taskName={subtask.name}
          onClose={() => setShowTaskComments(false)}
          milestoneID={milestoneID}
          process={process}
          taskId={subtask.id}
          taskDescription={''}
          assignments={subtask.assignments}
          dueByMilestone={dueByMilestone}
          deadlineAt={deadlineAt}
          createdAt={subtask.created_at}
          assetID={assetID}
          readOnly={readOnly}
          isSubtask
          forms={forms}
          taskActivity={taskActivity}
        />
      </div>
    </Box>
  )
}

export const MilestoneTaskFlow = ({ 
  id,
  name,
  completedAt,
  assignments,
  subtasks,
  milestoneID,
  process,
  milestone,
  dueDate,
  assetID,
  blocking,
  task_comments,
  createdAt,
  forms,
  className,
  readOnly,
  setAsset,
  setActiveProcess,
  setHasSentReferral,
  setScreen,
  taskActivity,
  activities,
  originalFC,
  client
}) => {
  const { user } = useContext(UserContext);
  const processID = process?.id;
  const { asset } = useContext(AssetContext);
  const [checked, setChecked] = useState(!!completedAt);
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [showTaskComments, setShowTaskComments] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [openFinalBill, setOpenFinalBill] = useState(false);

  const orderSubtasks = [...subtasks].sort((sA, sB) => sA.order - sB.order);
  const dueBy = new Date(+dueDate).toISOString();

  const refetch = taskActivity
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: taskActivity.id } },
      { query: ACTIVITY_TASK_FLOWS_QUERY, variables: { activityID: taskActivity.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: taskActivity.id } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
      { query: ASSET_QUERY, variables: { assetID, userID: user.userID } }
    ];

  const [updateTaskFlow] = useMutation(UPDATE_TASK_FLOW_AND_EVENTS_MUTATION);
  const [updateSubtask] = useMutation(UPDATE_SUBTASK_MUTATION);
  const [endProcess] = useMutation(END_CURRENT_PROCESS);

  useEffect(() => {
    const emptyValues = [];
    if (forms?.length) {
      forms.forEach((form) => {
        const hasSomeEmptyValue = form.form_fields.find((field) => {
          if (field.required && !field.condition) {
            return (!field.value || field.value === '');
          } else if (field.required && field.condition) {
            // Needs to check the condition of the field
            const conditionsParts = field.condition.split(' = ');
            const condField = conditionsParts[0];
            const condValue = conditionsParts[1];

            const formField = form.form_fields.find((field) => field.label === condField);
            if (formField?.value === condValue) {
              // If condition is true, then we must check if the value is setted
              return (!field.value || field.value === '')
            }
          }

          return false;
        })
        if (hasSomeEmptyValue) emptyValues.push(hasSomeEmptyValue);
      });
    }

    setIsFormFilled(!(emptyValues.length > 0));
  }, [forms]);

  const { addMessage } = useContext(MessageContext)
  const theme = useTheme();

  const disableClose = (e) => e.stopPropagation();

  const clickFinalBill = () => {
    if (user.userType !== 'PORTFOLIO_MANAGER') {
      addMessage({ type: 'error', message: 'Only Portfolio Managers can complete this task.' });
      return;
    }
    if (!subtasks[2].completedAt) {
      addMessage({ type: 'error', message: 'You must confirm that the final bill has been paid before closing this task.' });
      return;
    }

    setOpenFinalBill(true);
  };

  const completeFinalBill = async () => {
    const taskFlow = { completedAt: new Date() };
    const event = {
      type: "task-complete",
      description: `The Final Bill has been paid on ${new Date().toLocaleDateString()}`,
      createdFor: user.id,
      completedDate: new Date(),
      milestoneID, 
      process: processID
    }

    try {
      await updateTaskFlow({
        variables: {
          taskFlow: { where: { id: id }, data: taskFlow },
          event,
        },
      });
      const subtaskPromise = subtasks.map(async (subtask) => {
        if (!subtask.completedAt)
          return await updateSubtask({
            variables: {
              subtask: { where: { id: subtask.id }, data: taskFlow },
              event
            }
          })
      })

      await Promise.all(subtaskPromise);
      const originalHold = originalFC && originalFC.holds.filter((hold) => {
        return hold.holdType === "BK" && hold.active && !hold.closeDate
      })
      await endProcess({
        variables: {
          input: {
            assetID,
            processID: processID,
            closeDate: new Date(),
            originalFC: originalFC?.id,
            originalHold: originalHold?.length ? originalHold[0].id : null
          }
        },
        refetchQueries: refetch,
        awaitRefetchQueries: true
      });

      if (originalFC) {
        setActiveProcess(originalFC);
        setAsset((prev) => ({
          ...prev,
          activeProcess: originalFC
        }))
        setHasSentReferral(true);
        setScreen(asset.activities.length + 1);
        await client.refetchQueries({ include: [
          { query: EVENTS_QUERY, variables: { milestoneID: originalFC.stepID, processID: originalFC.id } },
        ] })
      } else {
        await setActiveProcess((prev) => ({
          ...prev,
          referral: { deleted: true },
          deleted: true,
        }))
        await setAsset((prev) => ({
          ...prev,
          activeProcess: {
            ...prev.activeProcess,
            deleted: true,
            referral: { deleted: true }
          },
          processes: prev.processes.map((process) => {
            if (process.id == processID) {
              return {
                ...process,
                deleted: true,
                referral: { deleted: true }
              }
            }
            return process;
          })
        }))
        await setHasSentReferral(false)
        await setScreen(activities.length + 1);
      }

    } catch(err) {
      addMessage({ message: 'Unable to close current task. Please refresh and try again.', type: 'error' })
    }
  };

  const onCheckTask = async () => {
    if (!checked && !isFormFilled) {
      addMessage({ type: 'error', message: 'You need to first fill all forms to close this task.'});
      return;
    }

    if (!checked && name.toUpperCase() === 'REQUEST FINAL BILL FROM ATTORNEY') {
      return await clickFinalBill();
    }

    if (!checked) {
      const taskFlow = {
        completedAt: new Date(),
      };
      const event = {
        type: "task-complete",
        description: `Task flow ${name}`,
        createdFor: user.id,
        completedDate: new Date(),
      }

      if (taskActivity) event.activity = taskActivity.id;
      else {
        event.milestoneID = milestoneID
        event.process = processID
      }

      // Add form values to event description
      if (forms?.length) {
        let taskDescription = `${name}: `;
        forms.forEach((form) => {
          taskDescription = taskDescription.concat(
            form.form_fields
              .filter((field) => field.value)
              .map((field) => `${field.label}: ${field.value}`).join(', ')
          );
        });

        event.description = taskDescription;
      }

      try {
        await updateTaskFlow({
          variables: {
            taskFlow: { where: { id: id }, data: taskFlow },
            event,
          },
          refetchQueries: refetch
        });
        const subtaskPromise = subtasks.map(async (subtask) => {
          if (!subtask.completedAt)
            return await updateSubtask({
              variables: {
                subtask: { where: { id: subtask.id }, data: taskFlow },
                event
              },
              refetchQueries: refetch
            })
        })

        await Promise.all(subtaskPromise);
      } catch (error) {
        addMessage({ message: 'Unable to close current subtask. Please refresh and try again.', type: 'error' })
      }
    } else {
      const task = {
        completedAt: null,
      }

      const event = {
        type: 'task-reopened',
        description: `Task flow ${name}`,
        createdDate: new Date(),
        dueByMilestone: milestone,
        dueBy
      }

      if (taskActivity) event.activity = taskActivity.id;
      else {
        event.milestoneID = milestoneID
        event.process = processID
      }

      try {
        await updateTaskFlow({
          variables: {
            taskFlow: { where: { id: id }, data: task },
            event,
          },
          refetchQueries: refetch
        });
      } catch (error) {
        addMessage({ message: 'Unable to reopen current task. Please refresh and try again.', type: 'error' })
      }

    }
    setChecked(!checked);
  }

  const comments = task_comments ? task_comments.length : 0;

  return (
    <div onClick={disableClose}>
      <AccordionDetails
        onClick={disableClose}
        sx={{ 
          display: 'flex',
          justifyContent:' space-between',
          backgroundColor: (blocking && !completedAt)&& rgba(theme.themeColor.brandPrimaryRed, .33),
          borderRadius: blocking && '4px'
        }}
      >
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => setShowSubtasks(!showSubtasks)}>
          <Checkbox
            className={`${className}-checkbox`}
            size="medium"
            type="checkbox"
            onChange={onCheckTask}
            checked={checked}
            disabled={readOnly}
          />

          <span style={{
            cursor: 'pointer',
            color: theme.themeColor.bodyMain,
            textDecoration: completedAt ? 
            `line-through solid 1px ${theme.themeColor.brandPrimaryBlue}`
              : 'none',
          }}>
            {name}
          </span>
          {forms && forms.length > 0 && (
            <div>
              {forms.map((form) => (
                <Stack key={form.id} spacing={0.5} ml={4} fontSize={12}>
                  {form.form_fields && form.form_fields.map((formValue) => (
                    <span key={formValue.label}>{formValue.value && (
                      <span>{formValue.label}: {formValue.value}</span>
                    )}
                    </span>
                  ))}
                </Stack>
              ))}
            </div>
          )}
          {showSubtasks ? <ExpandLess /> : <ExpandMore />}
        </Box>
        
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
        >
          {
            assignments.length > 0 && (
              <AssingmentsBadge assignments={assignments} onClick={() => setShowTaskComments(!showTaskComments)}/>
            )
          }
          <CommentIcon className={`${className}-comment`} onClick={() => setShowTaskComments(true)} value={comments} />
          <TaskComments
            open={showTaskComments}
            comments={task_comments}
            taskName={name}
            onClose={() => setShowTaskComments(false)}
            milestoneID={milestoneID}
            process={process}
            taskId={id}
            taskDescription={''}
            assignments={assignments}
            deadlineAt={dueDate || ''}
            createdAt={createdAt}
            assetID={assetID}
            isTaskFlow={subtasks}
            forms={forms}
            readOnly={readOnly}
            taskActivity={taskActivity}
          />
        </div>
      </AccordionDetails>
      <Collapse in={showSubtasks}>
        <Box sx={{
          ml: 5,
          pl: 1,
          borderLeft: `1px solid ${theme.themeColor.sectionStroke}`
        }}>
          {orderSubtasks.map((subtask, i) => (
            <MilestoneSubtask
              className={`${className}-${i}`}
              key={subtask.name}
              subtask={subtask}
              milestoneID={milestoneID}
              process={process}
              dueByMilestone={subtask.dueByMilestone}
              deadlineAt={subtask.dueDate}
              assetID={assetID}
              user={user}
              assignments={assignments}
              isCompleted={checked}
              forms={subtask.forms}
              readOnly={readOnly}
              taskActivity={taskActivity}
            />
          ))}
        </Box>
      </Collapse>
      <FinalBillConfirmation open={openFinalBill} onClose={() => setOpenFinalBill(false)} completeFinalBill={completeFinalBill} />
    </div>
  )
}