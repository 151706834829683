import React, { useState, useEffect, useContext, useRef } from 'react';
import { useQuery } from "@apollo/client";
import { Box } from '@mui/material';
import { gql, useMutation } from "@apollo/client";
import CellWrapper from '../GridInputCell/CellWrapper';
import { UserContext } from '../../context/UserContext';
import { MessageContext } from "../../context/MessageContext";
import StandardGrid from '../Grid/Grid';
import TableSkeleton from '../Skeleton/TableSkeleton';
import TableDateTime from '../TableDateTime/TableDateTime';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ActionColumn from './ActionColumn';
import Modal from '../../styled/Modal/Modal';
import MembersList from '../MembersList/MembersList';
import Button from '../../styled/Button/Button';
import Typography from '../../styled/Typography/Typography';
import { capitalize } from '../../utilities/index'

export const CREATE_INVITATION = gql`
  mutation CREATE_INVITATION($input: createInvitationInput) {
    createInvitation(input: $input) {
      invitation {
        id
        email
      }
    }
  }
`

export const CREATE_TEAM = gql`
  mutation CREATE_TEAM($input: createTeamInput!) {
    createTeam(input: $input) {
      id
      name
      type
      invitations {
        email
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query Query($input: findTeamsInput) {
    findTeams(input: $input) {
      id
      name
      type
      createdAt
      createdBy {
        firstName
        lastName
        email
      }
      memberships {
        type
        user {
          id
          firstName
          lastName
          email
          memberStatus
        }
      }
    }
  }
`

  
const frameworkComponents = {
  ActionColumn,
  CellWrapper,
  TableDateTime
}

const TableContainer = styled(Box)`
  margin: 0 auto;
  height: 98%;
  width: 98%;
  border: ${({theme}) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({theme}) => theme.themeSizing.borderRadiusLarge};
`

const ls = window.localStorage;

export default function TeamsList() {
  const gridApiRef = useRef(null);
  const { user } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();

  const [selectedTeamID, setSelectedTeamID] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  const myColsDef = [
    {
      headerName: "Team Name",
      field: "name",
      sortable: true,
      filter: true,
      width: 450,
      cellRenderer: "CellWrapper",
      cellRendererParams: {
        label: 'Team Name'
      }
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      width: 400,
      cellClass: "ag-show-dropdown-modal",
      cellRenderer: "CellWrapper",
      cellRendererParams: {
        dropdownValues: [
          {text:'Internal', value:"INTERNAL"}, 
          {text:'External', value:"EXTERNAL"}
        ]
      }
    },
    {
      headerName: "Members",
      field: "members",
      sortable: true,
      filter: true,
      width: 250,
      cellRenderer: "CellWrapper",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "Created",
      field: "dateCreated",
      sortable: true,
      filter: true,
      width: 350,
      cellRenderer: "TableDateTime",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "",
      field: "actionColumn",
      sortable: true,
      filter: false,
      width: 240,
      cellRenderer: "ActionColumn",
      cellClass: "ag-show-dropdown-modal",
      suppressColumnsToolPanel: true,
      cellRendererParams: {
        setSelectedTeamID,
        setShowModal,
        setSelectedTeam,
        setSelectedTeamMembers,
      }
    },
  ];

  const [columnDefs, setColumnDefs] = useState(myColsDef);

  const [createTeam, { loading }] = useMutation(CREATE_TEAM, {
    awaitRefetchQueries: true,
    refetchQueries: "all"
  });

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    pinnedTopRowData: [{
      name: 'fr-',
      type: 'fr-dropdown-',
      actionColumn: 'fr-create',
      dateCreated: '',
      members: '',
    }],
    rowSelection: 'single',
    columnDefs,
    resizable: true,
  };

  function onGridReady(params) {
    params.api.sizeColumnsToFit();
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  const res = useQuery(GET_TEAMS, {
    variables: {
      input: {
        options: {
          NOT: { type: "LAWFIRM" }
        }
      }
    }
  });

  useEffect(() => {
    if (res?.data) {
      const teams = [];

      [...res.data.findTeams].forEach((team) => {
        if (team.type === 'LAWFIRM') return;
        const teamCopy = { ...team };

        const members = team.memberships.filter((member) => {
          return member.user.id != user.userID
        })
        teamCopy.memberships = members;
        teams.push(teamCopy);
      });
      const transformed = transformTeamData([...teams]);
      setTeams(transformed);
    }
    if (gridApiRef?.current)
      setTimeout(() => gridApiRef.current.sizeColumnsToFit(), 0);
  }, [res]);

  useEffect(() => {
    const visibleColumns = ls.getItem('teamsList') ? JSON.parse(ls.getItem('teamsList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found && found.colId != 'actionColumn') {
        col.hide = found.hide;
      } 
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, []);

  if (res.loading) return (
    <TableSkeleton columnDefs={myColsDef} sx={{width: "100vw"}}/>
  );

  function onCellClicked() {
    return;
  }

  function transformTeamData(teams) {
    const transformedTeams = teams.map((item) => {
      return {
        id: item.id,
        name: capitalize(item.name),
        type: item.type,
        dateCreated: new Date(+item.createdAt),
        editColumn: "",
        members: item.memberships.length,
        memberships: item.memberships,
      }
    });

    return transformedTeams;
  }

  function formDataValid(formData) {
    if (formData.name.length === 0) {
      addMessage({type: 'error', message: 'Please provide a team name.'});
      return false;
    }

    if (formData.type.length == 0) {
      addMessage({type: 'error', message: 'Please provide a team type.'});
      return false;
    }

    return true;
  }

  const onTeamCreate = async (event) => {
    event.preventDefault();
    if (loading) return;

    const data = gridApiRef.current?.getPinnedTopRow(0)?.data
      || gridOptions?.api?.getPinnedTopRow(0)?.data;

    const formData = {
      name: capitalize(data.name.replace('fr-', '')),
      type: data.type.replace('fr-dropdown-', ''),
    };

    if (formDataValid(formData)) {
      try {
        await createTeam({
          variables: {
            input: {
              ...formData,
            },
          }
        });
        addMessage({ message: "Team created!" });
      } catch (error) {
        addMessage({ message: error, type: "error" })
      } finally {
        setTimeout(() => {
          gridApiRef.current.setPinnedTopRowData([{
            name: 'fr-',
            type: 'fr-dropdown-',
            actionColumn: 'fr-create',
            dateCreated: '',
            members: '',
          }])
        }, 0);
      }
    }
  }

  return (
    <TableContainer sx={{height: '100%', border: `1px solid ${theme.themeColor.sectionStroke}`}}>
      <form
        onSubmit={onTeamCreate}
        style={{height: '100%'}}
      >
        <StandardGrid
          tableName="teamsList"
          onGridReady={onGridReady}
          rowData={teams}
          onCellClicked={onCellClicked}
          gridOptions={gridOptions}
          onGridSizeChanged={onGridSizeChanged}
          components={frameworkComponents}
        />
      </form>

      <Modal open={showModal} width="90%" sx={{display: 'flex', flexDirection: 'column'}}> 
        <Typography variant="h4" component="h2">{teams?.find(t => t.id == selectedTeamID)?.name}</Typography>
        <MembersList selectedTeam={selectedTeam} selectedTeamMembers={selectedTeamMembers} />
        <Button
          onClick={() => {
            setShowModal(false);
          }}
          sx={{
            width: 'auto',
            border: 'none',
            boxShadow: 'none',
            marginLeft: '15px',
          }}
        >
            Close
        </Button>
      </Modal>
    </TableContainer>
  )
}