import React, { useState, useContext } from 'react';
import Drawer from '../../../styled/Drawer/Drawer';
import Typography from '../../../styled/Typography/Typography';
import { isJudicial as judicial, GENERIC_JUDICIAL, GENERIC_NONJUDICIAL } from '../../../utilities/genericSteps';
import Radio from '../../../styled/Radio/Radio';
import styled from '@emotion/styled';
import Button from '../../../styled/Button/Button';
import Modal from '../../../styled/Modal/Modal';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { CREATE_PROCESS } from '../../../apollo/mutations/createProcess';
import { MessageContext } from '../../../context/MessageContext';
import { gql } from '@apollo/client';
import { EVENTS_QUERY } from '../../../apollo/queries/eventsQuery';
import { ASSET_QUERY } from '../../../apollo/queries/assetQuery';
import { UserContext } from '../../../context/UserContext';
import { isForeclosure } from '../../../utilities';

export const CREATE_EVENT = gql`
  mutation($event: createEventInput!) {
    createEvent(input: $event) {
      id
    }
  }
`
export const UPDATE_ACTIVE_PROCESS = gql`
  mutation UpdateAssetProcess($assetID: ID!, $processID: ID!, $referralID: ID!, $activeProcessID: ID) {
    updateActiveProcess(assetID: $assetID, processID: $processID, referralID: $referralID, activeProcessID: $activeProcessID) {
      id
      activeProcess {
        id
        processSteps
        processType
        stepID
        deleted
        status
        startDate

        referral {
          id
          referralStatus
          deleted
          team {
            id
            name
          }
        }

        holds {
          id
          startDate
          closeDate
          expectedCloseDate
          createdInMilestone
          holdDescription
          holdType
        }

        events {
          id
          type
          createdDate
          milestoneID
        }

        tasks {
          id
          blocking
          deleted
          completedAt
        }

        task_flows {
          id
          blocking
          completedAt
        }
      }
    }
  }
`

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
`
const setJudicialState = (asset, activeProcess) => {
  if (activeProcess === null) return null;
  const { processType } = activeProcess;
  if (processType === 'stateDefault')
    return judicial(asset.state);
  else if (processType === 'judicial')
    return true;
  else return false;
}

export default function ProcessDrawer({ open, onClose, asset, activeProcess, setActiveProcess, setAsset }) {
  const { addMessage } = useContext(MessageContext);
  const { user, portfolioID } = useContext(UserContext);
  const [isJudicial, setIsJudicial] = useState(setJudicialState(asset, activeProcess));
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [updateActiveProcess] = useMutation(UPDATE_ACTIVE_PROCESS, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });
  const [createProcess] = useMutation(CREATE_PROCESS);
  const [createEvent] = useMutation(CREATE_EVENT, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });

  const handleSubmit = async () => {
    // Not deleted, not current process, and is a foreclosure
    const nonDeletedProcesses = asset.processes.filter((process) => 
      !process?.deleted && process.id !== activeProcess.id
      && isForeclosure(process)
    );
    
    // User has changed process before
    if (nonDeletedProcesses.length > 0) {
      // Going back to the original process
      if (activeProcess.processType !== "stateDefault") {
        let originalProcess;
        asset.processes.forEach((process) => {
          if (process.processType === "stateDefault")
            originalProcess = process;
        })
        // Just in case asset is started without stateDefault
        if (!originalProcess) {
          isJudicial
            ? (nonDeletedProcesses.forEach((process) => {
              if (process.processType === "nonJudicial")
                originalProcess = process;
            }))
            : nonDeletedProcesses.forEach((process) => {
              if (process.processType === "judicial")
                originalProcess = process;
            })
        }

        try {
          const { stepID } = originalProcess;

          const updateResp = await updateActiveProcess({
            variables: { assetID: asset.id, processID: originalProcess.id, referralID: activeProcess.referral.id, activeProcessID: activeProcess.id}
          })

          if (updateResp && !("errors" in updateResp)) {
            const event = {
              type: "process-changed",
              description: `Asset Type reverted to (${isJudicial ? 'Judicial' : 'Non-judicial'})`,
              createdDate: new Date(),
              process: originalProcess.id,
              milestoneID: stepID || activeProcess.stepID
            }
            await createEvent({ variables: { event } },
              {
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: ASSET_QUERY,
                    variables: { assetID: asset.id, portfolioID, userID: user.userID, },
                  },
                  {
                    query: EVENTS_QUERY,
                    variables: { milestoneID: originalProcess.stepID || activeProcess.stepID, processID: originalProcess.id },
                  },
                ]
              });
            
            setActiveProcess(updateResp.data.updateActiveProcess.activeProcess);
            setAsset((prev) => {
              const newAsset = { ...prev };
              newAsset.activeProcess = updateResp.data.updateActiveProcess.activeProcess;
              return newAsset;
            })
              
            addMessage({ message: `Asset reverted to ${isJudicial ? 'Judicial' : 'Non-judicial'} state` });
            setOpenConfirmation(false);
            onClose();
          } else addMessage({ message: "Unable to update the currently active process.", type: "error" })
        } catch(err) {
          console.log(err)
          addMessage({ message: "Currently unable to revert the current asset to its original process", type: "error" })
        }
      } else {
        // Switching to changed process (#2)
        const destinationProcess = isJudicial
          ? nonDeletedProcesses.filter((process) => process.processType === 'judicial' && !process?.deleted)[0]
          : nonDeletedProcesses.filter((process) => process.processType === 'nonJudicial' && !process?.deleted)[0];
        // User had a stateDefault process, switched and closed them, started new process and is now changing the processType
        if (!destinationProcess) {
          try {
            const data = {
              asset: asset.id,
              processSteps: isJudicial ? GENERIC_JUDICIAL : GENERIC_NONJUDICIAL,
              processType: isJudicial ? 'judicial' : 'nonJudicial',
              stepID: isJudicial ? GENERIC_JUDICIAL[1].stepID : GENERIC_NONJUDICIAL[1].stepID,
              referral: activeProcess.referral.id,
              startDate: new Date()
            }
            const processData = await createProcess({ variables: { input: data } });
            const id = processData.data.createProcess.id;

            const event = {
              type: "process-changed",
              description: `Asset Type changed to (${isJudicial ? 'Judicial' : 'Non-judicial'})`,
              createdDate: new Date(),
              process: id,
              milestoneID: '0.10'
            }
            await createEvent({ variables: { event: { data: event } } },
              {
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: ASSET_QUERY,
                    variables: { assetID: asset.id, portfolioID, userID: user.userID, },
                  },
                  {
                    query: EVENTS_QUERY,
                    variables: { milestoneID: "0.10", processID: id },
                  },
                ]
              })

            const newActiveProcess = processData.data.createProcess;
            setActiveProcess(newActiveProcess);
            setAsset((prev) => {
              const newAsset = { ...prev };
              newAsset.activeProcess = newActiveProcess;
              return newAsset;
            })
          
            addMessage({ message: "A new process has been started." });
            setOpenConfirmation(false);
            onClose();
          } catch(err) {
            console.log(err);
            addMessage({ message: "There has been an error while trying to change the process.", type: 'error'})
          }
        }
        else try {
          const { stepID } = destinationProcess;

          const updateResp = await updateActiveProcess({
            variables: { assetID: asset.id, processID: destinationProcess.id, referralID: activeProcess.referral.id }
          });

          if (updateResp && !("errors" in updateResp)) {
            const event = {
              type: "process-changed",
              description: `Asset Type changed to (${isJudicial ? 'Judicial' : 'Non-judicial'})`,
              createdDate: new Date(),
              process: destinationProcess.id,
              milestoneID: stepID || activeProcess.stepID
            }
            await createEvent({ variables: { event: { data: event } } },
              {
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: ASSET_QUERY,
                    variables: { assetID: asset.id, portfolioID, userID: user.userID, },
                  },
                  {
                    query: EVENTS_QUERY,
                    variables: { milestoneID: stepID, processID: destinationProcess.id },
                  },
                ]
              })

            setActiveProcess(updateResp.data.updateActiveProcess.activeProcess);
            setAsset((prev) => {
              const newAsset = { ...prev };
              newAsset.activeProcess = updateResp.data.updateActiveProcess.activeProcess;
              return newAsset;
            });

            addMessage({ message: `Asset changed to ${isJudicial ? 'Judicial' : 'Non-judicial'} state` });
            setOpenConfirmation(false);
            onClose();
          } else addMessage({ message: "Unable to update the currently active process.", type: "error" })
        } catch(err) {
          console.log(err);
          addMessage({ message: "Unable to update current process of the asset.", type: "error" })
        }
      }
    } else {
      try {
        const data = {
          asset: asset.id,
          processSteps: isJudicial ? GENERIC_JUDICIAL : GENERIC_NONJUDICIAL,
          processType: isJudicial ? 'judicial' : 'nonJudicial',
          stepID: isJudicial ? GENERIC_JUDICIAL[1].stepID : GENERIC_NONJUDICIAL[1].stepID,
          referral: activeProcess.referral.id,
          startDate: new Date()
        }

        const processData = await createProcess({ variables: { input: data, activeProcessID: activeProcess.id } });
        const id = processData.data.createProcess.id;

        const event = {
          type: "process-changed",
          description: `Asset Type changed to (${isJudicial ? 'Judicial' : 'Non-judicial'})`,
          createdDate: new Date(),
          process: id,
          milestoneID: '0.10'
        }
        await createEvent({ variables: { event } },
          {
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: ASSET_QUERY,
                variables: { assetID: asset.id, portfolioID, userID: user.userID, },
              },
              {
                query: EVENTS_QUERY,
                variables: { milestoneID: "0.10", processID: id },
              },
            ]
          })

        const newActiveProcess = processData.data.createProcess;
        setActiveProcess(newActiveProcess);
        setAsset((prev) => {
          const newAsset = { ...prev };
          newAsset.activeProcess = newActiveProcess;
          return newAsset;
        })
      
        addMessage({ message: "A new process has been started." });
        setOpenConfirmation(false);
        onClose();
      } catch(err) {
        console.log(err);
        addMessage({ message: "There has been an error while trying to change the process.", type: 'error'})
      }
    }
  }

  return (
    <Drawer
      open={open}
      anchor='left'
      onClose={onClose}
      height="50vh"
      styling={{ margin: "25vh 20px !important" }}
    >
      <Typography variant="h4" component="h1" mb={4} mt={3}>Settings</Typography>

      <label>Foreclosure Type</label>
      <Radio label="Judicial" checked={isJudicial} onClick={() => setIsJudicial(true)}/>
      <Radio label="Non-Judicial" checked={!isJudicial} onClick={() => setIsJudicial(false)}/>
      <Typography mt={3}>Your asset is in a {setJudicialState(asset, activeProcess) ? 'judicial' : 'non-judicial'} state</Typography>
      
      <ButtonBox
        sx={{marginTop: 10}}
      >
        <Button
          disabled={setJudicialState(asset, activeProcess) === isJudicial}
          sx={{
            width: '150px'
          }}
          onClick={() => {
            if (setJudicialState(asset, activeProcess) !== isJudicial)
              setOpenConfirmation(true);
          }}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          variant="secondary"
          sx={{
            backgroundColor: 'transparent !important',
            width: 'auto',
            border: 'none',
            boxShadow: 'none',
            marginLeft: '15px'
          }}
        >
          Cancel
        </Button>
      </ButtonBox>

      <Modal
        open={openConfirmation}
        width="25vw"
      >
        <Typography variant="h4" component="h1">Confirm Changes</Typography>
        <Typography mt={3} mb={3}>
          You&apos;re about to change this asset to be a {isJudicial ? 'Judicial' : 'Non-Judicial'} asset. No data will be lost,
          but this change will rollback your current progress.
        </Typography>
        <ButtonBox>
          <Button
            sx={{width: '150px'}}
            onClick={handleSubmit}
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmation(false);
              onClose();
            }}
            variant="secondary"
            sx={{
              backgroundColor: 'transparent !important',
              width: 'auto',
              border: 'none',
              boxShadow: 'none',
              marginLeft: '15px',
            }}
          >
            Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </Drawer>
  )
}

